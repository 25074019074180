

export namespace COURSE {
    export namespace QUESTION {
        export enum RESPONSE_TYPE {
            OPTION = "Option",
            TEXT = "Text",
            DATE = "Date",
            YESNO = "YesNo",
            YES_NO = "Yes/No",
            MOOD = "Mood",
            ATTACHMENT = "Attachment"
        };
    }

    export enum CONTENT_TYPE {
        LESSON = "Lesson",
        QUIZES = "Quizes",
        QUIZ = "Quiz",
        TASK = "Task",
        TASK_QUESTION = "TaskQuestion",
        QUESTION = "Question",
        END_COURSE = "endcourse",
        MODULE = "Module"
    };

    // Assigned Status Type Enum
    export enum ASSIGNED_STATUS {
        ASSIGNED = "Assigned",
        ENROLLED = "Enrolled",
        COMPLETED = "Completed"
    };


    // Invitation Status Type Enum
    export enum INVITATION_STATUS {
        PENDING = "Pending",
        COMPLETE = "Complete"
    };


    // Display Question Type Enum
    export enum DISPLAY_QUESTION {
        DEFAULT = "Default",
        FORM = "Form"
    };


    // Display Header Type Enum
    export enum DISPLAY_HEADER {
        LOGO = "Logo",
        BANNER = "Banner"
    };

    // Status Type Enum
    export enum STATUS {
        DRAFT = "Draft",
        PUBLISH = "Publish",
        PRIVATE = "Private",
        ARCHIVE = "Archive"
    };


    // Level Type Enum
    export enum LEVEL {
        BEGINNER = "Beginner",
        INTERMEDIATE = "Intermediate",
        ADVANCED = "Advanced"
    };

    // Enroll Type Enum
    export enum ENROLL_MODE {
        PREVIEW = "Preview",
        ENROLL = "Enroll",
        PUBLIC = "Public",
        PUBLIC_SHARE = "public share"
    };

    export namespace QUIZ {
        export enum SCORE_BASIS {
            PERCENTAGE = 1,
            CUMULATIVE = 2
        };

    }

    // Question Response Type Enum

}




import { getProfile, getToken } from 'functions'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ModuleView from '../content-view/ModuleView'

import LessonView from '../content-view/LessonView'
import QuizView from '../content-view/QuizView'
import TaskView from '../content-view/TaskView'
import QuestionView from '../content-view/QuestionView'
import EndCourseView from '../content-view/EndCourseView'
import QuestionViewForm from '../content-view/QuestionViewForm'
import ContentGroup from './ContentGroup'
import cookie from "react-cookies"
import { Course, User } from 'types'
import { API } from 'api'
import { COURSE, IMAGE, PAGE } from 'consts'
import { useUserContext } from 'UserContext'
import "./course-enroll.css"
import EnrollFailed from './EnrollFailed'
import { isMobile } from 'react-device-detect'

export default function CourseEnroll(props: {
    mode: COURSE.ENROLL_MODE
}) {
    const { url_hash } = useParams()
    const { mode } = props
    const [searchParams] = useSearchParams();
    const { setPage: setPageName } = useUserContext()

    useEffect(() => {
        setPageName(PAGE.COURSE_ENROLL)
    }, [setPageName]);
    const courseId = searchParams.get("c")

    const navigate = useNavigate()


    const [user, setUser] = useState<User | null>(null)

    const [hidePanel, setHidePanel] = useState(false)
    const [active, setActive] = useState<string | null>(null)
    const [course, setCourse] = useState<Course>(null)
    const [contents, setContents] = useState<any[]>([])
    const [contentView, setContentView] = useState<any>(null)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [allowNext, setAllowNext] = useState(true)
    const [enrollFailed, setEnrollFailed] = useState<string | null>(null)
    const buttonNextRef = useRef(null);

    console.log('mode--->', mode)
    // console.log(url_hash, mode)
    const accessToken = getToken()

    useEffect(() => {
        if (mode === COURSE.ENROLL_MODE.PUBLIC) {
            return
        }
        if (!accessToken && courseId) {
            navigate("/login?next=/course/enroll/?c=" + courseId)
        }
    }, [mode, accessToken, courseId]);


    let obj_type = null

    const handleActive = (contentId: string) => {
        setActive(contentId)
    }

    const handleClose = () => {
        if (mode === COURSE.ENROLL_MODE.PREVIEW) {
            if (isMobile) {
                navigate("/profile?tab=authored-courses")
                return
            }
            navigate("/course/edit/?c=" + courseId)
        } else if (mode === COURSE.ENROLL_MODE.ENROLL) {
            navigate("/profile?tab=personal-courses")
        } else if (mode === COURSE.ENROLL_MODE.PUBLIC) {
            navigate("/")
        } else if (mode === COURSE.ENROLL_MODE.PUBLIC_SHARE) {
            navigate("/")
        }
    }

    const handleDirect = (data: any, type?: string) => {
        console.log("data & contents --> ", data, contents)

        const obj_id = type === "Module" ? data.id : data.obj_id
        const content = contents.filter((item: any) => item.obj_id === obj_id)
        console.log("content for handle page", content)
        handlePage(content[0].order, true, type)
    }

    const handleNext = () => {
        console.log(page)
        handlePage(page + 1)
    }

    const handlePrev = () => {
        console.log('prev page --->', page)
        handlePage(page - 1)
    }

    const handlePage = (n: number, direct = false, type?: string) => {
        console.log(n, direct, type);
        const next = n < 0 ? false : !direct
        if (direct) {
            const x = parseInt(n.toString())
            console.log(x)
            if (x < 0 || x > (contents.length)) {
                return
            }

            setPage(x)
            handleView(contents[x - 1], next)
            return
        }
        console.log('n', n)
        const item = contents.filter(item => item.order === n)

        setPage(n)
        handleView(item[0], next)
    }

    const handleView = async (data: any, next = false) => {
        setLoading(true)
        console.log(data, next);
        obj_type = data.obj_type
        data["next"] = next
        console.log("data request ----->", data)
        const response = await API.Course.Enroll.GET_CONTENT(JSON.stringify(data), mode)
        console.log('response', response)

        if (mode === COURSE.ENROLL_MODE.ENROLL || mode === COURSE.ENROLL_MODE.PUBLIC) {
            setContents(response.contents)
        }
        const cond1 = obj_type === COURSE.CONTENT_TYPE.QUIZ ||
            obj_type === COURSE.CONTENT_TYPE.QUIZES ||
            obj_type === COURSE.CONTENT_TYPE.TASK

        const cond2 = !response.data.image
        const cond3 = response.data.description === "" || response.data.description === null

        if (cond1) {
            if (cond2 && cond3) {
                handlePage(data.order + 1)
                return
            }
        }

        console.log('obj_type', obj_type)

        switch (obj_type) {
            case COURSE.CONTENT_TYPE.MODULE:
                setAllowNext(true)
                setActive(data.module)
                setContentView(<ModuleView data={response.data} />)
                break;
            case COURSE.CONTENT_TYPE.LESSON:
                setAllowNext(true)
                setActive(data.lesson)
                setContentView(<LessonView data={response.data} />
                )
                break;
            case COURSE.CONTENT_TYPE.QUIZ:
            case COURSE.CONTENT_TYPE.QUIZES:
                setAllowNext(true)
                setActive(data.quiz)
                setContentView(
                    <QuizView
                        data={response.data}
                    />)
                break;
            case COURSE.CONTENT_TYPE.TASK:
                setAllowNext(true)
                setActive(data.task)
                setContentView(<TaskView
                    key={"view-" + data.id}
                    data={response.data}
                />)
                break;
            case COURSE.CONTENT_TYPE.QUESTION:
                console.log("masuk kesini")
                setActive(data.quiz)
                setAllowNext(false)
                setContentView(<QuestionView
                    data={response.data}
                    mode={mode}
                    session={data.session}
                    setAllowNext={setAllowNext}
                />)
                break;
            case COURSE.CONTENT_TYPE.TASK_QUESTION:
                setActive(data.task)
                setAllowNext(false)
                if (response.data.display_question === "Form") {
                    setContentView(
                        <QuestionViewForm
                            setPage={setPage}
                            key={"view-" + data.id}
                            obj_type={data.obj_type}
                            data={response.data}
                            mode={mode}
                            session={data.session}
                            setAllowNext={setAllowNext}
                        />
                    )
                } else {
                    setContentView(
                        <QuestionView
                            data={response.data}
                            mode={mode}
                            session={data.session}
                            setAllowNext={setAllowNext}
                        />
                    )
                }
                break;
            case COURSE.CONTENT_TYPE.END_COURSE:
                setAllowNext(true)
                setActive(null)
                setContentView(
                    <EndCourseView
                        key={"view-" + data.id}
                        data={response.data}
                        mode={mode}
                    />
                )
                break;
            default:
                break;
        }

        const mainPanel = document.querySelector(".main-panel .body")
        mainPanel && (mainPanel.scrollTop = 0)

        setLoading(false)

    }

    const handleGetCourseContents = async () => {
        if (!courseId && !url_hash) {
            return
        }
        console.log('here')
        const public_user = url_hash ? cookie.load("public_user") : null

        const response = await API.Course.Enroll.ENROLL(mode, courseId || undefined, url_hash, public_user)
        console.log(response);
        if (response.status) {
            if (response.public_user) {
                cookie.save("public_user", (response.public_user as string), { path: "/" })
            }
            setCourse(response.course)
            setContents(response.data)
            handleView(response.data[0])
        } else {
            setEnrollFailed(response.message)
        }
    }

    useEffect(() => {
        if (courseId || url_hash) {
            handleGetCourseContents()
        }

        if (url_hash) {
            return
        }
        const getUserProfile = async () => {
            try {
                const user = await getProfile()
                setUser(user)

            } catch (error) {
                console.log('error', error)
            }
        }
        getUserProfile()

    }, [])

    // console.log("allow next---------------> ", allowNext)
    // console.log(active)
    return (
        <>
            {course &&
                <div className={hidePanel ? `course-${mode} hide-panel` : `course-${mode}`}>
                    {isMobile &&
                        <div className="header">
                            <img src={IMAGE.CURIOBITES_ICON} alt="icon" />
                            <div className="title is-6 ml-3">{course.course_name}</div>
                            <div className="delete" onClick={handleClose}></div>
                        </div>
                    }
                    <div className="content-panel" >
                        <div className="header">
                            <img src={IMAGE.CURIOBITES_ICON} alt="icon" />
                            <div className="title is-6 ml-3">{course.course_name}</div>
                        </div>
                        <div className="body" >

                            {course && course.modules.map((item: any) => {
                                return <ContentGroup
                                    key={item.id}
                                    data={item}
                                    active={active}
                                    contents={contents}
                                    handleActive={handleActive}
                                    handleDirect={handleDirect}
                                    mode={mode}
                                />;
                            })}

                        </div>
                        <div className="footer">
                            {
                                user ?
                                    <div className="user-enroll">
                                        <img src={user.avatar ? user.avatar : `https://ui-avatars.com/api/?name=${user.firstname}+${user.lastname}&background=random&rounded=true`} alt="" />
                                        <p className="ml-3 mb-0">{user.firstname} {user.lastname}</p>
                                    </div> :
                                    <div className="user-enroll">
                                        <img src={IMAGE.CURIOBITES_ICON} alt="" />
                                        <p className="ml-3 mb-0">Guest</p>
                                    </div>
                            }
                        </div>
                    </div>

                    <div className={"main-panel"}>


                        <div className={loading ? "loading-bar is-loading" : "loading-bar"}></div>

                        <div className="delete close-button" onClick={handleClose}></div>

                        <div className="colapse-button" onClick={() => setHidePanel(prev => !prev)}>
                            {hidePanel ? <i className="fa-solid fa-plus"></i> : <i className="fa-solid fa-minus"></i>}
                        </div>

                        <div className="body">
                            {
                                course && (course.banner || course.logo) &&
                                <div className="brand-header-panel mb-4">
                                    {
                                        course.display_header === "Banner" ?
                                            <img src={course.banner} alt="" className='image-banner' /> :
                                            <img src={course.logo} alt="" className='image-logo' />
                                    }
                                </div>
                            }

                            {contentView}

                        </div>

                        <div className="footer">
                            <div className={page <= 1 ? "previous disable" : "previous"} onClick={handlePrev}>
                                <i className="fa-solid fa-arrow-left"></i>
                                <p>Previous</p>
                            </div>
                            <div className="center"></div>
                            {
                                allowNext ?
                                    <div ref={buttonNextRef}
                                        className={contents && contents.length && page < contents.length ? "next " : "next disable"}
                                        onClick={handleNext}>
                                        <p>Next</p>
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                    :
                                    <div className="next disable">
                                        <p>Next</p>
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                            }
                        </div>
                    </div>
                </div >
            }

            {enrollFailed &&
                <EnrollFailed message={enrollFailed} />
            }
        </>
    )

}


export const icons = {
    Module: <i className="fa-solid fa-folder"></i>,
    Lesson: <i className='fas fa-graduation-cap mr-3'></i>,
    Quiz: <i className='fas fa-question-circle mr-3'></i>,
    Quizes: <i className='fas fa-question-circle mr-3'></i>,
    Task: <i className="fa-solid fa-clipboard-list mr-3"></i>,
    Question: null,
    TaskQuestion: null,
};


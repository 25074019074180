import React, { useEffect, useState } from 'react'
import { getProfile } from 'functions'
import { Tabs } from 'antd'
import EditProfile from './EditProfile';
import EditAccount from './EditAccount';
import './setting.css'
import { User } from 'types';
import { PAGE } from 'consts';
import { useUserContext } from 'UserContext';
import BillingInfo from './BillingInfo';
import { isMobile } from 'react-device-detect';


export default function Setting() {

    const [user, setUser] = useState<User | null>(null)

    const { setPage } = useUserContext()

    useEffect(() => {
        setPage(PAGE.HOME)
    }, [setPage]);


    const settingTabs = [
        {
            key: 'setting-profile',
            label: `Profile`,
            children: <EditProfile />,
        },
        {
            key: 'seting-account',
            label: `Account`,
            children: user ? <EditAccount user={user} /> : null,
        },
        {
            key: 'billing-info',
            label: `Billing & Orders`,
            children: user ? <BillingInfo /> : null,
        },
    ];


    useEffect(() => {
        const getUserProfile = async () => {
            const profile = await getProfile()
            setUser(profile)
        }
        getUserProfile()
    }, [])

    return (
        <div className="setting">
            {user &&
                <div className="container">
                    <Tabs
                        tabPosition={isMobile ? "bottom" : "left"}
                        items={settingTabs}
                        className={'setting-tabs'}
                    />
                </div>
            }
        </div>
    )
}



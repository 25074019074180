import React, { useEffect, useState } from 'react'
import Navbar from 'components/navbar/Navbar'
import { ArrowLeftOutlined } from '@ant-design/icons';
import withAuth from 'WithAuth'
import { Link, useSearchParams } from 'react-router-dom';
import { Tabs } from 'antd';
import { Term } from 'App';
import ClassroomMember from './ClassroomMember';
import ClassroomPreferences from './ClassroomPreferences';
import ClassroomTask from './ClassroomTask';
import ClassroomPrograms from './ClassroomPrograms';
import { API } from 'api';
import "./classroom.css"

function Classroom() {
    const [classroom, setClassroom] = useState<any>(null)
    const [searchParams] = useSearchParams();
    const [collapse, setCollapse] = useState(false)
    const classrommId = searchParams.get("c")

    const handleGetClassroom = async () => {
        if (!classrommId) return
        const response = await API.Classroom.Classroom.GET(classrommId)

        console.log(response);
        if (response.status) {
            setClassroom(response.data)
        }
    }

    const classroomTabs = [

        {
            key: 'modules',
            label: `${Term.program}s`,
            icon: <i className="fas fa-box"></i>,
            children: <ClassroomPrograms key={'classroom-module'} classroom={classroom} />,
        },
        {
            key: 'tasks',
            label: `Tasks`,
            icon: <i className="fas fa-clipboard-list"></i>,

            children: <ClassroomTask key={'classroom-task'} classroom={classroom} />,
        },
        // {
        //     key: 'announcement',
        //     label: `Announcement`,
        //     icon: <i className="fas fa-bullhorn"></i>,

        //     children: `Content of Tab Pane 3`,
        // },
        // {
        //     key: 'discussion',
        //     label: `Discussion`,
        //     icon: <i className="fas fa-comments"></i>,

        //     children: `Content of Tab Pane 3`,
        // },
        {
            key: 'members',
            label: `Members`,
            icon: <i className="fas fa-user-group"></i>,

            children: <ClassroomMember key={'classroom-member'} classroom={classroom} />,
        },
        {
            key: 'preferences',
            label: `Preferences`,
            icon: <i className="fas fa-cog"></i>,
            children: <ClassroomPreferences key={'classroom-preferences'} classroomId={classroom.id} />,
        },
    ];

    useEffect(() => {
        classrommId && handleGetClassroom()
    }, [classrommId])

    return (
        <>
            <Navbar />
            {
                classroom &&
                <div className={collapse ? "classroom collapse" : "classroom"}>
                    <div className="head">
                        <Link to={"/profile?tab=authored-subjects"}>
                            <div className="back">
                                <ArrowLeftOutlined />
                            </div>
                        </Link>
                        <div className="title is-5">{classroom.classname}</div>
                    </div>
                    <Tabs
                        className='body'
                        tabPosition={"left"}
                        items={classroomTabs.map(item => {

                            return {
                                label: (
                                    <span className='tab-item'>
                                        <div className="icon">
                                            {item.icon}

                                        </div>
                                        {item.label}
                                    </span>
                                ),
                                key: item.key,
                                children: item.children,
                            };
                        })}
                    />
                    <div className="tab-switch" onClick={() => setCollapse(prev => !prev)}>
                        {
                            collapse ?
                                <i className="fa-solid fa-chevron-right"></i>
                                :
                                <i className="fa-solid fa-chevron-left"></i>
                        }
                    </div>



                </div>
            }
        </>
    )
}

export default withAuth(Classroom)
import React, { memo, useEffect, useState } from 'react';
import { Input, Checkbox, InputNumber, Radio, message, Empty } from 'antd';
import { getDragContentStyle, getKey, getRandomColor, reorderData } from 'functions';
import UploadFile from 'components/ui/UploadFile';
import EditQuestion from './EditQuestion';
import QuestionListItem from './QuestionListItem';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Module, Question, Quiz } from 'types';
import { DeleteUpload, GetUpdateQuestion, GetUpdateQuiz } from 'api';
import { HOST } from 'consts';
import { Modal } from 'components/ui/Modal';

const { TextArea } = Input;

export default function EditQuiz(props: {
    quizId?: string,
    module: Module
    reload: () => void
}) {
    const { quizId, module, reload } = props
    const [editTitle, setEditTitle] = useState(false)
    const [quiz, setQuiz] = useState<Quiz | null>(null)
    const [questionList, setQuestionList] = useState([])
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [question, setQuestion] = useState<Question | null>(null)


    const handleEditTitle = (arg: boolean) => {
        setEditTitle(arg)
        !arg && updateQuiz()
    }

    const handleUpdateQuiz = ({ name, value }: any) => {
        setQuiz((prev: any) => ({
            ...prev,
            [name]: value
        }));
    }

    useEffect(() => {
        if (quizId) {
            getQuiz()
        }
    }, [quizId])

    const handleDragEnd = async (result: any) => {
        if (!result.destination) return; // if dropped outside the list
        const itemsCopy = Array.from(questionList);
        const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
        itemsCopy.splice(result.destination.index, 0, reorderedItem);
        const cek = await reorderData(itemsCopy, "question", quiz.id)
        console.log(cek);
        setQuestionList(itemsCopy);
    };

    const getQuiz = async () => {
        try {
            const response = await GetUpdateQuiz(quizId || quiz.id, "GET");
            setQuiz(response.data);
            setQuestionList(response.data.questions)
        } catch (error) {
            console.log(error);
        }
    };

    const updateQuiz = async () => {
        try {
            const response = await GetUpdateQuiz(quiz.id, "PUT", quiz)
            console.log(response);
            if (response.status) {
                setEditTitle(false);
                reload();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const addQuestion = () => {
        setOpenModal(true)
    }

    const editQuestion = async (pk: string) => {
        console.log(pk)
        setOpenModal(true)
        try {
            const response = await GetUpdateQuestion(pk, "GET")
            console.log('response', response)
            if (response.status) {
                setQuestion(response.data)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const deleteQuestion = async (pk: string) => {
        try {
            const response = await GetUpdateQuestion(pk, "DELETE");
            const data = response.data;
            console.log(data)
            reload();
        } catch (error) {
            console.log(error);
        }
    };

    const handleSetting = () => {
        updateQuiz()
    }

    const draggingColor = getRandomColor()
    const questionListItems = questionList && questionList.length ? questionList.map((item: any, index) => (
        <Draggable draggableId={"drag-quiz-question-" + index} index={index} key={index}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getDragContentStyle(
                        draggingColor,
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}
                    className='question-list-item'>
                    <QuestionListItem
                        key={item.id}
                        question={item}
                        deleteQuestion={deleteQuestion}
                        editQuestion={editQuestion}
                    />
                </div>
            )}
        </Draggable>
    )) : <Empty description={"No questions"} />

    return (

        <div className="edit-quiz">
            {quiz &&
                <>
                    <Modal open={openModal}>
                        <EditQuestion
                            close={() => {
                                setOpenModal(false);
                                setQuestion(null);
                            }}
                            question={question}
                            quiz={quiz}
                            reload={getQuiz}
                        />
                    </Modal>
                    <div className={editTitle ? "header edit" : "header"}>
                        <div className="header-breadcrumb" onClick={() => setEditTitle(true)}>
                            <div className="title">{module.title}</div>
                            <i className="las la-angle-right mx-2"></i>
                            <div className="title m-0">
                                {quiz.title}
                            </div>
                            <i className="fa-solid fa-pen ml-3"></i>
                        </div>
                        <TitleField quiz={quiz} handleUpdateQuiz={handleUpdateQuiz} handleEditTitle={handleEditTitle} />
                    </div>

                    <div className="form-box">

                        {/* questions  */}
                        <div className="question-panel">
                            {/* <div className="question-panel-box"> */}
                            <p className="title is-6">Questions</p>
                            <DragDropContext onDragEnd={handleDragEnd} key={getKey()}>
                                <Droppable
                                    droppableId={"questions-" + quiz.id}
                                    key={quiz.id} >
                                    {(provided) => (
                                        <div className="question-list" ref={provided.innerRef}>
                                            {questionListItems}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <div className="button is-outlined is-secondary" onClick={addQuestion}>+ Add Question</div>

                        </div>

                        {/* setting  */}
                        <div className="setting-panel p-4">
                            <div className="head">
                                <div className="title is-6 mb-3">Setting</div>
                            </div>
                            <form className="setting-form" id='id-setting-form' onBlur={() => handleSetting()}>

                                <DescriptionField quiz={quiz} handleUpdateQuiz={handleUpdateQuiz} />

                                <QuizImageField fileUrl={quiz.image} app={"curio"} model={"Quiz"} object={quiz} name={"image"} />

                                <VideoField quiz={quiz} handleUpdateQuiz={handleUpdateQuiz} />

                                <LimitQuestionField quiz={quiz} handleUpdateQuiz={handleUpdateQuiz} />

                                {quiz.limit_question && <MaxQuestionField quiz={quiz} handleUpdateQuiz={handleUpdateQuiz} />}

                                <RequiredScoreField quiz={quiz} handleUpdateQuiz={handleUpdateQuiz} />

                                {quiz.required_score &&
                                    <>
                                        <ScoreBasisField quiz={quiz} handleUpdateQuiz={handleUpdateQuiz} />
                                        <ScoreToPassField quiz={quiz} handleUpdateQuiz={handleUpdateQuiz} />
                                    </>

                                }

                                <RandomQuestionField quiz={quiz} handleUpdateQuiz={handleUpdateQuiz} />

                                <DisplayQuestionField quiz={quiz} handleUpdateQuiz={handleUpdateQuiz} />

                                <div className="button is-outlined is-danger my-5 delete-content">Delete</div>

                            </form>
                        </div>
                    </div>
                </>
            }
        </div>

    )
}

interface FieldProps {
    quiz: Quiz,
    handleUpdateQuiz: (arg: any) => void
}

const TitleField = (props: FieldProps & {
    handleEditTitle: (arg: boolean) => void
}) => {
    const { quiz, handleUpdateQuiz, handleEditTitle } = props
    const handleChange = (value: string) => {
        handleUpdateQuiz({ name: "title", value: value })
    }

    const handleUpdate = () => {
        handleEditTitle(false)
    }

    return (
        <div className="header-form" >
            <Input value={quiz.title} style={{ width: 500 }} onChange={(e) => handleChange(e.target.value)} onPressEnter={handleUpdate} />
            <div className="button is-secondary is-outlined ml-3" onClick={handleUpdate}>
                <i className="fa-solid fa-check"></i>
            </div>
        </div>
    )
}

const DescriptionField = (props: FieldProps) => {
    const { quiz, handleUpdateQuiz } = props
    const handleChange = (value: string) => {
        handleUpdateQuiz({ name: "description", value: value })
    }

    return (
        <div className="field">
            <div className="control">
                <div className="label">Description</div>
                <TextArea
                    showCount
                    value={quiz.description}
                    rows={3}
                    maxLength={200}
                    onChange={(e) => handleChange(e.target.value)}
                />
            </div>
        </div>
    )
}


const VideoField = (props: FieldProps) => {
    const { quiz, handleUpdateQuiz } = props
    const handleChange = (value: string) => {
        handleUpdateQuiz({ name: "video", value: value })
    }

    return (
        <div className="field">
            <div className="control">
                <div className="label">Video Url</div>
                <Input
                    placeholder="Enter video url"
                    value={quiz.video}
                    onChange={(e) => handleChange(e.target.value)}
                />
            </div>
        </div>
    )
}

const LimitQuestionField = (props: FieldProps) => {
    const { quiz, handleUpdateQuiz } = props
    const handleChange = (checked: boolean) => {
        handleUpdateQuiz({ name: "limit_question", value: checked })
    }

    return (
        <div className="field">
            <div className="control">
                <Checkbox
                    checked={quiz.limit_question}
                    onChange={(e) => handleChange(e.target.checked)}
                >
                    <div className="label">Limit Question</div>
                </Checkbox>
                <p className="tip">Limit the number of questions display.</p>
            </div>
        </div>
    )
}


const MaxQuestionField = (props: FieldProps) => {
    const { quiz, handleUpdateQuiz } = props

    const handleChange = (e: number) => {
        handleUpdateQuiz({ name: "max_question", value: e })
    }

    return (
        <div className="field">
            <div className="control">
                <div className="label">Max Questions</div>
                <InputNumber min={1} max={100}
                    value={quiz.max_question}
                    step={1}
                    onChange={(e) => handleChange(e)}
                    style={{ width: '100%' }} />
            </div>
        </div>
    )
}

const RequiredScoreField = (props: FieldProps) => {
    const { quiz, handleUpdateQuiz } = props

    const handleChange = (checked: boolean) => {
        handleUpdateQuiz({ name: "required_score", value: checked })
    }
    return (
        <div className="field">
            <div className="control">
                <Checkbox
                    checked={quiz.required_score}
                    onChange={(e) => handleChange(e.target.checked)}
                >
                    <div className="label">Required Score</div>
                </Checkbox>
                <p className="tip">
                    Enabling this option will score the user answers.
                    Users can pass or fail the quiz if this enabled.
                </p>
            </div>
        </div>
    )
}

const ScoreToPassField = (props: FieldProps) => {
    const { quiz, handleUpdateQuiz } = props

    const handleChange = (e: number) => {
        handleUpdateQuiz({ name: "score_to_pass", value: e })
    }
    return (
        <div className="field">
            <div className="control">
                <div className="label">Score to Pass</div>
                <InputNumber min={10} max={100}
                    value={quiz.score_to_pass}
                    step={10}
                    onChange={(e) => handleChange(e)}
                    style={{ width: '100%' }} />
            </div>
        </div>
    )
}

const RandomQuestionField = (props: FieldProps) => {
    const { quiz, handleUpdateQuiz } = props
    const handleChange = (e: boolean) => {
        handleUpdateQuiz({ name: "random_question", value: e })
    }
    return (
        <div className="field">
            <div className="control">
                <Checkbox
                    checked={quiz.random_question}
                    onChange={(e) => handleChange(e.target.checked)}
                >
                    <div className="label">Random Question Order</div>
                </Checkbox>
                <p className="tip">
                    The order of the questions displayed to the user will be randomised.
                </p>
            </div>
        </div>
    )
}

const ScoreBasisField = (props: FieldProps) => {
    const { quiz, handleUpdateQuiz } = props;
    const [value, setValue] = useState(quiz.score_basis)
    const handleChange = (e: any) => {
        const value = e.target.value
        setValue(value)
        handleUpdateQuiz({ name: "score_basis", value: value })
    }
    return (
        <div className="field">
            <div className="control">
                <div className="label">Score Basis</div>
                <Radio.Group value={value} onChange={handleChange}  >
                    <Radio.Button className="custom-radio" value={1}>Percentage</Radio.Button>
                    <Radio.Button className="custom-radio" value={2}>Cumulative</Radio.Button>
                </Radio.Group>
                <p className="tip mt-2">
                    {value === 1 ?
                        "Percentage from correct user answer."
                        :
                        "Cumulative base from answer score."
                    }

                </p>
            </div>
        </div>
    )
}
const DisplayQuestionField = (props: FieldProps) => {
    const { quiz, handleUpdateQuiz } = props;
    const handleChange = (e: any) => {
        console.log(e);
        handleUpdateQuiz({ name: "display_question", value: e.target.value })
    }
    return (
        <div className="field">
            <div className="control">
                <div className="label">Display Question</div>
                <Radio.Group value={quiz.display_question} onChange={handleChange}  >
                    <Radio.Button className="custom-radio" value="Default">Default</Radio.Button>
                    <Radio.Button className="custom-radio" value="Form">Form</Radio.Button>
                </Radio.Group>
                <p className="tip mt-2">
                    format form to display questions.
                </p>
            </div>
        </div>
    )
}

const QuizImageField = memo((props: {
    fileUrl: string,
    app: string,
    model: string,
    object: any,
    name: string,
}) => {

    const { fileUrl, app, model, object, name } = props

    console.log('fileUrl', fileUrl)
    const [fileList, setFileList] = useState<any>([])

    console.log('fileList', fileList)

    useEffect(() => {
        if (fileUrl) {
            setFileList([{
                uid: '-1',
                fileName: 'image.png',
                status: 'done',
                url: fileUrl,
            }])
        }

        return () => {
            setFileList([])
        }
    }, [fileUrl]);


    const handleChange = (info: any) => {
        setFileList(info)
    }

    const handleRemove = async (file: any) => {
        console.log('Removing file:', file);
        message.loading("deleting...", 0)
        const response = await DeleteUpload(app, model, object.id, name)
        console.log('response', response)
        message.destroy()
        setFileList([file])
    }

    const uploadEnpoint = HOST + `/api/Upload/?app=${app}&model=${model}&pk=${object.id}&field=${name}`

    return (

        <div className="field">
            <div className="label proper">{name}</div>
            <UploadFile
                handleChange={handleChange}
                handleRemove={handleRemove}
                endpoint={uploadEnpoint}
                accept=".jpg,.jpeg,.png"
                fileList={fileList}
                className={"image-field " + name}

            />
        </div>

    )
})


import MyEditor from 'components/ui/FroalaEditor';
import React from 'react'


export default function DateQuestionForm(props: {
    question: any,
    updateQuestion: (obj: any) => void
}) {
    const { question, updateQuestion } = props

    const handleContent = (value: any) => {
        updateQuestion({ name: "question", value: value })
    }

    return (
        <div className="question-box" datatype="text">
            <div className="question-field form-box">
                <div className="label">Question</div>
                <MyEditor
                    content={question.question}
                    onContentChange={handleContent}
                />
            </div>

            <hr />
            <div className="options mt-5">
                <div className="flex-group mb-4">
                    <div className="title is-6 m-0">Date</div>
                </div>
                <div className="field">
                    <div className="control">

                    </div>
                </div>
            </div>
        </div>
    );
}

import React from 'react'
import { ReactNode } from 'react'
import ReactDom from 'react-dom'

export function Modal({ children, open }: { children: ReactNode, open: boolean }): JSX.Element | null {


    const modalRoot = document.getElementById('app-modal')
    if (!modalRoot) return null
    const ModalContainer = (
        <div>
            {children}
        </div>
    )

    return open ? ReactDom.createPortal(ModalContainer, modalRoot) : null

}
import React from 'react'
import { Routes, Route } from "react-router-dom";
import Register from '../components/auth/Register';
import Login from '../components/auth/Login';
import Home from '../components/home/Home';
import Profile from '../components/profile/Profile';
import Logout from '../components/auth/Logout';
import CourseBuilder from '../components/coursebuilder/CourseBuilder';
import Test from '../components/Test';
import CourseEnroll from 'components/course/course-enroll/CourseEnroll';
import PageNotFound from 'components/error-pages/PageNotFound';
import Setting from 'components/settings/Setting';
import Classroom from 'components/classroom/Classroom';
import Search from 'components/search/Search';
import RegistrationSuccess from 'components/auth/RegistrationSuccess';
import Activation from 'components/auth/Activation';
import CourseInvitationHandler from 'components/course/CourseInvitationHandler';
import ResetPassword from 'components/auth/ResetPassword';
import Model from 'components/model/Model';
import Reviewer from 'components/course/reviewer/Reviewer';
import { COURSE } from 'consts';
import { CancelPayment } from 'components/payment/CancelPayment';
import { SuccessPayment } from 'components/payment/SuccessPayment';

export default function MetaRouter(): React.ReactNode {

    window.scrollTo(0, 0)

    return (
        <Routes >
            <Route path="/payment/cancel/:paymentToken/" element={<CancelPayment />} />
            <Route path="/payment/success/:paymentToken/" element={<SuccessPayment />} />

            <Route path="/classroom/edit/" element={<Classroom />} />
            <Route path="/course/edit/" element={<CourseBuilder />} />
            <Route path="/course/invitation" element={<CourseInvitationHandler />} />
            <Route path="course/s/enroll/:url_hash" element={<CourseEnroll mode={COURSE.ENROLL_MODE.PUBLIC} />} />
            <Route path="/course/enroll/" element={<CourseEnroll mode={COURSE.ENROLL_MODE.ENROLL} />} />
            <Route path="/course/preview/" element={<CourseEnroll mode={COURSE.ENROLL_MODE.PREVIEW} />} />
            <Route path="/test/" element={<Test />} />
            <Route path="/reviewer/:mode/:reviewerId" element={<Reviewer />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/login" element={<Login />} />
            <Route path="/model" element={<Model />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reset-password/:hash" element={<ResetPassword />} />
            <Route path="/activation/:token" element={<Activation />} />
            <Route path="/registration-success" element={<RegistrationSuccess />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/search" element={<Search />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

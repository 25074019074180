import React from 'react'
import { Table } from 'antd';
import { Term } from 'App';
import moment from 'moment';
import { Classroom, Member } from 'types';

export default function ClassroomMember(props: {
    classroom: Classroom
}) {
    const { classroom } = props;

    const DataTable = () => {
        console.log(props);
        const newData = classroom.data.member.map((item: Member) => ({
            name: item.member_name,
            avatar: item.member_avatar,
            program: item.program.length,
            dateJoin: moment(item.date_join).fromNow()
        }));

        console.log("new data : ", newData);
        return (
            <Table className={"is-table"} columns={columns} dataSource={newData} onRow={(record) => ({
                onClick: () => handleRowClick(record),
            })} />
        );
    };
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, record: Member) => {
                const imageUrl = record.avatar;
                console.log(name, record)

                return (
                    <>
                        <img src={imageUrl} className={'member-avatar'} alt="course" style={{ width: 30, height: 30, marginRight: 10 }} />
                        {name}
                    </>
                );
            },
            sorter: (a: Member, b: Member) => a.name.localeCompare(b.name),
            className: "has-avatar"

        },
        {
            title: Term.program + "s",
            dataIndex: 'program',
            key: 'program',
            sorter: (a: Member, b: Member) => a.program - b.program,
        },
        {
            title: 'Date Join',
            dataIndex: 'dateJoin',
            key: 'dateJoin',
            sorter: (a: Member, b: Member) => a.dateJoin - b.dateJoin,
            render: (dateJoin: any) => (
                dateJoin === 0 ? "Free" : dateJoin
            )
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            className: "action",
            render: () => (
                <>

                </>
            ),
        },
    ];

    const handleRowClick = (record: Member) => {
        console.log("row clicked", record);
    }
    return (
        <div className="classroom-member">
            <div className="p-5">
                <div className="title is-5 mb-3">Members</div>
                <DataTable />
            </div>
        </div>
    )
}

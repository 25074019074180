import React, { createContext, useContext, useState } from 'react';
import { User } from 'types';
import { PAGE } from 'consts';
// Create the context
interface UserContextProps {
    user: User | null;
    setUser: (user: User | null) => void;
    openChat: boolean;
    setOpenChat: (openChat: boolean) => void;
    page: PAGE;
    setPage: (page: PAGE) => void;
}

// Initialize context with default values
const defaultUserContext: UserContextProps = {
    user: null, // assuming User or null as the default state
    setUser: () => { }, // empty function for default state
    openChat: false,
    setOpenChat: () => { },
    page: PAGE.HOME,
    setPage: () => { },
};

const UserContext = createContext<UserContextProps>(defaultUserContext);

interface UserProviderProps {
    children: React.ReactNode;
}

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [openChat, setOpenChat] = useState<boolean>(false);
    const [page, setPage] = useState<PAGE>(PAGE.HOME);

    const value = {
        user,
        setUser,
        openChat,
        setOpenChat,
        page,
        setPage,
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};

export { UserProvider, useUserContext };

import { Checkbox, Input } from 'antd';
import MyEditor from 'components/ui/FroalaEditor';
import { getKey, getMood } from 'functions'
import React, { useEffect, useState } from 'react'
import { Answer, Question } from 'types';


const defaultAnswer = [
    {
        id: getKey(15),
        correct: false,
        answer: "Bad",
    },
    {
        id: getKey(15),
        correct: false,
        answer: "Sad",
    },
    {
        id: getKey(15),
        correct: false,
        answer: "Neutral",
    },
    {
        id: getKey(15),
        correct: false,
        answer: "Good",
    },
    {
        id: getKey(15),
        correct: false,
        answer: "Excelent",
    },
]


export default function MoodQuestionForm(props: {
    question: Question,
    answer: Answer,
    updateAnswer: (obj: any) => void,
    updateQuestion: (obj: any) => void
}) {
    const { question, answer: propsAnswer, updateAnswer, updateQuestion } = props

    const [answer, setAnswer] = useState(defaultAnswer);

    useEffect(() => {
        if (propsAnswer) {
            setAnswer(propsAnswer);
        } else {
            updateAnswer(defaultAnswer)
        }
    }, [propsAnswer]);


    const handleContent = (value: any) => {
        updateQuestion({ name: "question", value: value })
    }

    const handleAnswerChange = (id: string, a: any) => {
        let newAnswer = answer.map((ans) => {
            return { ...ans, correct: false };
        });

        newAnswer = newAnswer.map((ans) => {
            if (ans.id === id) {
                return { ...ans, answer: a };
            }
            return ans;
        });

        updateAnswer(newAnswer);
    };

    useEffect(() => {

    }, [])

    return (
        <div className="question-box" datatype="Option">
            <div className="question-field form-box">
                <div className="label">Question</div>
                <MyEditor
                    content={question.question}
                    onContentChange={handleContent}
                />
            </div>

            <hr />
            <div className="options mt-5">
                <div className="flex-group mb-4">
                    <div className="title is-6 m-0">Mood</div>
                </div>
                {answer &&
                    answer.map((ans, index) => (
                        <OptionInput key={ans.id.toString()} ans={ans} index={index}
                            handleAnswerChange={handleAnswerChange}
                        />
                    ))}
            </div>
        </div>
    );
}


function OptionInput(props: any) {
    const { ans, handleAnswerChange, index } = props;

    const handleChange = (e: any) => {
        // let c = e.currentTarget.querySelector(".input-check input").checked;
        let a = e.currentTarget.querySelector(".input-value").value;
        handleAnswerChange(ans.id, a);
    }

    return (
        <div className="option-answer-item " id={ans.id} onChange={handleChange}>
            <div className="answer-box mood-option">
                <img src={getMood(index + 1) || ""} alt="" className='mood-icon' />
                <Input placeholder='Option' className='input-value' value={ans.answer} />
                <Checkbox checked={ans.correct} className="input-check non">is True</Checkbox>
            </div>
        </div>
    )
}


import React, { createContext, useContext, useState } from 'react';

// Langkah 1: Buat Context
interface BuilderContextProps {
    setBuilderLoading: (loading: boolean) => void;
}

const BuilderContext = createContext<BuilderContextProps>({
    setBuilderLoading: () => { },
});


// Langkah 2: Buat Provider
export const BuilderProvider = ({ children }: { children: React.ReactNode }) => {
    const [builderLoading, setBuilderLoading] = useState<boolean>(false);

    const value = { setBuilderLoading };

    const className = `builder-loading ${builderLoading ? 'active' : 'done'}`;

    return (
        <BuilderContext.Provider value={value}>
            <>

                <div className={className}></div>

                {children}
            </>
        </BuilderContext.Provider>
    );
};

export const useBuilder = () => {
    const context = useContext(BuilderContext);

    if (context === undefined) {
        throw new Error('useBuilder must be used within a BuilderProvider');
    }

    return context;
};


import { Button, notification } from 'antd';
import { getMood, HTMLRenderer, } from 'functions'

import React, { useEffect, useState } from 'react'
import { QuestionViewProps } from '../QuestionView';
import { Answer } from 'types';
import { API } from 'api';
import { COURSE } from 'consts';
import cookie from "react-cookies"

export default function MoodQuestionView(props: QuestionViewProps & {
    type: string

}) {
    const { data, mode, session, type, setAllowNext } = props;
    const [confirm, setConfirm] = useState(false)
    const [answer, setAnswer] = useState<Answer>([])
    const [loading, setLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const publicUser = cookie.load("public_user")

    const updateAnswer = (answerId: string) => {
        setAnswer([answerId])
        setConfirm(false)
    }

    console.log('type', type)

    const handleConfirm = () => {
        setConfirm(true)
        if (mode === COURSE.ENROLL_MODE.ENROLL || mode === COURSE.ENROLL_MODE.PUBLIC) {
            handleSaveAnswer()
        } else {
            setAllowNext(true)
        }
    }

    const getAnswerItemClassName = (item: any) => {
        let className = 'answer answer-mood';
        if (answer.includes(item.id)) {
            className += ' selected';
        }
        return className
    }


    const handleSaveAnswer = async () => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append('question', JSON.stringify(data));
            formData.append('answer', answer);
            formData.append('session', session);
            formData.append('type', type)
            formData.append('mode', mode);
            if (mode === COURSE.ENROLL_MODE.PUBLIC) {
                formData.append("user", publicUser)
            }

            const response = await API.Course.UserAnswer.SAVE(formData, mode)
            console.log(response)
            if (type === COURSE.CONTENT_TYPE.TASK_QUESTION || data.required_score) {
                api.success({
                    message: "Answer saved successfully",
                })

            }
            setAllowNext(true)

        } catch (error) {
            api.error({
                message: "Failed to save answer",
            })
        }
        setLoading(false)

    }

    useEffect(() => {
        if (data.user_answer.length > 0) {
            setAllowNext(true)
            setAnswer([data.user_answer[0].user_answer_mood])
        }
    }, [data])

    console.log(answer)

    return (
        <div className={data.response_type + "-view"}>
            {contextHolder}
            <div className="question">
                <div className="question-label">Question</div>
                <HTMLRenderer content={data.question} key={data.id} />
            </div>

            <div className="tip mt-4 mb-3">Select mood</div>

            <div className={"mb-4 answer-box " + data.response_type}>
                {data.options.map((item: any, index: number) => (
                    <div className="option-item" key={"option-item-mood" + index}>
                        <div id={"answer-option-" + item.id}
                            className={getAnswerItemClassName(item)}

                            onClick={() => updateAnswer(item.id)}
                        >
                            <div className="option-index">
                                <img src={getMood(index + 1) || ""} alt="" className='mood-icon' />
                            </div>
                            <div className="option-value">
                                {item.answer}
                            </div>
                        </div>
                        {
                            confirm && answer.includes(item.id) ?
                                <div className="explanation-box mb-3">
                                    {item.explanation}
                                </div> : null
                        }
                    </div>
                ))}
            </div>

            <Button type='primary'
                loading={loading}
                disabled={!answer.length}
                onClick={handleConfirm}>Confirm</Button>
        </div>
    )
}

import React from "react"
import { message } from "antd"
import UploadFile from "components/ui/UploadFile"
import { HOST } from "consts"
import { getKey, getToken } from "functions"
import { memo, useState } from "react"
import { Course } from "types"



export const CourseImageField = memo((props: {
    fileUrl: string,
    name: string,
    course: Course,
    style?: React.CSSProperties
}) => {

    const { fileUrl, name, course, style } = props

    const [fileList, setFileList] = useState<any>(fileUrl ? [
        {
            uid: getKey(),
            name: name,
            status: "done",
            url: fileUrl,
        },
    ] : [])

    const handleChange = (info: any) => {
        setFileList(info)
    }

    const handleRemove = async (file: any) => {
        console.log('Removing file:', file);
        message.loading("deleting...", 0)
        const endpoint = HOST + `/api/Upload/?app=curio&model=Course&pk=${course.id}&field=${name}`
        await fetch(endpoint, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
        })
            .then(r => r.json())
            .then(r => {
                console.log(r)
                message.destroy()
            }).catch(e => console.log(e))

        setFileList([file])
    }
    return (

        <div className="control" >
            <div className="label proper">{name === "logo2" ? "Logo (Dark Mode)" : name}</div>
            <UploadFile
                handleChange={handleChange}
                handleRemove={handleRemove}
                endpoint={HOST + `/api/Upload/?app=curio&model=Course&pk=${course.id}&field=${name}`}
                accept=".jpg,.jpeg,.png"
                fileList={fileList}
                className={"course-image-field " + name}
                style={style}
            />
            <div className="tip">{
                (name === "logo" || name === "logo2") ? "Recomended size 400px * 110px" : name === "banner" ? "Recomended size 1200px * 190px" :
                    name === "image" && ""
            }
            </div>
        </div>

    )
})

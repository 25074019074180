import Footer from 'components/footer/Footer'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Checkbox, Space } from 'antd'
import "./search.css"
import CourseCard from 'components/course/CourseCard'
import { Course } from 'types'
import { HOST } from 'consts'


export default function Search() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [level, setLevel] = useState<string[]>([])
    const [courses, setCourses] = useState<Course[]>([])
    // const [classrooms, setClassrooms] = useState([])
    const keyword = searchParams.get("q")

    const handleFilter = ({ name, value }: any) => {

        console.log(name, value)
        if (name === "level") {
            const currentParams = new URLSearchParams(window.location.search);
            currentParams.delete("level"); // Remove existing 'level' parameters

            value.forEach((item: any) => {
                currentParams.append("level", item); // Append each new 'level' parameter
            });

            setSearchParams(currentParams.toString()); // Update the search parameters
            setLevel(value);

        }

        getSearch()
    }

    useEffect(() => {
        getSearch(); // Get initial search results
    }, [searchParams]);


    const getSearch = async () => {
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.delete("q")
        const endpoint = `${HOST}/api/Search?course_name=${keyword}&${currentParams}`;
        console.log(endpoint)
        const response = await fetch(endpoint);
        const data = await response.json();
        console.log(data)
        setCourses(data.results)
    }

    console.log('level', level)
    return (
        <div className="global-search">
            <div className="container fit-screen">
                <div className="filter-box">
                    <div className="title is-6 p-4 m-0">Filter</div>

                    <div className="filter p-4">
                        <h4>Knowledge</h4>
                    </div>
                    <div className="filter p-4">
                        <h4>Level</h4>
                        <Checkbox.Group
                            className='mt-3'
                            value={level}
                            onChange={e => handleFilter({ name: "level", value: e })}

                        >
                            <Space direction='vertical' >
                                <Checkbox value="Beginner">Beginner</Checkbox>
                                <Checkbox value="Intermediate">Intermediate</Checkbox>
                                <Checkbox value="Advanced">Advance</Checkbox>
                            </Space>
                        </Checkbox.Group>
                    </div>
                    <div className="filter p-4">
                        <h4>Ratings</h4>
                    </div>

                </div>
                <div className="contents">
                    {
                        courses.map(item => <CourseCard course={item} key={item.id} />)
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

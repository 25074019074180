import { Button, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { Classroom, Lesson } from 'types';
import { Field } from 'components/ui/Field';
import { FORMAT } from 'consts';
import { API } from 'api';
import { useParams } from 'react-router-dom';

export default function TaskLesson(props: {
    classroom: Classroom,
    taskLessonId?: string,
    handleDisplay: (display: string) => void,
    getTasks: () => void
}) {

    const { classroomId } = useParams()
    const { classroom, taskLessonId, handleDisplay, getTasks } = props;
    // console.log(classroom, pk)

    const [lesson, setLesson] = useState<Lesson | null>(null)

    const getTaskLesson = async () => {
        if (!taskLessonId) return
        const response = await API.Classroom.TaskLesson.GET(taskLessonId)
        console.log(response)
        if (response.status) {
            setLesson(response.data)
        }
    }

    const createTaskLesson = async () => {
        const response = await API.Classroom.TaskLesson.CREATE(classroom.id)
        console.log(response)
        if (response.status) {
            setLesson(response.data)
            getTasks()
        }

    }

    useEffect(() => {
        if (taskLessonId) {
            getTaskLesson()
        } else {
            createTaskLesson()
        }
    }, [taskLessonId])

    const handleUpdateLesson = ({ name, value }: any) => {
        setLesson((prev: Lesson) => ({
            ...prev, [name]: value
        }));
    };


    const handleSaveLesson = async () => {
        const response = await API.Classroom.TaskLesson.UPDATE(lesson, lesson.id)
        console.log(response)
        if (response.status) {
            message.success("Lesson save!")
            getTasks()
        }

    }

    const handleGetAllTaskLesson = async () => {

    }

    useEffect(() => {
        handleGetAllTaskLesson()
    }, [classroomId]);

    return (
        <div className="task-lesson">
            {lesson &&
                <>
                    <div className="header px-3">
                        <div className="back mr-2" onClick={() => handleDisplay("table")}>
                            <i className="fa-solid fa-arrow-left"></i>
                        </div>
                        <div className="title is-6">
                            {lesson.title}
                        </div>
                    </div>
                    <div className="body">
                        <div className="editor-box">
                            <Field
                                name='content'
                                object={lesson}
                                handleUpdate={handleUpdateLesson}
                                type="textEditor"
                                label='Content'
                            />
                            <div className="button-group mt-4">
                                <Button type='primary' onClick={handleSaveLesson}>Save</Button>
                            </div>

                        </div>
                        <div className="setting-panel p-4">
                            <p className='mb-4'><strong >Setting</strong></p>

                            <Field
                                name='title'
                                object={lesson}
                                handleUpdate={handleUpdateLesson}
                                type="char"
                                label='Title'
                            />

                            <Field
                                name='description'
                                object={lesson}
                                handleUpdate={handleUpdateLesson}
                                type="textarea"
                                label='Description'
                            />

                            <Field
                                name='due_type'
                                object={lesson}
                                handleUpdate={handleUpdateLesson}
                                type="radio"
                                options={[
                                    { value: "Day", label: "Day" },
                                    { value: "Date", label: "Date" }
                                ]}
                                label='Due Type'
                            />


                            {lesson && lesson.due_type === "Day" ?
                                <Field
                                    name='due_days'
                                    object={lesson}
                                    handleUpdate={handleUpdateLesson}
                                    type="number"
                                    label='Due Days'
                                />
                                :
                                <Field
                                    name='due_date'
                                    object={lesson}
                                    handleUpdate={handleUpdateLesson}
                                    type="date"
                                    label='Due Date'
                                    dateFormat={FORMAT.DATE}

                                />

                            }
                        </div>
                    </div>
                </>

            }
        </div>
    )
}

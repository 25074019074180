import React, { useEffect, useRef, useState } from "react";
import { CoursePreferencesFieldProps } from "./CoursePreferences";
import { CreatePublicShareCourse, DeletePublicShareCourse } from "api";
import { Button, Checkbox, Input, Tooltip, notification } from "antd"
import { CopyOutlined } from "@ant-design/icons"

export const PublicShareField = (props: CoursePreferencesFieldProps) => {

    const { course } = props;
    console.log('course', course)

    const [check, setCheck] = useState(false)
    const [publicUrl, setPublicUrl] = useState("")
    const [api, contextHolder] = notification.useNotification()

    const hash = course.public_share_hash
    const inputRef = useRef(null);

    const handleCreatePublicShare = async () => {
        const response = await CreatePublicShareCourse(course.id)
        // console.log(response)
        setPublicUrl(response.url)

    }

    const deletePublicShare = async () => {
        const response = await DeletePublicShareCourse(course.id)
        console.log(response)
        api.success({
            message: "Public share url has removed."
        })
    }

    const handleChange = (e: any) => {
        setCheck(e.target.checked)

        if (e.target.checked) {
            return handleCreatePublicShare()
        }

        return deletePublicShare()
    }

    useEffect(() => {
        if (hash) {
            handleCreatePublicShare()
            return setCheck(true)
        }
        setCheck(false)
    }, [])

    const handleCopyClick = async () => {
        const input = inputRef.current as any
        console.log('input.value', input.value)
        if (input) {
            input.select();
            try {
                // Use the Clipboard API to copy text
                await navigator.clipboard.writeText(publicUrl);
                api.success({
                    message: "Public share URL copied!"
                })
            } catch (error) {
                api.error({
                    message: "Failed to copy URL"
                })
                console.error('Error copying text: ', error);
            }
        }
    };


    return (
        <div className="field">
            {contextHolder}
            <div className="control " >
                <div className="label">Public Share</div>
                <Checkbox checked={check} onChange={handleChange}>Enable Public Share</Checkbox>
                <div className="tip">Public share is allow anonymous user who have the link to enroll.</div>
            </div>

            {
                check &&
                <div className="control " >
                    <Input.Group compact>
                        <Input
                            ref={inputRef}
                            style={{ width: 'calc(100% - 200px)' }}
                            value={publicUrl}
                        />
                        <Tooltip title="copy url">
                            <Button icon={<CopyOutlined onClick={handleCopyClick} />} />
                        </Tooltip>
                    </Input.Group>
                </div>
            }
        </div>
    )
}

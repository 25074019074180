import React, { useEffect, useState } from 'react'
import { icons } from './CourseEnroll';
import { COURSE } from 'consts';


export default function ContentItem(props: {
    active: string | null,
    data: any,
    contents: any,
    handleActive: (arg: string) => void,
    handleDirect: (data: any, type?: string) => void,
    mode: string
}) {

    const { active, data, contents, handleActive, handleDirect, mode } = props;
    // console.log("cek--->", data)
    const className = active === data.obj_id ? "item active" : "item";
    const [complete, setComplete] = useState(false)

    const handleClick = (e: any) => {
        e.stopPropagation()
        // console.log("aku dipanggil")
        handleActive(data.obj_id)
        handleDirect(data)
    }

    useEffect(() => {
        if (mode === COURSE.ENROLL_MODE.ENROLL || mode === COURSE.ENROLL_MODE.PUBLIC) {

            const obj_id = data.obj_id;
            const target = contents.filter((obj: any) => obj.obj_id === obj_id);
            // console.log("target ---->", target)
            try {
                setComplete(target[0]?.is_complete)

            } catch (error) {
                console.log(error)
            }
        }
    }, [contents]);

    // console.log(data)

    return (
        <div className={complete ? `${className} complete` : className}
            data-type={data.obj_type}
            data-pk={data.id}
            onClick={handleClick}>
            {icons[data.obj_type as keyof typeof icons]}
            <div style={{ justifySelf: "flex-start" }}>{data.title}</div>
        </div>
    )

}

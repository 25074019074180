import { Button, Input } from 'antd';
import { API } from 'api';
import { TERM } from 'consts';
import React, { useState } from 'react'
import { Course } from 'types';

export const NewCourseForm = (props: {
    close: () => void;
}) => {

    const { close } = props;
    const [newCorse, setNewCourse] = useState<Course>({ title: "New Course" });

    const handleCreateNewCourse = async () => {
        const data = JSON.stringify(newCorse);
        const response = await API.Course.Course.CREATE(data);
        console.log(response);
        if (response.status) {
            window.location.href = `/course/edit/?c=${response.data.id}`;
        }
    };

    return (
        <div className={"new-course-form"}>
            <div className="form-box">
                <div className="title is-3">New {TERM.COURSE}</div>
                <Input
                    value={newCorse.title}
                    placeholder={`${TERM.COURSE} Title`}
                    onChange={(e) => setNewCourse({ title: e.target.value })}
                />
                <div className="button-group mt-4">
                    <Button onClick={close}>Cancel</Button>
                    <Button type="primary" onClick={handleCreateNewCourse}>
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}

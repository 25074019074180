import React, { useEffect } from 'react'
import { Input, Select, InputNumber, Checkbox, Rate, Radio, DatePicker } from 'antd';
import FroalaEditor from './FroalaEditor';
import AWSUpload from './AWSUpload';
import dayjs from 'dayjs';

export type FieldType = "char" | "number" | "select" | "boolean" | "date" | "datetime" | "text" | "textarea" | "textEditor" | "checkbox" | "rate" | "radio" | "fileUpload" | "imageUpload" | "password"

export interface FieldProps {
    object: any,
    handleUpdate: (e: any) => void,
    name: string,
    label: string,
    type: FieldType,
    tip?: string,
    className?: string,
    options?: any[],
    placeholder?: string,
    errors?: any
    theme?: string
    loading?: boolean
    disabled?: boolean
    maxLength?: number
    showCount?: boolean
    required?: boolean
    minRows?: number
    maxRows?: number
    autoSize?: boolean
    fileList?: any[]
    onDelete?: (item: any) => void
    callback?: (item: any) => void
    accept?: string
    maxCount?: number
    displayAllowedFileTypes?: boolean
    dateFormat?: string
    prefix?: any
}

type ErrorItem = {
    message: string;
    members: string[];
};

type ErrorsArray = ErrorItem[];

type FormattedErrors = {
    [key: string]: string;
};


export const Field = (props: FieldProps) => {
    const {
        object,
        handleUpdate,
        name,
        label,
        type,
        tip,
        className,
        options,
        placeholder,
        errors: propErrors,
        theme = "light",
        loading = false,
        disabled = false,
        maxLength,
        showCount,
        required = false,
        minRows = false,
        maxRows = 6,
        onDelete,
        fileList,
        callback,
        accept,
        maxCount,
        dateFormat,
        prefix
    } = props;

    const [errors, setErrors] = React.useState<FormattedErrors>({});

    const onChange = (value: any) => {
        // console.log('value', value)
        handleUpdate({ name: name, value: value })
    }

    const formatErrors = (errorsArray: ErrorsArray): FormattedErrors => {
        const errorsObject: FormattedErrors = {};
        errorsArray.forEach((error) => {
            error.members.forEach((member) => {
                errorsObject[member] = error.message;
            });
        });

        return errorsObject;
    };

    useEffect(() => {
        if (propErrors) {
            const formattedErrors = formatErrors(propErrors)
            setErrors(formattedErrors)
        }
    }, [propErrors]);

    // console.log('fileList', fileList)
    return (
        <div className="field">
            {type !== "checkbox" &&
                < div className="label">
                    {required && <span className="is-required">*</span>}
                    {label}
                </div>
            }

            {type === "char" &&
                <Input
                    value={object[name]} // Fix: Use object[name] instead of object.name
                    placeholder={placeholder ? placeholder : label}
                    className={className ? className : ""}
                    onChange={e => onChange(e.target.value)}
                    disabled={disabled}
                    required={required}
                    prefix={prefix}
                />
            }

            {type === "password" &&
                <Input.Password
                    value={object[name]} // Fix: Use object[name] instead of object.name
                    placeholder={placeholder ? placeholder : label}
                    className={className ? className : ""}
                    onChange={e => onChange(e.target.value)}
                    disabled={disabled}
                    required={required}
                    prefix={prefix}
                />
            }

            {type === "select" &&
                <Select
                    value={object[name]} // Fix: Use object[name] instead of object.name
                    placeholder={placeholder ? placeholder : label}
                    options={options}
                    className={className ? className : ""}
                    onChange={e => onChange(e)}
                    loading={loading}
                    disabled={disabled}
                    aria-required={required}

                />
            }

            {type === "number" &&
                <InputNumber
                    value={object[name]} // Fix: Use object[name] instead of object.name
                    placeholder={placeholder ? placeholder : label}
                    className={className ? className : ""}
                    onChange={e => onChange(e)}
                    disabled={disabled}
                    required={required}
                    prefix={prefix}
                />
            }

            {((type === "text") || (type === "textarea")) &&
                <Input.TextArea
                    value={object[name]} // Fix: Use object[name] instead of object.name
                    placeholder={placeholder ? placeholder : label}
                    className={className ? className : ""}
                    onChange={e => onChange(e.target.value)}
                    disabled={disabled}
                    maxLength={maxLength}
                    showCount={showCount}
                    required={required}
                    autoSize={minRows ? { minRows: minRows, maxRows: maxRows } : false}
                    prefix={prefix}
                />
            }

            {type === "checkbox" &&
                <Checkbox
                    checked={object[name]} // Fix: Use object[name] instead of object.name
                    onChange={e => onChange(e.target.checked)}
                    className={className ? className : ""}
                    disabled={disabled}

                >
                    {label}
                </Checkbox>
            }

            {type === "radio" &&
                <Radio.Group
                    value={object[name]} // Fix: Use object[name] instead of object.name
                    onChange={e => onChange(e.target.value)}
                    className={className ? className : ""}

                >
                    {options && options.map((item, index) => (
                        <Radio.Button key={index} value={item.value}>{item.label}</Radio.Button>
                    ))}
                </Radio.Group>
            }

            {type === "textEditor" &&
                <FroalaEditor
                    content={object[name]} // Fix: Use object[name] instead of object.name
                    onContentChange={e => onChange(e)}
                    className='content'
                    theme={theme}
                />
            }

            {type === "rate" &&
                <Rate
                    className='field-rate'
                    value={object[name]}
                    onChange={e => onChange(e)}

                />
            }


            {type === "fileUpload" &&
                <AWSUpload
                    files={fileList}
                    accept={accept}
                    maxCount={maxCount}
                    onDelete={onDelete}
                    callback={callback}
                    placeholder={placeholder}
                />
            }


            {type === "date" &&
                <DatePicker
                    value={dayjs((object[name]).format(dateFormat), dateFormat)}
                    format={dateFormat}
                    onChange={date => onChange(date)}
                    prefix={prefix}
                />

            }

            {tip &&
                <div className="tip mt-1">{tip}</div>
            }

            {errors[name] &&
                <div className="error">{errors[name]}
                </div>
            }
        </div >
    )
}



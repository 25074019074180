import { Input } from 'antd'
import { IMAGE } from 'consts'
import { Chat } from 'curiobites-chat';
import { getToken } from 'functions';
import React from 'react'
import { Link } from 'react-router-dom'
import Notification from 'components/notification/Notification';



interface Props {
    searchKeyword?: string;
    setSearchKeyword: (value: string) => void;
    handleSearch: () => void;
    loading: boolean;
    user: any;
    setActiveMenu: (active: boolean) => void;
}

export default function NavbarDesktop(props: Props) {
    const { searchKeyword, setSearchKeyword, handleSearch, loading, user, setActiveMenu } = props;
    return (
        <div className="navbar">
            <div className="nav-brand">
                <Link to="/">
                    <img className="logo" src={IMAGE.LOGO} alt="" />
                </Link>
            </div>
            <div className="nav-center">
                <div className="field w-100">
                    <p className="control has-icons-right">
                        <Input.Search placeholder="Search"
                            value={searchKeyword}
                            onChange={e => setSearchKeyword(e.target.value)}
                            onSearch={handleSearch}
                        />
                    </p>
                </div>
            </div>
            <div className="nav-end">
                {loading && null}

                {!loading && user &&
                    <div className="nav-user">
                        <div className="extra">
                            <Chat
                                mode="Local"
                                token={getToken()}
                                user={{ id: user.user }}
                                className={"chat"}
                            />
                            <Notification />
                        </div>
                        <div className="user" onClick={() => setActiveMenu(true)}>
                            <div className="username mr-2">{user.username}</div>
                            {user.avatar ?
                                <img className="avatar" src={user.avatar} alt="" /> :
                                <img className="avatar" src={`https://ui-avatars.com/api/?name=${user.firstname}+${user.lastname}&background=random&rounded=true`} alt="" />
                            }
                        </div>
                        <div className="user-menu">
                            <ul onClick={() => setActiveMenu(false)}>
                                <li className="account-balance">Account Balance</li>
                                <hr />
                                <Link to="/profile">
                                    <li>Library</li>
                                </Link>
                                <Link to={"/setting"}>
                                    <li className="settings-button">Settings</li>
                                </Link>
                                <hr />
                                <a href='/logout'>
                                    <li className="logout-button">Logout</li>
                                </a>
                            </ul>
                        </div>
                        <div className="close-popup" onClick={() => setActiveMenu(false)}></div>
                    </div>
                }

                {!loading && !user &&
                    <div className="group">
                        <Link to="/login">
                            <div className="button is-outlined is-secondary mr-2">Login</div>
                        </Link>
                        <Link to="/register">
                            <div className="button is-secondary">Register Free</div>
                        </Link>
                    </div>
                }
            </div>
        </div>
    )
}

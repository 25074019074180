import React, { memo, useEffect, useState } from 'react'
import { Button, Checkbox, Image, List, Popconfirm, Space, Table, Tag } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Course, Program } from 'types';
import { API } from 'api';
import { IMAGE, TERM } from 'consts';


function ProgramCourses(props: {
    program: Program
}) {
    const { program } = props;

    const [displayForm, setDisplayForm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [courses, setCourses] = useState<Course[]>([])

    const getProgramCourses = async () => {
        setLoading(true)
        const response = await API.Classroom.ProgramCourse.GET_COURSES(program.id)

        console.log(response)
        if (response.status) {
            const newData = response.data.map((item: Course) => ({
                id: item.id,
                courseId: item.course,
                name: item.course_name,
                days: item.period,
                image: item.image ? item.image : IMAGE.DEFAULT
            }));
            setCourses(newData);
        }
        setLoading(false)
    }

    useEffect(() => {
        getProgramCourses()
    }, [program])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, record: Course) => {
                const imageUrl = record.image;
                console.log(name, record)
                return (
                    <Space onClick={() => handleEdit(record)} style={{ cursor: "pointer" }}>
                        <MemoizedImage src={imageUrl} />
                        <div>
                            <p>{name}</p>
                            <div className="tip">{record.description}</div>
                        </div>
                    </Space>


                );
            },
            sorter: (a: Course, b: Course) => a.name.localeCompare(b.name),
            className: "has-avatar"

        },
        {
            title: "Days",
            dataIndex: 'days',
            key: 'days',
            sorter: (a: Course, b: Course) => a.days - b.days,
        },

        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            className: "action",
            width: "110px",
            render: (record: Course) => (
                <>
                    <Button icon={<EditOutlined />} className='mr-1'
                        onClick={() => handleEdit(record)}></Button>
                    <Popconfirm
                        placement="bottomRight"
                        title={"Delete " + TERM.COURSE}
                        description={`Are you sure to delete this ${TERM.COURSE}?`}
                        onConfirm={() => deleteProgramCourse(record.id)}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ loading: loading }}
                    >
                        <Button icon={<DeleteOutlined />}></Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    const handleEdit = (record: Course) => {
        console.log('record', record)
    }

    const deleteProgramCourse = async (courseId: string) => {
        setLoading(true)
        const response = await API.Classroom.ProgramCourse.DELETE_COURSE(courseId)

        console.log(response)
        if (response.status) {
            setCourses((prev: any) => (prev.filter((item: Course) => item.id !== courseId)))
        }
        setLoading(false)
    }

    const handleRowClick = async (record: Course) => {
        console.log('record', record)
    }


    return (
        <div className="p-5">
            {
                displayForm &&
                <FormAddCourses
                    key={"form-add-courses"}
                    setDisplayForm={setDisplayForm}
                    program={program}
                    courses={courses}
                    setCourses={setCourses}
                />
            }
            <div className="header flex-group no-border mb-3">
                <div className="title is-5 mb-3">{TERM.COURSE}s</div>
                <Button type='primary' onClick={() => setDisplayForm(true)}>+ Add</Button>
            </div>

            {
                courses &&
                <Table key={"classroom-program-courses-table"}
                    className={"is-table"}
                    columns={columns}
                    dataSource={courses}
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record),
                    })} />
            }
        </div>
    )
}


const FormAddCourses = (props: {
    setDisplayForm: (value: boolean) => void,
    program: Program,
    courses: Course[],
    setCourses: (value: any) => void

}) => {
    const { setDisplayForm, program, courses, setCourses } = props;
    const [availableCourses, setAvailableCourses] = useState(null)

    const getCoursesForProgram = async () => {
        const response = await API.Classroom.ProgramCourse.GET_AVAILABLE_COURSES(program.id)
        console.log(response)
        if (response.status) {
            const allAvailableCourses = response.data;
            const programCourseIds = courses.map(course => course.courseId);
            const availableCourses = allAvailableCourses.filter((course: Course) => !programCourseIds.includes(course.id));
            setAvailableCourses(availableCourses);
        }
    }

    useEffect(() => {
        getCoursesForProgram()
    }, [])

    const [checkedList, setCheckedList] = useState<any[]>([]);

    const handleCheckboxChange = (id: string) => {
        const currentIndex = checkedList.indexOf(id);
        const newCheckedList = [...checkedList];

        if (currentIndex === -1) {
            newCheckedList.push(id);
        } else {
            newCheckedList.splice(currentIndex, 1);
        }

        setCheckedList(newCheckedList);
    };

    const saveProgramCourses = async () => {
        const response = await API.Classroom.ProgramCourse.ADD_COURSES_TO_PROGRAM(program.id, checkedList)
        console.log(response)
        if (response.status) {
            setCourses(response.data)
            setDisplayForm(false)
        }
    }

    return (
        <div className="form-add-courses">
            <div className="modal-box">
                <div className="modal-header flex-group px-5 py-3">
                    <div className="title is-5 m-0">Attach Courses</div>
                    <div className="delete" onClick={() => setDisplayForm(false)}></div>
                </div>

                <div className="modal-body p-5">
                    {availableCourses &&

                        <List
                            dataSource={availableCourses}
                            renderItem={
                                (item: any) => (
                                    <List.Item key={item.id} className='course-item'>
                                        <Checkbox
                                            checked={checkedList.includes(item.id)}
                                            onChange={() => handleCheckboxChange(item.id)}
                                        >
                                            {item.course_name}
                                        </Checkbox>
                                        <Tag style={{ width: "max-content" }}>
                                            {item.status}
                                        </Tag>
                                        <span>{item.modules}</span>
                                        <span>{item.lessons}</span>
                                        <span>{item.quizes}</span>
                                        <span>{item.tasks}</span>
                                    </List.Item>
                                )}
                        />


                    }
                    <div className="button-group mt-3">
                        <Button className='mr-1' onClick={() => setDisplayForm(false)}>Cancel</Button>

                        <Button onClick={saveProgramCourses} type='primary'>Save</Button>
                    </div>

                </div>
            </div>
            <div className="overlay"></div>
        </div>
    )
}

const MemoizedImage = memo(({ src }: { src: string }) => {
    return <Image src={src} style={{ width: 40, height: 40, objectFit: "cover" }} />;
});

export default memo(ProgramCourses)

import { Button, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import PasswordStrengthBar from "react-password-strength-bar";
import { validatePassword } from 'functions';
import { IMAGE, PAGE, PATH } from 'consts';
import { RegisterData } from 'types';
import { API } from 'api';
import "./auth.css"
import { useUserContext } from 'UserContext';


const initialData = {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    password_confirm: "",
}

export default function Register() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const next = searchParams.get("next")
    const [message, setMessage] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const [registerData, setRegisterData] = useState<RegisterData>(initialData)
    const [error, setError] = useState<{ [key: string]: string }>({})

    const { setPage } = useUserContext()

    useEffect(() => {
        setPage(PAGE.REGISTER)
        document.documentElement.classList.add("no-scroll")
        return () => {

            document.documentElement.classList.remove("no-scroll")
        }
    }, []);


    const handleChange = ({ name, value }: any) => {
        setError({})
        setRegisterData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRegister = async () => {
        setLoading(true)
        const rd = registerData
        const notvalid = validatePassword(rd.password, rd.password_confirm, rd.username, rd.email)

        setMessage(notvalid)

        if (notvalid) {
            setLoading(false)
            return
        }
        const response = await API.Auth.Register(registerData, next)
        setLoading(false)
        console.log('response', response)
        if (response.status) {
            navigate(PATH.REGISTRATION_SUCCESS)
        } else {
            setError(response.error)
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        console.log("Enter key pressed on form submission");
    }


    return (
        <div className="register" >
            <div className="image-box">
                <img src={IMAGE.LOGIN} alt="" />
            </div>


            <div className="register-panel">
                <div className="logo-box">
                    <Link to={"/"}>
                        <img src={IMAGE.LOGO} alt="logo" className='logo' />
                    </Link>
                </div>

                <form className="register-form" onSubmit={handleSubmit}>
                    <div className="form-header">
                        <h1 className='title is-5 mb-0 text-center'>Getting started is free!</h1>
                        <p className="tip mb-5">No credit card needed.</p>
                    </div>

                    <NameField registerData={registerData} handleChange={handleChange} />
                    <UsernameField error={error} registerData={registerData} handleChange={handleChange} />
                    <EmailField registerData={registerData} handleChange={handleChange} />
                    <PasswordField error={error} registerData={registerData} handleChange={handleChange} />
                    {message ? <p className="message">{message}</p> : ""}

                    <Button type='primary' onClick={handleRegister} loading={loading}>Register</Button>

                    <p className='my-4 text-center'>Already have an account?</p>
                    <Link to={next ? `/login?next=${next}` : "/login"}><Button >Login</Button></Link>

                </form>
            </div>
        </div>
    )
}

const NameField = (props: {
    registerData: RegisterData,
    handleChange: (param: any) => void,
}) => {
    const { registerData, handleChange } = props
    return (
        <div className="field">
            <div className="control">
                <div className="name-field">
                    <div className="group">
                        <div className="label">First Name</div>
                        <Input value={registerData.first_name} placeholder="First name"
                            onChange={e => handleChange({ name: "first_name", value: e.target.value })} />
                    </div>
                    <div className="group">
                        <div className="label">Last Name</div>
                        <Input value={registerData.last_name} placeholder="Last name"
                            onChange={e => handleChange({ name: "last_name", value: e.target.value })} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const UsernameField = (props: {
    registerData: RegisterData,
    handleChange: (param: any) => void,
    error: { [key: string]: string }
}) => {

    const { registerData, handleChange, error } = props

    return (
        <div className="field">
            <div className="control">
                <div className="label">Username</div>
                <Input value={registerData.username} placeholder="Username"
                    onChange={e => handleChange({ name: "username", value: e.target.value })} />
                {error.username &&
                    <div className="field-error">{error.username}</div>}
            </div>
        </div>
    )
}

const EmailField = (props: {
    registerData: RegisterData,
    handleChange: (param: any) => void,

}) => {
    const { registerData, handleChange } = props
    return (
        <div className="field">
            <div className="control">
                <div className="label">Email</div>
                <Input value={registerData.email} placeholder="Email"
                    onChange={e => handleChange({ name: "email", value: e.target.value })} />
            </div>
        </div>
    )
}

const PasswordField = (props: {
    registerData: RegisterData,
    handleChange: (param: any) => void,
    error: { [key: string]: string }
}
) => {
    const { registerData, handleChange } = props
    const [displayPassStr, setDisplayPassStr] = useState(false)
    return (
        <>
            <div className="field password">
                <div className="control">
                    <div className="label">Password</div>
                    <Input.Password value={registerData.password} placeholder="Password"
                        onFocus={() => setDisplayPassStr(true)}
                        onBlur={() => setDisplayPassStr(false)}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        onChange={e => handleChange({ name: "password", value: e.target.value })} />
                    {
                        displayPassStr &&
                        <PasswordStrengthBar password={registerData.password} />
                    }
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <div className="label">Password Confirm</div>
                    <Input.Password value={registerData.password_confirm} placeholder="Password confirm"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        onChange={e => handleChange({ name: "password_confirm", value: e.target.value })} />
                </div>
            </div>
        </>
    )
}

import React from 'react';
import cookie from 'react-cookies';
import moment from 'moment';
import AWS from 'aws-sdk';
import { AWS_ACCESS_KEY_ID, AWS_REGION, AWS_SECRET_ACCESS_KEY, AWS_STORAGE_BUCKET_NAME, HOST, MOOD, COURSE } from 'consts';
import { createRoot } from 'react-dom/client';


export function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export const getRandomImage = () => {
    return `https://meta-opt.s3.ap-southeast-1.amazonaws.com/abstracts/${getRandomInt(1, 50)}.jpg`;
}

export const getAvatarUrlByName = (name: string, rounded: boolean = true) => {
    return `https://ui-avatars.com/api/?name=${name}&background=random&rounded=${rounded}`
}

export function getKey(n = 15) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;

    for (let i = 0; i < n; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export async function getProfile() {
    const tokenAccess = cookie.load('ta_');
    const endpoint = HOST + '/api/UserProfile/';
    const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenAccess}`
        },
    });

    const result = await response.json();
    return result.data;
}

export const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export function numberToUpperCaseLetter(index: number) {
    // get ASCII code for uppercase A and add index
    const asciiCode = 'A'.charCodeAt(0) + index;

    // convert ASCII code to character and return
    return String.fromCharCode(asciiCode);
}

export function HTMLRenderer(props: {
    className?: string;
    content: string;

}) {
    const { className, content } = props;
    return (
        <div className={className ? className : "content"} dangerouslySetInnerHTML={{ __html: content }} />
    );
}

export function getToken() {
    const token = cookie.load("ta_")
    return token
}
export function getRole() {
    const token = cookie.load("role_")
    return token
}
export function formattedDate(date: string) {

    let diffSeconds = null
    let diffDays = null
    let formated = null

    diffSeconds = moment().diff(moment(date), 'seconds');

    // Convert the difference to days
    diffDays = Math.floor(diffSeconds / 86400);

    // Format the date based on the difference in days
    if (diffDays === 0) {
        formated = 'Today';
    } else if (diffDays === 1) {
        formated = 'Yesterday';
    } else {
        formated = `${diffDays} days ago`;
    }

    return formated
}



export function validatePassword(password: string, password_confirm: string, username: string, email: string) {

    if (password !== password_confirm) {
        return "Password not match with confirm!";
    }
    // Check if the password is at least 8 characters long
    if (password.length < 8) {
        return "Password must be at least 8 characters long";
    }

    // Check if the password is a common password
    const commonPasswords = [
        "password",
        "12345678",
        // add more common passwords here
    ];
    if (commonPasswords.includes(password.toLowerCase())) {
        return "Password is too common";
    }

    // Check if the password is too similar to the username or email
    if (username && password.toLowerCase().includes(username.toLowerCase())) {
        return "Password is too similar to the username";
    }
    if (email && password.toLowerCase().includes(email.toLowerCase())) {
        return "Password is too similar to the email";
    }

    // Check if the password contains at least one uppercase letter, one lowercase letter, and one digit
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /[0-9]/;
    if (!uppercaseRegex.test(password)) {
        return "Password must contain at least one uppercase letter";
    }
    if (!lowercaseRegex.test(password)) {
        return "Password must contain at least one lowercase letter";
    }
    if (!digitRegex.test(password)) {
        return "Password must contain at least one digit";
    }

    // If all checks pass, the password is valid
    return null;
}


export function getMood(index: number) {
    switch (index) {
        case 1:
            return MOOD._1
        case 2:
            return MOOD._2
        case 3:
            return MOOD._3
        case 4:
            return MOOD._4
        case 5:
            return MOOD._5
        default:
            return null
    }
}


export async function reorderData(data: any, type: string, pk: string) {
    const endpoint = HOST + `/api/Course/ReorderContent/?type=${type}&pk=${pk}`
    const method = "POST"
    const body = JSON.stringify(data)
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
    }

    const response = await fetch(endpoint, { method, headers, body })
        .then(r => r.json()).then(r => (r)).catch(err => { console.log(err) })

    console.log(response);
    return response
}


export const getDragContentStyle = (color: string, isDragging: boolean, draggableStyle: any) => {
    // define a function to generate a random light color using HSL
    return {
        // some basic styles to make the items look a bit nicer
        userSelect: "none",

        // change background colour if dragging
        backgroundColor: isDragging ? color : "#fafafd",
        color: isDragging ? "#24292f" : "#24292f",
        boxShadow: isDragging ? "inset 0 0 0 1px #111429" : "none",

        // styles we need to apply on draggables
        ...draggableStyle,
    };
};

export const getRandomColor = () => {
    const hue = Math.floor(Math.random() * 360); // hue can be any value between 0 and 359
    const saturation = "80%"; // set saturation to a fixed value
    const lightness = "90%"; // set lightness to a fixed value to generate a light color
    return `hsl(${hue}, ${saturation}, ${lightness})`;
};


export const getResponseTypeIcon = (responseType: COURSE.QUESTION.RESPONSE_TYPE | string) => {
    // console.log(rt)
    switch (responseType) {
        case "Option":
            return <i className="las la-list response-type-icon"></i>;
        case "YesNo":
            return <i className="las la-check-circle response-type-icon"></i>;
        case "Yes/No":
            return <i className="las la-check-circle response-type-icon"></i>;
        case "Text":
            return <i className="las la-align-center response-type-icon"></i>;
        case "Attachment":
            return <i className="las la-paperclip response-type-icon"></i>;
        case "Date":
            return <i className="las la-calendar response-type-icon"></i>;
        default:
            return <i className="las la-grin response-type-icon"></i>;
    }
};

export const starSVG = "M11.4434703,19.4783366 L7.11534027,21.8561884 C6.53071469,22.1773786 5.80762087,21.9424899 5.50026501,21.3315498 C5.3778743,21.0882703 5.3356403,20.8096129 5.38010133,20.5387172 L6.2067006,15.5023462 C6.27323987,15.0969303 6.14461904,14.6832584 5.86275418,14.3961413 L2.36122346,10.8293635 C1.88825143,10.3475782 1.87857357,9.55633639 2.33960735,9.06207547 C2.52319342,8.86525818 2.76374635,8.73717345 3.02402575,8.69765029 L7.8630222,7.96285367 C8.25254987,7.90370429 8.58928356,7.64804097 8.76348563,7.27918144 L10.9275506,2.69693973 C11.2198634,2.07798981 11.936976,1.82386417 12.5292664,2.12933421 C12.7651196,2.25097399 12.9560234,2.45047063 13.0724239,2.69693973 L15.2364889,7.27918144 C15.410691,7.64804097 15.7474247,7.90370429 16.1369524,7.96285367 L20.9759488,8.69765029 C21.6295801,8.79690353 22.0824579,9.43108706 21.9874797,10.1141388 C21.9496589,10.3861337 21.827091,10.6375141 21.6387511,10.8293635 L18.1372204,14.3961413 C17.8553555,14.6832584 17.7267347,15.0969303 17.793274,15.5023462 L18.6198732,20.5387172 C18.7315268,21.219009 18.2943081,21.8650816 17.6433179,21.9817608 C17.3840902,22.028223 17.1174353,21.984088 16.8846343,21.8561884 L12.5565043,19.4783366 C12.2081001,19.2869252 11.7918744,19.2869252 11.4434703,19.4783366 Z"

export const AWSFileUpload = async (file: any, onProgress: any) => {
    const fileName = addRandomStringToFileName(file.name);
    const s3 = new AWS.S3({
        accessKeyId: AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_SECRET_ACCESS_KEY,
        region: AWS_REGION,
    });

    const params: any = {
        Bucket: AWS_STORAGE_BUCKET_NAME,
        Key: "UserUploads/" + fileName,
        Body: file,
        ContentType: file.type,
    };

    const upload = s3.upload(params);

    upload.on('httpUploadProgress', (progress) => {
        const percentCompleted = Math.round((progress.loaded / progress.total) * 100);
        onProgress(percentCompleted);
    });

    try {
        const data = await upload.promise();
        console.log('File uploaded successfully.', data.Location);
        return data
    } catch (error) {
        console.error('Error uploading file:', error);
        throw new Error('Error uploading file');
    }
};

export const GetAWSPresignedUrl = async (file: any) => {
    const expirationSeconds = 60
    const fileName = addRandomStringToFileName(file.name)

    const s3 = new AWS.S3({
        accessKeyId: AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_SECRET_ACCESS_KEY,
        region: AWS_REGION,
    });

    const objectKey = "UserFiles/" + fileName

    const params = {
        Bucket: AWS_STORAGE_BUCKET_NAME,
        Key: objectKey,
        Expires: expirationSeconds, // URL expiration time in seconds
    };

    return s3.getSignedUrl('getObject', params);

};

const addRandomStringToFileName = (fileName: string) => {
    const randomString = getKey(15);
    const extension = fileName.split('.').pop(); // Get the file extension
    const baseFileName = fileName.replace(`.${extension}`, ''); // Remove the extension
    const newFileName = `${baseFileName}_${randomString}.${extension}`;
    return newFileName;
};

export const GetFileNameFromAWSUrl = (url: string) => {
    if (!url) return ""
    let fileName = url.split('/').pop() as string;
    fileName = decodeURIComponent(fileName)
    fileName = fileName.replace(/_\w+(\.\w+)$/, '$1');
    return fileName
}

export const getYouTubeVideoId = (url: string) => {
    // Example YouTube URL formats:
    // - https://www.youtube.com/watch?v=VIDEO_ID
    // - https://youtu.be/VIDEO_ID
    const regex = /^(?:(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/))([a-zA-Z0-9_-]+)$/i;
    const match = url.match(regex);

    return match ? match[1] : null;
};



// Extending the HTMLElement type to include __reactRootContainer
declare global {
    interface HTMLElement {
        __reactRootContainer?: ReturnType<typeof createRoot>;
    }
}

const ROOT_PROPERTY = '__reactRootContainer';

export const modalRoot = () => {
    const modalContainer = document.getElementById('app-modal');
    if (!modalContainer) return null;

    if (modalContainer[ROOT_PROPERTY]) {
        return modalContainer[ROOT_PROPERTY];
    }

    const root = createRoot(modalContainer);
    modalContainer[ROOT_PROPERTY] = root;
    return root;
};

export function cls(...classes: (string | false | null | undefined)[]): string {
    return classes.filter(Boolean).join(' ');
}
import React from 'react'
import { Radio } from 'antd';
import MyEditor from 'components/ui/FroalaEditor';
import { Question } from 'types';

export default function AttachmentQuestionForm(props: {
    question: any,
    updateQuestion: (obj: any) => void
}) {
    const { question, updateQuestion } = props

    const handleContent = (value: any) => {
        updateQuestion({ name: "question", value: value })
    }

    return (
        <div className="question-box" datatype="text">
            <div className="question-field form-box">
                <div className="label">Question</div>
                <MyEditor
                    content={question.question}
                    onContentChange={handleContent}
                />
            </div>

            <hr />
            <div className="options mt-5">
                <div className="flex-group mb-4">
                    <div className="title is-6 m-0">Attachment</div>
                </div>
                <div className="field">
                    <div className="control">
                        <Radio.Group onChange={(e) => updateQuestion({ name: "attachment_type", value: e.target.value })} value={question.attachment_type}>
                            <Radio.Button value="Document">Document</Radio.Button>
                            <Radio.Button value="Image">Image</Radio.Button>
                            <Radio.Button value="Audio">Audio</Radio.Button>
                            <Radio.Button value="Video">Video</Radio.Button>
                        </Radio.Group>
                        <div className="tip mt-2">
                            Allowed format : {allowedFormat(question)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const allowedFormat = (question: Question) => {
    switch (question.attachment_type) {
        case "Document":
            return ".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .csv, .txt";
        case "Image":
            return ".jpg, .jpeg, .png, .gif";
        case "Audio":
            return ".mp3, .wav";
        case "Video":
            return "Video Url (YouTube, Vimeo)";
        default:
            return ".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .csv, .txt";
    }
}
import React, { useEffect, useState } from 'react'
import { Link, useParams, } from 'react-router-dom'
import "./auth.css"

import { Button, Input } from 'antd'
import { API } from 'api';
import { validatePassword } from 'functions';
import { IMAGE } from 'consts';
import { User } from 'types';

export default function ResetPassword() {

    const { hash } = useParams()
    const [password, setPassword] = useState({
        hash: hash,
        password: "",
        password_confirm: ""
    })

    const [user, setUser] = useState<User | null>(null)
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState<string | null>(null)

    const handleUpdate = ({ name, value }: any) => {
        setPassword(prev => ({
            ...prev, [name]: value
        }))
    }

    const handleGetResetPassword = async () => {
        if (!hash) return
        setLoading(true)
        const response = await API.Auth.Password.GetResetPasswordUserWithHash(hash)
        console.log(response)
        if (response.status) {
            setUser(response.data)
        }
        setLoading(false)
    }

    const handleChangePassword = async () => {
        if (!user) return
        const notvalid = validatePassword(password.password, password.password_confirm, user.username, user.email)
        setMessage(notvalid)
        if (notvalid) {
            setLoading(false)
            return
        }
        const data = JSON.stringify(password)
        const response = await API.Auth.Password.SubmitNewPassword(data)
        console.log(response)
        if (response.status) {
            window.location.href = "/login"
        }
    }

    useEffect(() => {
        if (hash) {
            handleGetResetPassword()
        }
    }, [hash]);
    return (
        <div className="reset-password">
            <div className="image-box">
                <img src={IMAGE.LOGIN} alt="" />
            </div>

            <div className="reset-password-panel">
                <div className="logo-box">
                    <Link to={"/"}>
                        <img src={IMAGE.LOGO} alt="logo" className='logo' />
                    </Link>
                </div>

                <form action="" className="reset-password-form">
                    <h1 className='title is-5 text-center'>Change your password</h1>
                    {message ? <p className="message">{message}</p> : ""}


                    <div className="field">
                        <label className="label">New Password</label>
                        <Input.Password
                            placeholder='Enter your new password'
                            value={password.password}
                            onChange={e => handleUpdate({ name: "password", value: e.target.value })}
                        />
                    </div>

                    <div className="field">
                        <label className="label">Password Confirm</label>
                        <Input.Password
                            placeholder='Enter your new password again'

                            value={password.password_confirm}
                            onChange={e => handleUpdate({ name: "password_confirm", value: e.target.value })}
                        />
                    </div>

                    <Button
                        loading={loading}
                        type='primary'
                        onClick={handleChangePassword}>Change Password</Button>

                </form>


            </div>
        </div>
    )
}

import { Button, notification } from 'antd'
import { API } from 'api';
import { HTMLRenderer, numberToUpperCaseLetter } from 'functions'
import React, { useEffect, useState } from 'react'
import { QuestionViewProps } from '../QuestionView';
import { Answer } from 'types';
import { COURSE } from 'consts';
import cookie from "react-cookies"


export default function YesNoQuestionView(props: QuestionViewProps & {
    type: string
    mode: COURSE.ENROLL_MODE
}) {
    const { data, mode, session, type, setAllowNext } = props;
    const [answer, setAnswer] = useState<Answer>([])
    const [confirm, setConfirm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();

    const publicUser = cookie.load("public_user")

    console.log("data ---->.", data)

    const updateAnswer = (answerId: string) => {
        setConfirm(false)
        if (data.multiple_answer) {
            if (answer.includes(answerId)) {
                // remove answerId from the array
                setAnswer((prev: any) => prev.filter((id: string) => id !== answerId))
            } else {
                // add answerId to the array
                setAnswer((prev: any) => [...prev, answerId])
            }
            return
        }
        setAnswer([answerId])
    }

    const handleSaveAnswer = async () => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append('question', JSON.stringify(data));
            formData.append('answer', answer);
            formData.append('session', session);
            formData.append('type', type)
            formData.append('mode', mode);
            if (mode === COURSE.ENROLL_MODE.PUBLIC) {
                formData.append("user", publicUser)
            }
            const response = await API.Course.UserAnswer.SAVE(formData, mode)
            console.log('type', type)
            console.log(response)
            if (type === COURSE.CONTENT_TYPE.TASK_QUESTION || data.required_score) {
                api.success({
                    message: "Answer saved successfully",
                })
            }
            setAllowNext(true)
        } catch (error) {
            api.error({
                message: "Failed to save answer",
            })
        }
        setLoading(false)
    }

    const handleConfirm = () => {
        setConfirm(true)
        if (mode === COURSE.ENROLL_MODE.ENROLL || mode === COURSE.ENROLL_MODE.PUBLIC) {
            handleSaveAnswer()
        } else {
            setAllowNext(true)

        }

    }

    const getAnswerItemClassName = (item: any) => {
        let className = 'answer';
        if (answer.includes(item.id)) {
            className += ' selected';
        }
        if (confirm) {
            if (type !== "TaskQuestion" && !data.required_score) {

                if (item.correct && answer.includes(item.id)) {
                    className += ' is-true';
                } else if (!item.correct && answer.includes(item.id)) {
                    className += ' is-false';
                }
            }
        }
        return className
    }

    useEffect(() => {
        // console.log(data.user_answer.length)
        if (data.user_answer.length > 0) {
            setAllowNext(true)
            console.log(data.user_answer)
            if (type === "TaskQuestion") {
                setAnswer([data.user_answer[0].user_answer_yes_no])

            } else if (type === "Question") {
                setAnswer([data.user_answer[0].answer])
            }
        }
    }, [data])
    // console.log(answer)

    return (
        <div className={data.response_type + "-view"}>
            {contextHolder}
            <div className="question">
                <div className="question-label">Question</div>
                <HTMLRenderer content={data.question} />
            </div>

            <div className="tip mt-4 mb-3">Select your answer.</div>

            <div className={"mb-4 answer-box " + data.response_type}>
                {data.options.map((item: any, index: number) => (
                    <div className="option-item" key={item.id}>
                        <div id={"answer-option-" + item.id}
                            className={getAnswerItemClassName(item)}

                            onClick={() => updateAnswer(item.id)}
                        >
                            <div className="option-index">
                                {numberToUpperCaseLetter(index)}
                            </div>
                            <div className="option-value">
                                {item.answer}
                            </div>
                        </div>
                        {type !== "TaskQuestion" && !data.required_score &&
                            confirm && answer.includes(item.id) ?
                            <div className="explanation-box mb-3">
                                <HTMLRenderer content={item.explanation} />
                            </div> : null
                        }
                    </div>
                ))}
            </div>

            <Button type='primary'
                loading={loading}
                disabled={!answer.length}
                onClick={handleConfirm}>Confirm</Button>

        </div>
    )
}

import { Popconfirm } from 'antd'
import { getResponseTypeIcon, HTMLRenderer } from 'functions'
import React from 'react'

export default function QuestionListItem(props: {
    question: any,
    deleteQuestion: (questionId: string) => void,
    editQuestion: (questionId: string) => void
}) {
    const { question, deleteQuestion, editQuestion } = props

    const handleDeleteQuestion = () => {
        deleteQuestion(question.id)
    }

    const handleEditQuestion = () => {
        editQuestion(question.id)
    }

    return (
        <>
            <div className="title-group">
                {getResponseTypeIcon(question.response_type)}
                <HTMLRenderer content={question.question} />
            </div>

            <div className="option">
                <i className="fa-solid fa-pen" onClick={handleEditQuestion}></i>
                <Popconfirm
                    placement="topRight"
                    title={"Delete Question"}
                    description={"Are you sure to delete this question ?"}
                    onConfirm={handleDeleteQuestion}
                    okText="Delete"
                    cancelText="No"
                >
                    <i className="fa-solid fa-trash" ></i>
                </Popconfirm>

            </div>
        </>
    )

}




import { Button, notification, Popconfirm } from 'antd'
import React, { useEffect, useState, } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Term } from 'App'
import { DeleteCourse, GetCourse, UpdateCourse } from 'api'
import WithAuth from 'WithAuth'
import { Course } from 'types'
import { PAGE } from 'consts'
import { useUserContext } from 'UserContext'
import { TagsField } from './TagsField'
import { Field } from 'components/ui/Field'
import { ReviewOptionsField } from './ReviewOptionsField'
import { PublicShareField } from './PublicShareField'
import { IntegrationField } from './IntegrationField'
import { CourseImageField } from './CourseImageField'


export interface CoursePreferencesFieldProps {
    course: Course
    handleInputChange: (data: any) => void
}


function CoursePreferences(props: {
    reload: () => void
}) {

    const { reload } = props
    const [searchParams] = useSearchParams()
    const courseId = searchParams.get("c")

    const [course, setCourse] = useState<Course | null>(null)
    const [loading, setLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification()
    const { setPage } = useUserContext()

    useEffect(() => {
        setPage(PAGE.COURSE_BUILDER)
        document.documentElement.classList.add("no-scroll")
        return () => {
            document.documentElement.classList.remove("no-scroll")
        }

    }, [setPage]);


    const initCourseInput = (data: any) => {
        const { ...rest } = data;
        console.log('data di init', data)
        // console.log(data.image);
        setCourse((prevCourse: any) => ({
            ...prevCourse,
            ...rest
        }));

    }

    const handleInputChange = ({ name, value }: any) => {
        setCourse((prevCourse: any) => ({
            ...prevCourse,
            [name]: value
        }));
    };

    const handleGetCourse = async () => {
        if (!courseId) return
        const response = await GetCourse(courseId)
        console.log('course get ', response)
        if (response.status) {
            initCourseInput(response.data)
        }

    }

    const hanldeUpdateCourse = async () => {
        if (!courseId) return
        setLoading(true)
        const data = JSON.stringify(course)
        const response = await UpdateCourse(data, courseId)
        console.log(response)
        if (response.status) {
            api.success({
                message: "Course save!",
                duration: 2
            })
            reload()
        }
        setLoading(false)
    }

    const handleDeleteCourse = async () => {
        if (!courseId) return
        setLoading(true)
        const response = await DeleteCourse(courseId)
        console.log(response)
        setLoading(false)
        if (response.status) {
            window.location.href = '/profile?tab=authored-courses'
        }
    }

    useEffect(() => {
        handleGetCourse()
    }, [courseId])

    console.log('course', course)
    return (
        <div className="course-preferences">
            {contextHolder}
            <div className="container">
                <div className="head">
                    <div className="title is-5">Course Preference</div>
                </div>

                <div className="body mt-5">
                    {course &&
                        <div className="form">

                            <Field
                                type='char'
                                name='course_name'
                                label='Title'
                                object={course}
                                tip="Make your Course stand out with a distinct and interesting title"
                                handleUpdate={handleInputChange}
                            />

                            <Field
                                type="textEditor"
                                name='description'
                                label='Description'
                                object={course}
                                tip="Tell more about your course."
                                handleUpdate={handleInputChange}
                            />

                            <Field
                                type='radio'
                                object={course}
                                handleUpdate={handleInputChange}
                                name='level'
                                label='Level'
                                tip='Select your course level.'
                                options={
                                    [
                                        { label: 'Beginner', value: 'Beginner' },
                                        { label: 'Intermediate', value: 'Intermediate' },
                                        { label: 'Advance', value: 'Advance' },
                                    ]
                                }
                            />

                            <div className="field">
                                <div className="display-header-group mb-4">

                                    <CourseImageField
                                        fileUrl={course.logo}
                                        name={"logo"}
                                        course={course}
                                    />

                                    <CourseImageField
                                        fileUrl={course.logo2}
                                        name={"logo2"}
                                        course={course}
                                        style={{ backgroundColor: '#1a1a1a' }}

                                    />

                                    <CourseImageField
                                        fileUrl={course.banner}
                                        name={"banner"}
                                        course={course}
                                    />
                                </div>

                                <Field
                                    name="display_header"
                                    label='Display Header'
                                    object={course}
                                    handleUpdate={handleInputChange}
                                    type='radio'
                                    options={[
                                        { label: 'Logo', value: 'Logo' },
                                        { label: 'Banner', value: 'Banner' },
                                    ]}
                                />
                            </div>

                            <div className="field">
                                <CourseImageField
                                    fileUrl={course.image}
                                    name={"image"}
                                    course={course} />
                                <div className="tip">Upload your course image, recomended size 400px * 240px.</div>
                            </div>

                            <ReviewOptionsField
                                course={course}
                                handleInputChange={handleInputChange}
                            />

                            <TagsField
                                course={course}
                                handleInputChange={handleInputChange}
                            />

                            <Field
                                type="number"
                                name='price'
                                object={course}
                                handleUpdate={handleInputChange}
                                label='Course Price ($USD)'
                                tip="Set the course price, reflecting its value and market standards. Adjustable anytime."
                            />

                            <Field
                                name='status'
                                label='Status'
                                object={course}
                                handleUpdate={handleInputChange}
                                type='radio'
                                options={[
                                    { label: 'Draft', value: 'Draft' },
                                    { label: 'Publish', value: 'Publish' },
                                    { label: 'Private', value: 'Private' },
                                ]}
                                tip={getStatusTip(course.status)}
                            />

                            <Field
                                type='checkbox'
                                name='archive'
                                object={course}
                                handleUpdate={handleInputChange}
                                label='Archive Course'
                                tip="Archived course only display on author profile"
                            />

                            <PublicShareField
                                course={course}
                                handleInputChange={handleInputChange}
                            />

                            <IntegrationField
                                course={course}
                                handleInputChange={handleInputChange}
                            />

                            <div className="flex-group mt-5 preference-buttons">
                                <Button type="primary" onClick={hanldeUpdateCourse}>Save</Button>
                                <Popconfirm
                                    placement="topRight"
                                    title={"Delete Course"}
                                    description={`Are you sure to delete ${course.course_name} ${Term.course} ?`}
                                    onConfirm={handleDeleteCourse}
                                    okText="Yes"
                                    cancelText="No"
                                    okButtonProps={{ loading: loading }}
                                >
                                    <Button className='button is-danger'>Delete</Button>
                                </Popconfirm>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}





export default WithAuth(CoursePreferences)


const getStatusTip = (status: string) => {
    switch (status) {
        case "Draft":
            return "Only author can see the course."
        case "Publish":
            return "Everyone see the course, it also allow for search. Plase make sure the course is ready before published!"
        case "Private":
            return "Only author and invited students can see the course."
        default:
            return ""
    }

}




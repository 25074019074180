import React, { useEffect, useState } from 'react'

import "../classroom.css"
import { Input, Spin, Tabs } from 'antd';
import { Term } from 'App';
import ProgramCourses from './ProgramCourses';
import ProgramMembers from './ProgramMembers';
import { API } from 'api';
import { Program as ProgramType } from 'types';


export default function Program(props: {
    programId: string
    setDisplay: (display: string) => void
}) {
    const { programId, setDisplay } = props;
    const [program, setProgram] = useState<ProgramType | null>(null)
    const [loading, setLoading] = useState(false)

    const getProgram = async () => {
        setLoading(true)
        const response = await API.Classroom.Program.GET(programId)
        console.log(response)
        if (response.status) {
            setProgram(response.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        getProgram()
    }, [programId])

    const programTabs = [

        {
            key: 'courses',
            label: `${Term.course}s`,
            icon: <i className="fas fa-box"></i>,
            children: <ProgramCourses program={program} />,
        },
        {
            key: 'members',
            label: `Members`,
            icon: <i className="fas fa-user-group"></i>,
            children: <ProgramMembers program={program} />,
        },
    ]

    return (
        <div className="program">
            <Spin spinning={loading} />
            {program &&
                <div className="section m-0 p-0">
                    <div className="header p-2">
                        <div className="back mr-2" onClick={() => setDisplay("table")}>
                            <i className="fa-solid fa-arrow-left"></i>
                        </div>
                        <span className='title is-6'>{Term.program} : {program.program_name}</span>
                    </div>

                    <div className="body">
                        <Tabs
                            tabPosition={"left"}
                            items={programTabs.map(item => {

                                return {
                                    label: (
                                        <span className='tab-item'>
                                            <div className="icon">
                                                {item.icon}

                                            </div>
                                            {item.label}
                                        </span>
                                    ),
                                    key: item.key,
                                    children: item.children,
                                };
                            })}
                        />
                        <ProgramSetting program={program} setProgram={setProgram} />
                    </div>


                </div>
            }
        </div>
    )
}

const ProgramSetting = (props: {
    program: ProgramType,
    setProgram: (program: ProgramType) => void

}) => {
    const { program, setProgram } = props;

    const handleUpdate = ({ name, value }: any) => {
        setProgram((prev: ProgramType) => ({
            ...prev, [name]: value
        }))
    }
    return (
        <div className="setting p-3">
            <div className="title is-6 mb-4">
                <strong >Setting</strong>
            </div>

            <div className="form">
                <div className="field">
                    <div className="control">
                        <div className="label">Title</div>
                        <Input name='program_name'
                            value={program.program_name}
                            onChange={e => handleUpdate({ name: e.target.name, value: e.target.value })} />
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <div className="label">Description</div>
                        <Input.TextArea name='description'
                            value={program.description}
                            onChange={e => handleUpdate({ name: e.target.name, value: e.target.value })} />
                    </div>
                </div>
            </div>
        </div>
    )
}

import { Button, Input, notification } from 'antd';
import { API } from 'api';
import { getKey, HTMLRenderer } from 'functions';
import React, { useEffect, useState } from 'react'
import { QuestionViewProps } from '../QuestionView';
import { Answer } from 'types';
import { COURSE } from 'consts';
import cookie from "react-cookies"


export default function TextQuestionView(props: QuestionViewProps & {
    type: string

}) {
    const { data, mode, session, type, setAllowNext } = props;
    const [answer, setAnswer] = useState<Answer>([])
    const [loading, setLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification()
    const publicUser = cookie.load("public_user")


    const updateAnswer = (answer: Answer) => {
        const value = answer.target.value
        setAnswer([value])
    }

    const handleConfirm = () => {
        if (mode === COURSE.ENROLL_MODE.ENROLL || mode === COURSE.ENROLL_MODE.PUBLIC) {
            handleSaveAnswer()
        } else {
            setAllowNext(true)
        }
    }

    const handleSaveAnswer = async () => {
        setLoading(true)
        const formData = new FormData()
        formData.append('question', JSON.stringify(data));
        formData.append('answer', answer);
        formData.append('session', session);
        formData.append('type', type)
        formData.append('mode', mode);
        if (mode === COURSE.ENROLL_MODE.PUBLIC) {
            formData.append("user", publicUser)
        }

        const response = await API.Course.UserAnswer.SAVE(formData, mode)

        if (response.status) {
            api.success({
                message: "Answer saved successfully.",
            })
            setAllowNext(true)
        }
        setLoading(false)

    }

    useEffect(() => {
        if (data.user_answer) {
            console.log(data.user_answer)
            setAllowNext(true)
            setAnswer([data.user_answer[0].user_answer_text])
        }
    }, [data])

    console.log(answer)
    return (
        <div className={data.response_type + "-view"}>
            {contextHolder}
            <div className="question">
                <div className="question-label">Question</div>
                <HTMLRenderer content={data.question} key={getKey()} />
            </div>

            <div className="tip mt-4 mb-3">Type your answer</div>

            <div className={"mb-4 answer-box " + data.response_type}>
                <Input.TextArea value={answer[0]} onChange={updateAnswer} />
            </div>

            <Button
                type='primary'
                loading={loading}
                disabled={!answer.length}
                onClick={handleConfirm}
            >Confirm
            </Button>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { Table, Tag } from 'antd';
import { API } from 'api';
import { Order } from 'types';
import dayjs from 'dayjs'
import { CURRENCY, FORMAT } from 'consts';

export default function BillingInfo() {

    const [orders, setOrders] = useState([])
    const [billing, setBilling] = useState<any | null>(null)

    const handleGetOrders = async () => {
        const response = await API.Payment.GetOrders()
        console.log('response', response)
        if (response.status) {
            setOrders(response.data.orders)
            setBilling(response.data.billing)
        }
    }

    useEffect(() => {
        handleGetOrders()
    }, []);

    return (
        <div className="billing-info">
            <div className="head mb-5">
                <h3 >Billing & Orders</h3>
            </div>
            <div className="billing mb-5">
                <div className="balance">
                    <h6>Balance</h6>
                    <p>$ {billing?.balance} {CURRENCY}</p>
                </div>

                <div className="income">
                    <h6>Income</h6>
                    <p>$ {billing?.income} {CURRENCY}</p>
                </div>

                <div className="spending">
                    <h6>Spending</h6>
                    <p>$ {billing?.spent} {CURRENCY}</p>
                </div>

            </div>
            <div className="order-list">
                <OrderTable orders={orders} />
            </div>
        </div>
    )
}

const OrderTable = (props: {
    orders: Order[]

}) => {
    const { orders } = props
    return (
        <Table
            columns={orderColumns}
            dataSource={orders}
            scroll={{ x: 'max-content' }}
        />
    )
}

const orderColumns = [
    {
        title: 'order number',
        dataIndex: 'order_number',
        key: 'order_number',
        render: (text: string) => <span>#{text}</span>
    },
    {
        title: 'Type',
        dataIndex: 'item_type',
        key: 'item_type',
    },
    {
        title: 'Title',
        dataIndex: 'item_name',
        key: 'item_name',
    },
    {
        title: 'price (USD)',
        dataIndex: 'item_price',
        key: 'item_price',
        render: (price: string) => {
            return `$ ${price}`
        }
    },
    {
        title: 'date order',
        dataIndex: 'date_order',
        key: 'date_order',
        render: (text: string) => {
            return dayjs(text).format(FORMAT.DATE_TIME)
        }
    },
    {
        title: 'status',
        dataIndex: 'status',
        key: 'status',
        render: (status: string) => {
            return <Tag className={status}>{status}</Tag>
        }
    },
];
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getProfile, getToken } from '../../functions';
import { useUserContext } from 'UserContext';
import './navbar.css';
import { pageWithNavbar } from 'consts';
import { isMobile } from 'react-device-detect';
import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';
import InstallPrompt from './InstallPrompt';

const token = getToken()

function Navbar(props: {
    keyword?: string;
}) {
    const { setUser, user, page } = useUserContext()
    const { keyword } = props;
    const [loading, setLoading] = useState(true)
    const [searchKeyword, setSearchKeyword] = useState(keyword)
    const navigate = useNavigate()

    async function getUserProfile() {
        setLoading(true)
        const user = await getProfile()
        if (user) {
            setUser(user);
        }
        setLoading(false)
    }

    useEffect(() => {
        if (user) return
        token ? getUserProfile() : setLoading(false)
    }, [user]);

    const setActiveMenu = (active: boolean) => {
        const target = document.querySelector('.nav-user .user') as HTMLElement;
        active ? target.classList.add('active') : target.classList.remove('active');
    };

    const handleSearch = () => {
        navigate("/search?q=" + (searchKeyword ? searchKeyword : ""))
    }

    return (
        <>
            <InstallPrompt />

            {pageWithNavbar.includes(page) && (!isMobile ?
                <NavbarDesktop
                    searchKeyword={searchKeyword}
                    setSearchKeyword={setSearchKeyword}
                    handleSearch={handleSearch}
                    loading={loading}
                    user={user}
                    setActiveMenu={setActiveMenu}
                />
                :
                <NavbarMobile
                    searchKeyword={searchKeyword}
                    setSearchKeyword={setSearchKeyword}
                    handleSearch={handleSearch}
                    loading={loading}
                    user={user}
                    setActiveMenu={setActiveMenu}
                />)
            }


        </>

    );
}

export default Navbar;

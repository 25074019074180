import React, { useState } from 'react'
import { CoursePreferencesFieldProps } from "./CoursePreferences";
import { Checkbox, Input } from 'antd';

export const ReviewOptionsField = (props: CoursePreferencesFieldProps) => {

    const { course, handleInputChange } = props;
    const [reviewOptions, setReviewOptions] = useState({
        enable_course_review: course.enable_course_review,
        review_by_score: course.review_by_score,
        review_by_mood: course.review_by_mood,
        review_by_text: course.review_by_text,
        enable_invite_reviewers: course.enable_invite_reviewers,
        reviewer_email: course.reviewer_email
    });

    const handleChange = (name: any, value: any) => {
        const data = { name, value };
        setReviewOptions({ ...reviewOptions, [name]: value });
        handleInputChange(data);
    };

    return (
        <div className="field">
            <div className="control">
                <div className="label">Review Options</div>
                <Checkbox
                    checked={reviewOptions.enable_course_review}
                    name="enable_course_review"
                    onChange={(e) => handleChange(e.target.name, e.target.checked)}
                >
                    Enable Course Review
                </Checkbox>
                <div className="tip">Allow to review Task.</div>
            </div>

            {reviewOptions.enable_course_review && (
                <>
                    <div className="control mt-5">
                        <div className="label">Review Method</div>
                        <Checkbox
                            checked={reviewOptions.review_by_score}
                            name="review_by_score"
                            onChange={(e) => handleChange(e.target.name, e.target.checked)}
                        >
                            by Score
                        </Checkbox>
                        <Checkbox
                            checked={reviewOptions.review_by_mood}
                            name="review_by_mood"
                            onChange={(e) => handleChange(e.target.name, e.target.checked)}
                        >
                            by Mood
                        </Checkbox>

                        <div className="tip">Select review method.</div>
                    </div>
                    <div className="control mt-5">
                        <Checkbox
                            checked={reviewOptions.enable_invite_reviewers}
                            name="enable_invite_reviewers"
                            onChange={(e) => handleChange(e.target.name, e.target.checked)}
                        >
                            Enable to invite reviewers
                        </Checkbox>
                        <div className="tip">Allows user to invite their own reviewers for this courses tasks</div>

                    </div>


                    <div className="control mt-5">
                        <div className="label">Default reviewer email</div>
                        <Input name='reviewer_email' value={reviewOptions.reviewer_email} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                        <div className="tip">Review invitation will send to this email by default. Leave this blank means no invitation will send by default to reviewer.</div>
                    </div>

                </>
            )}
        </div>
    );
};

import { sendRequest } from "api/Main"
import { HOST } from "consts"

export namespace NotificationApi {

    export const MarkAsRead = async (notificationId: string) => {
        const endpoint = HOST + "/api/Notification/MarkAsRead?pk=" + notificationId
        const method = "PUT"
        return await sendRequest(endpoint, method)
    }

}
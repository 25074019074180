import { HOST } from 'consts';
import React from 'react'

export default function EditorJSBlock({ block }: any) {


    if (block.type === "paragraph") {
        return (
            <p className="paragraph" style={{ textAlign: block.tunes ? block.tunes.tuness.alignment : "" }} dangerouslySetInnerHTML={{ __html: block.data.text }} />
        )
    }
    return null
}

//@ts-ignore
async function parse_new_dropbox_url(path: string) {
    const url = HOST + "curio/lesson/refresh_lesson_content_image_url/"
    const data = {
        "path": path
    }
    let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),

    }).then(r => r.json())
        .then(r => {
            console.log(r);
            return r
        })

    return response.link
}

import React, { useState } from "react";
import { HTMLRenderer } from "functions";
import { ReviewAnswer } from "./ReviewAnswer";
import ReviewScore from "./ReviewScore";
import { InputNumber } from "antd";
import { Answer, Question, Reviewer, Task } from "types";

export const ReviewTask = (props: {
    task: Task
    reviewer: Reviewer
    mode?: string
    reviewData: any
    handleUpdate: (data: any) => void
}) => {
    const { task, mode, handleUpdate, reviewer } = props;
    const [taskScore, setTaskScore] = useState(task.review.length > 0 ? task.review[0].score : 0)
    const questions = task.questions

    console.log(task.questions)

    const handleReviewChange = (data: any) => {
        // console.log(data);
        if (data.task_review?.score) {
            setTaskScore(data.task_review.score)
        }
        handleUpdate(data)

    };

    return (
        <div className={reviewer.has_review ? "review-tasks has-review" : "review-tasks"}>


            <div className="title is-6 review-task-title mb-2">
                {task.title}
                <div className="task-score-box">
                    <div className="label mr-3 mb-0">score : </div>
                    <InputNumber value={taskScore} />
                </div>
            </div>
            <div className="question-list">
                {questions.map((question: Question) => (
                    <div className="item" key={question.id}>
                        <div className="header">
                            <HTMLRenderer content={question.question_details.question} />

                        </div>

                        <div className="body">
                            <div className="answer-box p-3">
                                <p className='label'>{question.user} answer :</p>
                                {question.answers.map((item: Answer) => (
                                    <ReviewAnswer answer={item} key={item.id} />

                                ))}
                            </div>
                            <div className="review-response p-3">
                                {question.review.map((review: any) => (
                                    <ReviewScore
                                        key={review.id}
                                        mode={mode}
                                        review_type={review.review_type}
                                        review={review}
                                        handleReviewChange={handleReviewChange}
                                    />
                                ))}

                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    )
}
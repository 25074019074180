import React, { useEffect } from 'react';

export default function Model() {
    useEffect(() => {
        // Load the script
        const script = document.createElement('script');
        script.src = "https://meta-opt.s3.ap-southeast-1.amazonaws.com/files/main.meta-opt.js";
        script.async = true;
        document.body.appendChild(script);

        // Load the stylesheet
        const link = document.createElement('link');
        link.rel = "stylesheet";
        link.href = "https://meta-opt.s3.ap-southeast-1.amazonaws.com/files/main.meta-opt.css";
        document.head.appendChild(link);

        return () => {
            // Cleanup the script and link when the component unmounts
            document.body.removeChild(script);
            document.head.removeChild(link);
        };
    }, []);

    return (
        <>
            <div id="meta-root"></div>
            <div id="modal-root"></div>
        </>

    );
}

import { Button, notification } from 'antd'
import { API } from 'api';
import { Answer } from 'types';
import { HTMLRenderer, numberToUpperCaseLetter } from 'functions'
import React, { useEffect, useState } from 'react'
import cookie from "react-cookies"
import { QuestionViewProps } from '../QuestionView';
import { COURSE } from 'consts';


export default function OptionQuestionView(props: QuestionViewProps & {
    type: string

}) {
    const { data: question, mode, session, type, setAllowNext } = props;
    const [answer, setAnswer] = useState<Answer>([])
    const [confirm, setConfirm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification();

    const publicUser = cookie.load("public_user")

    console.log(question)
    const updateAnswer = (answerId: string) => {
        setConfirm(false)
        if (question.multiple_answer) {
            if (answer.includes(answerId)) {
                // remove answerId from the array
                setAnswer((prev: any) => prev.filter((id: string) => id !== answerId))
            } else {
                // add answerId to the array
                setAnswer((prev: any) => [...prev, answerId])
            }
            return
        }
        setAnswer([answerId])
    }


    const handleSaveAnswer = async () => {
        console.log("masuk sini---------->")
        setLoading(true)
        const formData = new FormData()
        formData.append('question', JSON.stringify(question));
        formData.append('answer', answer);
        formData.append('session', session);
        formData.append('type', type);
        formData.append('mode', mode);
        if (mode === COURSE.ENROLL_MODE.PUBLIC) {
            formData.append("user", publicUser)
        }

        const response = await API.Course.UserAnswer.SAVE(formData, mode)
        console.log(response)
        if (type === COURSE.CONTENT_TYPE.TASK_QUESTION || question.required_score) {
            api.success({
                message: "Answer saved successfully.",
            })
        }
        setAllowNext(true)
        setLoading(false)
    }

    const handleConfirm = () => {
        setConfirm(true)
        if (mode === COURSE.ENROLL_MODE.ENROLL || mode === COURSE.ENROLL_MODE.PUBLIC) {
            handleSaveAnswer()
        } else {
            setAllowNext(true)
        }

    }



    const getAnswerItemClassName = (item: any) => {
        let className = 'answer';
        if (answer.includes(item.id)) {
            className += ' selected';
        }
        if (confirm) {
            console.log(type)
            if (type !== "TaskQuestion" && !question.required_score) {
                if (item.correct && answer.includes(item.id)) {
                    className += ' is-true';
                } else if (!item.correct && answer.includes(item.id)) {
                    className += ' is-false';
                }
            }
        }
        return className
    }

    useEffect(() => {
        if (question.user_answer.length > 0) {
            setAllowNext(true)
            console.log(question.user_answer)
            question.user_answer.map((item: any) => {
                if (type === "TaskQuestion") {
                    setAnswer((prev: any) => [...prev, item.user_answer_option])
                } else {
                    setAnswer((prev: any) => [...prev, item.answer])
                }
            })
        }
    }, [question])


    console.log(question.user_answer)

    return (
        <div className={question.response_type + "-view"}>
            {contextHolder}
            <div className="question">
                <div className="question-label">Question</div>
                <HTMLRenderer content={question.question} key={"question" + question.id} />
            </div>

            <div className="tip mt-4 mb-3">Select your answer.</div>

            <div className={"mb-4 answer-box " + question.response_type}>
                {question.options.map((item: any, index: number) => {
                    if (item.answer === null) { return }

                    return (

                        <div className="option-item" key={item.id}>
                            <div id={"answer-option-" + item.id}
                                className={getAnswerItemClassName(item)}

                                onClick={() => updateAnswer(item.id)}
                            >
                                <div className="option-index">
                                    {numberToUpperCaseLetter(index)}
                                </div>
                                <div className="option-value">
                                    {item.answer}
                                </div>
                            </div>
                            {
                                type !== "TaskQuestion" && !question.required_score &&
                                    confirm && answer.includes(item.id) ?
                                    <div className="explanation-box mb-3">
                                        <HTMLRenderer content={item.explanation} />
                                    </div> : null
                            }
                        </div>
                    )
                })}
            </div>

            <Button
                type='primary'
                loading={loading}
                disabled={!answer.length}
                onClick={handleConfirm}
            >Confirm
            </Button>

        </div>
    )
}



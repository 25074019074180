import React, { useEffect, useState } from 'react'
import QuestionListItem from './QuestionListItem'
import { Input, Checkbox, InputNumber, Radio, Empty } from 'antd';
import { getKey, reorderData } from 'functions';
import EditTaskQuestion from './EditTaskQuestion';
import { createRoot } from 'react-dom/client';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { getDragContentStyle, getRandomColor } from '../../../functions';
import { Module, Question, Task } from 'types';
import { API } from 'api';

const { TextArea } = Input;

export default function EditTask(props: {
   module: Module,
   taskId: string,
   reload: () => void
}
) {

   const { module, taskId, reload } = props
   const [editTitle, setEditTitle] = useState(false)
   const [questionList, setQuestionList] = useState<Question[]>([])
   const [task, setTask] = useState<Task | null>(null)

   const context = task?.course ? "Course" : "Classroom"

   const handleUpdateTask = ({ name, value }: any) => {
      setTask((prev: any) => ({
         ...prev, [name]: value
      }));

   };


   useEffect(() => {
      getTask()
   }, [taskId])

   const handleEditTitle = (arg: boolean) => {
      setEditTitle(arg)
      if (!arg) { updateTask() }
   }

   const getTask = async () => {
      const data = await API.Course.Task.GET(taskId)
      setTask(data)
      setQuestionList(data.questions)
   }

   const updateTask = async () => {
      const response = await API.Course.Task.UPDATE(task, task.id)
      console.log(response);
      if (response.status) {
         reload()
      }

   }

   const handleDeleteTask = async () => {

   }

   const handleDragEnd = (result: any) => {
      if (!result.destination) return; // if dropped outside the list
      const itemsCopy = Array.from(questionList);
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
      itemsCopy.splice(result.destination.index, 0, reorderedItem);
      reorderData(itemsCopy, "task_question", task.id)
      setQuestionList(itemsCopy);
   };

   const addQuestion = () => {
      const modal = document.getElementById('app-modal');
      if (!modal) return
      const root = createRoot(modal);
      root.render(<EditTaskQuestion root={root} task={task} getTask={getTask} />)
   }

   const editQuestion = async (questionId: string) => {
      const modal = document.getElementById('app-modal');
      if (!modal) return
      const root = createRoot(modal);
      root.render(<EditTaskQuestion root={root} task={task} getTask={getTask} questionId={questionId} />)
   }

   const deleteQuestion = async (pk: string) => {

      const response = await API.Course.Question.DELETE({ context: context, type: "Task", questionId: pk })
      console.log('response', response)
      if (response.status) {
         setQuestionList(prev => {
            return prev.filter((question: any) => question.id !== pk);
         });
      }
   }

   const draggingColor = getRandomColor()
   const questionListItems = questionList && questionList.length ? questionList.map((item, index) => (
      <Draggable draggableId={"drag-task-question-" + index} index={index} key={item.id}>
         {(provided, snapshot) => (
            <div ref={provided.innerRef}
               {...provided.draggableProps}
               {...provided.dragHandleProps}
               style={getDragContentStyle(
                  draggingColor,
                  snapshot.isDragging,
                  provided.draggableProps.style
               )}
               className='question-list-item'>
               <QuestionListItem
                  key={item.id}
                  question={item}
                  deleteQuestion={deleteQuestion}
                  editQuestion={editQuestion} />
            </div>
         )}
      </Draggable>
   )) :
      <Empty description={"No question"} />


   return (
      <div className="edit-task">
         {task &&
            <>
               <div className={editTitle ? "header edit" : "header"}>
                  <div className="header-breadcrumb" onClick={() => handleEditTitle(true)}>
                     <div className="title">{module.title}</div>
                     <i className="las la-angle-right mx-2"></i>
                     <div className="title m-0">
                        {task.title}
                     </div>
                     <i className="fa-solid fa-pen ml-3"></i>
                  </div>
                  <div className="header-form" >
                     <TitleField task={task} handleUpdateTask={handleUpdateTask} handleEditTitle={handleEditTitle} />
                  </div>
               </div>

               <div className="form-box">
                  <div className="form-body">
                     <div className="question-panel">
                        <p className="title is-6">Questions</p>
                        <DragDropContext onDragEnd={handleDragEnd} key={getKey()}>
                           <Droppable
                              droppableId={"questions-" + task.id}
                              key={task.id} >
                              {(provided) => (
                                 <div className="question-list" ref={provided.innerRef}>
                                    {questionListItems}
                                    {provided.placeholder}
                                 </div>
                              )}
                           </Droppable>
                        </DragDropContext>
                        <div className="button is-outlined is-secondary" onClick={addQuestion}>+ Add Question</div>

                     </div>
                  </div>

                  <div className="setting-panel p-4" onBlur={updateTask}>
                     <div className="head">
                        <div className="title is-6 mb-3">Setting</div>
                     </div>
                     <form className="setting-form" id='id-setting-form' >

                        <DescriptionField task={task} handleUpdateTask={handleUpdateTask} />

                        <LimitQuestionField task={task} handleUpdateTask={handleUpdateTask} />

                        {task.limit_question && <MaxQuestionField task={task} handleUpdateTask={handleUpdateTask} />}

                        <LimitTimeField task={task} handleUpdateTask={handleUpdateTask} />

                        {task.limit_time && <MaxTimeField task={task} handleUpdateTask={handleUpdateTask} />}

                        <RandomQuestionField task={task} handleUpdateTask={handleUpdateTask} />

                        <EnableTaskReviewField task={task} handleUpdateTask={handleUpdateTask} />

                        <DisplayQuestionField task={task} handleUpdateTask={handleUpdateTask} />

                        <div className="button is-outlined is-danger my-5 delete-content" onClick={handleDeleteTask}>Delete</div>

                     </form>
                  </div>
               </div>
            </>
         }

      </div>
   )
}


interface FieldProps {
   task: Task,
   handleUpdateTask: any
}

const DescriptionField = (props: FieldProps) => {
   const { task, handleUpdateTask } = props
   const [description, setDescription] = useState(task.description)

   const handleChange = (e: any) => {
      const data = { name: "description", value: e.target.value }
      setDescription(e.target.value)
      handleUpdateTask(data)
   }

   return (
      <div className="field">
         <div className="control">
            <div className="label">Description</div>
            <TextArea showCount name='description' value={description} rows={3} maxLength={200} onChange={(e) => handleChange(e)} />
         </div>
      </div>)
}

const LimitQuestionField = (props: FieldProps) => {
   const { task, handleUpdateTask } = props
   const handleChange = (e: any) => {
      handleUpdateTask({ name: "limit_question", value: e.target.checked })
   }

   return (
      <div className="field">
         <div className="control">
            <Checkbox checked={task.limit_question} onChange={handleChange} id={"id-limit-question"}>
               <div className="label">Limit Question</div>
            </Checkbox>
            <p className="tip">Limit the number of questions display.</p>
         </div>
      </div>
   )
}

const EnableTaskReviewField = (props: FieldProps) => {
   const { task, handleUpdateTask } = props
   const handleChange = (e: any) => {
      handleUpdateTask({ name: "enable_task_review", value: e.target.checked })
   }

   return (
      <div className="field">
         <div className="control">
            <Checkbox checked={task.enable_task_review} onChange={handleChange} id={"id-enable-task-review"}>
               <div className="label">Enable Task Review</div>
            </Checkbox>
            <p className="tip">Author or invited reviewer can review this task.</p>
         </div>
      </div>
   )
}

const MaxQuestionField = (props: FieldProps) => {
   const { task, handleUpdateTask } = props
   const handleChange = (e: any) => {
      handleUpdateTask({ name: "max_question", value: e })
   }
   return (
      <div className="field">
         <div className="control">
            <div className="label">Number of Questions</div>
            <InputNumber min={1} max={100}
               value={task.max_question}
               step={1}
               onChange={handleChange}
               style={{ width: '100%' }} />
         </div>
      </div>
   )
}

const LimitTimeField = (props: FieldProps) => {
   const { task, handleUpdateTask } = props
   const handleChange = (e: any) => {
      handleUpdateTask({ name: "limit_time", value: e.target.checked })
   }
   return (
      <div className="field">
         <div className="control">
            <Checkbox checked={task.limit_time} onChange={handleChange} id={"id-limit-time"}>
               <div className="label">Limit Time</div>
            </Checkbox>
            <p className="tip">Limit user time to complete this task.</p>
         </div>
      </div>
   )
}

const MaxTimeField = (props: FieldProps) => {
   const { task, handleUpdateTask } = props
   const handleChange = (e: any) => {
      handleUpdateTask({ name: "max_time", value: e })
   }
   return (
      <div className="field">
         <div className="control">
            <div className="label">Max time (minutes)</div>
            <InputNumber min={10} max={100}
               value={task.max_time}
               step={10}
               onChange={handleChange}
               style={{ width: '100%' }} />
         </div>
      </div>
   )
}

const RandomQuestionField = (props: FieldProps) => {
   const { task, handleUpdateTask } = props
   const handleChange = (e: any) => {
      handleUpdateTask({ name: "random_question", value: e.target.checked })
   }
   return (
      <div className="field">
         <div className="control">
            <Checkbox checked={task.random_question} onChange={handleChange} id={"id-limit-question"}>
               <div className="label">Random Question Order</div>
            </Checkbox>
            <p className="tip">
               The order of the questions displayed to the user will be randomised.
            </p>
         </div>
      </div>
   )
}

const DisplayQuestionField = (props: FieldProps) => {
   const { task, handleUpdateTask } = props
   const handleChange = (e: any) => {
      handleUpdateTask({ name: "display_question", value: e.target.value })
   }
   return (
      <div className="field">
         <div className="control">
            <div className="label">Display Question</div>
            <Radio.Group value={task.display_question} onChange={handleChange} buttonStyle="solid" >
               <Radio.Button className="custom-radio" value="Default">Default</Radio.Button>
               <Radio.Button className="custom-radio" value="Form">Form</Radio.Button>
            </Radio.Group>
            <p className="tip mt-2">
               format form to display questions.
            </p>
         </div>
      </div>
   )
}

const TitleField = (props: FieldProps & {
   handleEditTitle: (arg: boolean) => void
}

) => {
   const { task, handleUpdateTask, handleEditTitle } = props
   const handleChange = (e: any) => {
      const data = { name: "title", value: e.target.value }
      handleUpdateTask(data)
   }
   const handleUpdate = () => {
      handleEditTitle(false)
   }
   return (
      <>
         <Input value={task.title} style={{ width: 500 }} onChange={handleChange} onPressEnter={handleUpdate} />
         <div className="button is-secondary is-outlined ml-3" onClick={handleUpdate}>
            <i className="fa-solid fa-check"></i>
         </div>
      </>
   )
}




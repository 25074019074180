import { HOST } from "consts"
import { sendRequest } from "./Main"

export namespace ClassroomAPI {

    // -------------------------------------- CLASSROOM TASK ---------------------------------------------
    export namespace Tasks {
        export const GET_ALL = async (classrommId: string) => {
            const endpoint = HOST + "/api/Classroom/Tasks?pk=" + classrommId
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

        export const DELETE = async (classrommId: string, taskId: string, type: string) => {
            const endpoint = `${HOST}/api/Classroom/Tasks?pk=${classrommId}&taskId=${taskId}&type=${type}`
            const method = "DELETE"
            return await sendRequest(endpoint, method)
        }

    }

    // ------------------------------------------ CLASSROOM -------------------------------------------------
    export namespace Classroom {

        export const GET_ALL = async () => {
            const endpoint = HOST + "/api/Classroom"
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

        export const GET = async (classrommId: string) => {
            const endpoint = HOST + "/api/Classroom?pk=" + classrommId
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

        export const CREATE = async (data: any) => {
            const endpoint = HOST + "/api/Classroom"
            const method = "POST"
            const body = JSON.stringify(data)
            return await sendRequest(endpoint, method, body)
        }

        export const UPDATE = async (data: any, classroomId: string) => {
            const endpoint = HOST + "/api/Classroom?pk=" + classroomId
            const method = "PUT"
            const body = JSON.stringify(data)
            return await sendRequest(endpoint, method, body)
        }

        export const DELETE = async (classroomId: string) => {
            const endpoint = HOST + "/api/Classroom?pk=" + classroomId
            const method = "DELETE"
            return await sendRequest(endpoint, method)
        }

    }

    // -------------------------------------- CLASSROOM PROGRAM ---------------------------------------------

    export namespace Program {

        export const GET_ALL = async (classrommId: string) => {
            const endpoint = `${HOST}/api/Classroom/Program/GetAll?classroomId=${classrommId}`
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

        export const GET = async (programId: string) => {
            const endpoint = `${HOST}/api/Classroom/Program/?pk=${programId}`
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

        export const DELETE = async (programId: string) => {
            const endpoint = `${HOST}/api/Classroom/Program/?pk=${programId}`
            const method = "DELETE"
            return await sendRequest(endpoint, method)
        }

        export const UPDATE = async (data: any, programId: string) => {
            const endpoint = `${HOST}/api/Classroom/Program/?pk=${programId}`
            const method = "PUT"
            const body = JSON.stringify(data)
            return await sendRequest(endpoint, method, body)
        }

        export const CREATE = async (data: any, classroomId: string) => {
            const endpoint = `${HOST}/api/Classroom/Program/?pk=${classroomId}`
            const method = "POST"
            const body = JSON.stringify(data)
            return await sendRequest(endpoint, method, body)
        }
    }

    // -------------------------------------- CLASSROOM PROGRAM COURSES ---------------------------------------------

    export namespace ProgramCourse {
        /**
         * Get all courses in a program
         */
        export const GET_COURSES = async (programId: string) => {
            const endpoint = `${HOST}/api/Classroom/Program/Courses?pk=${programId}`
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

        /**
         * Delete course in program
         */
        export const DELETE_COURSE = async (courseId: string) => {
            const endpoint = `${HOST}/api/Classroom/Program/Courses?pk=${courseId}`
            const method = "DELETE"
            return await sendRequest(endpoint, method)
        }

        /**
         * Get available courses that create by author with status "PUBLISHED" or "PRIVATE"
         */
        export const GET_AVAILABLE_COURSES = async (programId: string) => {
            const endpoint = `${HOST}/api/Classroom/Program/GetAvailableCourses?pk=${programId}`
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

        export const ADD_COURSES_TO_PROGRAM = async (programId: string, data: any) => {
            const endpoint = `${HOST}/api/Classroom/Program/Courses?pk=${programId}`
            const method = "POST"
            const body = JSON.stringify(data)
            return await sendRequest(endpoint, method, body)
        }
    }


    // -------------------------------------- CLASSROOM TASK LESSON ---------------------------------------------
    export namespace TaskLesson {
        export const GET_ALL = async () => {
            const endpoint = `${HOST}/api/Classroom/Task/Lesson`
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

        export const GET = async (lessonId: string) => {
            const endpoint = `${HOST}/api/Classroom/Task/Lesson?pk=${lessonId}`
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

        export const CREATE = async (classroomId: string) => {
            const endpoint = `${HOST}/api/Classroom/Task/Lesson?pk=${classroomId}`
            const method = "POST"
            return await sendRequest(endpoint, method)
        }

        export const UPDATE = async (data: any, lessonId: string) => {
            const endpoint = `${HOST}/api/Classroom/Task/Lesson?pk=${lessonId}`
            const method = "PUT"
            const body = JSON.stringify(data)
            return await sendRequest(endpoint, method, body)
        }

        export const DELETE = async (lessonId: string) => {
            const endpoint = `${HOST}/api/Classroom/Task/Lesson?pk=${lessonId}`
            const method = "DELETE"
            return await sendRequest(endpoint, method)
        }
    }


    // -------------------------------------- CLASSROOM TASK QUIZ ---------------------------------------------
    export namespace TaskQuiz {

        export const GET = async (quizId: string) => {
            const endpoint = `${HOST}/api/Classroom/Task/Quiz?pk=${quizId}`
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

        export const CREATE = async (classroomId: string) => {
            const endpoint = `${HOST}/api/Classroom/Task/Quiz?pk=${classroomId}`
            const method = "POST"
            return await sendRequest(endpoint, method)
        }

        export const UPDATE = async (data: any, quizId: string) => {
            const endpoint = `${HOST}/api/Classroom/Task/Quiz?pk=${quizId}`
            const method = "PUT"
            const body = JSON.stringify(data)
            return await sendRequest(endpoint, method, body)
        }

        export const DELETE = async (quizId: string) => {
            const endpoint = `${HOST}/api/Classroom/Task/Quiz?pk=${quizId}`
            const method = "DELETE"
            return await sendRequest(endpoint, method)
        }

        export const GET_ALL = async (classroomId: string) => {
            const endpoint = `${HOST}/api/Classroom/Task/Quiz?pk=${classroomId}`
            const method = "GET"
            return await sendRequest(endpoint, method)
        }
    }

    // -------------------------------------- CLASSROOM TASK QUESTION ---------------------------------------------

    export namespace TaskQuestion {
        export const GET_ALL = async (taskQuizId: string) => {
            const endpoint = `${HOST}/api/Classroom/Task/Question?pk=${taskQuizId}`
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

        export const GET = async (questionId: string) => {
            const endpoint = `${HOST}/api/Classroom/Task/Question?pk=${questionId}`
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

        export const CREATE = async (taskQuizId: string) => {
            const endpoint = `${HOST}/api/Classroom/Task/Question?pk=${taskQuizId}`
            const method = "POST"
            return await sendRequest(endpoint, method)
        }

        export const UPDATE = async (data: any, questionId: string) => {
            const endpoint = `${HOST}/api/Classroom/Task/Question?pk=${questionId}`
            const method = "PUT"
            const body = JSON.stringify(data)
            return await sendRequest(endpoint, method, body)
        }

        export const DELETE = async (questionId: string) => {
            const endpoint = `${HOST}/api/Classroom/Task/Question?pk=${questionId}`
            const method = "DELETE"
            return await sendRequest(endpoint, method)
        }

    }
}







import React from 'react'
function EmptyState() {
    return (
        <div className="empty-state">
            <img src={"https://curiobites-media.s3.ap-southeast-1.amazonaws.com/empty-state/edit-course.png"} alt="tip" className='tip' />
            <p className="title is-3 mt-4">Select any content to edit</p>
        </div>
    )
}

export default EmptyState
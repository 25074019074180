import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Input, Space } from 'antd';
import { API } from 'api';
import { Course } from 'types';
import { DownOutlined } from '@ant-design/icons';
import PersonalCoursesTable from './PersonalCoursesTable';


export default function PersonalCourses() {

    const [courses, setCourses] = useState<Course[]>([])
    const [originalData, setOriginalData] = useState<Course[]>([])
    const [loading, setLoading] = useState(true)
    const [selectedStatus, setSelectedStatus] = useState("All")



    const statusOptions = [
        {
            key: "All",
            label: "All",
        },
        {
            key: "Assigned",
            label: "Assigned",
        },
        {
            key: "Enrolled",
            label: "Enrolled",
        },
        {
            key: "Completed",
            label: "Completed",
        }
    ];

    const handleGetCourses = async (params = "") => {
        setLoading(true)
        const response = await API.Profile.GetPersonalCourses(params)
        console.log(response)
        if (response.status) {
            setCourses(response.data)
            setOriginalData(response.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        handleGetCourses()
    }, [])

    const handleSearch = (keyword: string) => {
        console.log(keyword)
        // Filter the originalData based on the title containing the keyword
        const filteredCourses = originalData.filter(course =>
            course.course_name.toLowerCase().includes(keyword.toLowerCase())
        );
        setCourses(filteredCourses);
    };


    useEffect(() => {
        const handleFilterStatus = async () => {
            if (selectedStatus === "All") {
                await handleGetCourses();
            } else {
                await handleGetCourses("?status=" + selectedStatus);
            }
        }

        handleFilterStatus()
    }, [selectedStatus]);

    return (
        <div className="personal-courses">
            <div className="header-box">
                <div className="title m-0">Personal Courses</div>
                <div className="query">
                    <Input
                        onChange={e => handleSearch(e.target.value)}
                        placeholder='Search'
                    />
                    <Dropdown
                        className='status'
                        menu={{
                            items: statusOptions,
                            onClick: (e: any) => setSelectedStatus(e.key)
                        }}
                    >
                        <Button >
                            <Space>
                                <span style={{ opacity: .7 }}>Status :</span>
                                {selectedStatus}
                                <DownOutlined style={{ fontSize: ".7rem", opacity: .6 }} />
                            </Space>
                        </Button>
                    </Dropdown>

                </div>
            </div>
            <div className="data-body">
                <div className="table-box">
                    <PersonalCoursesTable
                        courses={courses}
                        loading={loading}
                    />

                </div>

            </div>
        </div>
    )
}


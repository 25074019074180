import React, { useState } from 'react'
import { Alert, Button, Input, message } from 'antd'
import { getRole, validatePassword } from 'functions'
import moment from 'moment'
import { SetRoleAsAuthor, UpdatePassword } from 'api'
import { User } from 'types'
import { IMAGE } from 'consts'


const initialData = {
    old_password: "",
    new_password: "",
    password_confirm: ""
}

const role = getRole()

export default function EditAccount(props: { user: User }) {
    const { user } = props

    const [updatePassword, setUpdatePassword] = useState<boolean>(false)
    const [passwordUpdateDate, setPasswordUpdateDate] = useState<string>(user.password_update)
    const [passwordChange, setPasswordChange] = useState(initialData)

    const [errMessage, setErrMessage] = useState<string | null>(null)

    const handleSaveNewPassword = () => {
        const notvalid = validatePassword(passwordChange.new_password, passwordChange.password_confirm, user.username, user.email)
        setErrMessage(notvalid)
        if (!notvalid) {
            saveNewPassword()
        }
    }

    const handlePasswordChange = ({ name, value }: any) => {
        // console.log(name, value)
        setPasswordChange(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    console.log(errMessage)
    const saveNewPassword = async () => {

        const data = JSON.stringify(passwordChange)

        const response = await UpdatePassword(data)
        console.log('response', response)

        if (!response.status) {
            setErrMessage(response.message)
        } else {
            message.success(response.message)
            setUpdatePassword(false)
            const date = new Date()
            setPasswordUpdateDate(date.toString())
            setPasswordChange(initialData)
        }

    }


    return (
        <div className="edit-account ">
            <div className="head mb-5">
                <h3>Account</h3>
            </div>
            <div className="form">
                <div className="field">
                    <div className="label">Email Account</div>
                    <Input value={user.email} disabled />
                    <div className="tip">Your registered account can't be change</div>
                </div>
                <hr />

                {updatePassword &&
                    <div className="pasword-change">


                        <div className="field">
                            <div className="label">Old Password</div>
                            <Input.Password value={passwordChange.old_password} placeholder='Type your old password'
                                onChange={(e) => handlePasswordChange({ name: "old_password", value: e.target.value })} />
                        </div>


                        <div className="field">
                            <div className="label">New Password</div>
                            <Input.Password
                                value={passwordChange.new_password}
                                placeholder='Type your new password'
                                onChange={(e) => handlePasswordChange({ name: "new_password", value: e.target.value })} />
                        </div>


                        <div className="field">
                            <div className="label">New Password Confirm</div>
                            <Input.Password value={passwordChange.password_confirm}
                                placeholder='Type your new password again'
                                onChange={(e) => handlePasswordChange({ name: "password_confirm", value: e.target.value })} />
                        </div>

                        {errMessage &&
                            <div className="err-password my-3">
                                <Alert
                                    message={errMessage}
                                    type="warning"
                                    showIcon
                                />
                            </div>
                        }

                        <div className="button-group mt-5">
                            <Button onClick={() => setUpdatePassword(false)} >Cancel</Button>
                            <Button onClick={handleSaveNewPassword} type='primary' className='ml-2'>Update Password</Button>
                        </div>
                    </div>
                }

                {
                    !updatePassword &&
                    <div className="field">
                        <div className="label">Password</div>
                        {passwordUpdateDate ?
                            <p>{moment(passwordUpdateDate).fromNow()}</p>
                            :
                            <p className="tip">Never Change</p>
                        }

                        <Button onClick={() => setUpdatePassword(true)} className='mt-3'>Update Password</Button>
                    </div>
                }


            </div>
            {role !== "author" &&
                <RoleUpgradePanel />}
        </div>
    )
}


const RoleUpgradePanel = () => {

    const [loading, setLoading] = useState(false)
    const handleUpgradeRole = async () => {
        setLoading(true)
        const response = await SetRoleAsAuthor()
        console.log(response)
        if (response.status) {
            message.success("Great! Your role is upgrade to Author.")
        }

        setTimeout(() => {
            window.location.href = "/logout"
        }, 3000)

        setLoading(false)
    }

    return (
        <div className="role-upgrade-panel mt-5">
            <img src={IMAGE.TEACHER} alt="" />
            <img src={IMAGE.BECOME_AUTHOR} alt="" className='bg' />
            <Button
                loading={loading}
                type='primary'
                onClick={handleUpgradeRole}
            >Become an Author</Button>
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { IMAGE, KEY, PAGE, PATH } from "consts"
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import "./auth.css"

import cookie from 'react-cookies'
import { Button, Input, Spin } from 'antd'
import { API } from 'api';
import { useUserContext } from 'UserContext';

export default function Login() {

    const [searchParams] = useSearchParams()
    const next = searchParams.get("next")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [displayForgotPassword, setDisplayForgotPassword] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState(null)
    const expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
    const { setUser, setPage } = useUserContext()
    const navigate = useNavigate()

    useEffect(() => {
        setPage(PAGE.LOGIN)
        document.documentElement.classList.add("no-scroll")
        return () => {
            document.documentElement.classList.remove("no-scroll")
        }
    }, []);

    const userLogin = async () => {
        setLoading(true)
        let data = { username: username, password: password }

        const response = await API.Auth.Login(data, next)
        console.log(response)
        if (response.status) {
            cookie.save(KEY.TOKEN_ACCESS, response.token.access, { path: '/', expires: expires })
            cookie.save(KEY.TOKEN_REFRESH, response.token.refresh, { path: '/', expires: expires })
            cookie.save(KEY.ROLE, response.token.role, { path: '/', expires: expires })
            setUser(response.user)
            navigate(next ? next : PATH.PROFILE)
        } else {
            setMessage(response.message)
        }
        setLoading(false)

    }


    const handleForgotPassword = () => {
        setDisplayForgotPassword(true)
    }

    return (
        <div className="login">
            <div className="image-box">
                <img src={IMAGE.LOGIN} alt="" />
            </div>

            <div className="login-panel">
                <div className="logo-box">
                    <Link to={"/"}>
                        <img src={IMAGE.LOGO} alt="logo" className='logo' />
                    </Link>
                </div>

                {!displayForgotPassword &&
                    <form action="" className="login-form">
                        <h1 className='title is-5 text-center'>Login to your account</h1>
                        {message ? <p className="message">{message}</p> : ""}

                        <div className="field">
                            <label className="label">Username</label>
                            <div className="control has-icons-left ">
                                <Input
                                    placeholder="Username"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                    prefix={<UserOutlined />} />
                            </div>
                        </div>

                        <div className="field">
                            <div className="flex-group">
                                <label className="label">Password</label>
                                <div className="label forgot-password"
                                    onClick={handleForgotPassword}
                                >Forgot your password?</div>
                            </div>

                            <div className="control has-icons-left has-icons-right">
                                <Input.Password
                                    placeholder="Password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    prefix={<LockOutlined />}
                                />

                            </div>
                        </div>

                        <div className="field">
                            <div className="control" onClick={userLogin}>
                                <Button type='primary' loading={loading}>Login</Button>
                            </div>
                        </div>

                        <div className="field text-center">
                            <p className='m-5'>First time user ?</p>
                            <div className="control mt-4">
                                <Link to={next ? `/register?next=${next}` : `/register`}>
                                    <Button >Register</Button>
                                </Link>
                            </div>
                        </div>

                    </form>
                }
                {displayForgotPassword &&
                    <FormForgotPassword
                        setDisplayForgotPassword={setDisplayForgotPassword} />
                }
            </div>
        </div>
    )
}




const FormForgotPassword = (props: {
    setDisplayForgotPassword: (param: boolean) => void

}) => {
    const { setDisplayForgotPassword } = props;
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")

    const [displayValidation, setDisplayValidation] = useState(false)
    const handleBacktoLogin = () => {
        setDisplayForgotPassword(false)
    }

    const handleResetPassword = async () => {
        setLoading(true)
        const response = await API.Auth.Password.ResetPassword(email)
        console.log(response)
        if (response.status) {
            setDisplayValidation(true)
        }
        setLoading(false)
    }


    return (
        <>
            {!displayValidation &&
                <div className="forgot-password-form">
                    <div className="title is-5 mb-2">Forgot your password?</div>
                    <p>Don't worry, we’ll help you reset it and get back on track.</p>

                    <div className="field my-5">
                        <div className="label">Enter your registered email on Curiobites.</div>
                        <Input
                            value={email}
                            placeholder='Email'
                            prefix={<MailOutlined />}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                    <Button type='primary'
                        className='mb-3'
                        loading={loading}
                        onClick={handleResetPassword}>Reset Password</Button>
                    <Button onClick={handleBacktoLogin}>Back to Login</Button>
                </div>
            }

            {displayValidation &&
                <FormValidationResetPassword email={email} />
            }
        </>
    )
}


const FormValidationResetPassword = (props: {
    email: string
}) => {
    const navigate = useNavigate()
    const { email } = props
    const [validationNumber, setValidationNumber] = useState("")
    const [loading, setLoading] = useState(false)

    const handleValidationInput = (e: any) => {
        const value = e.target.value
        setValidationNumber(value)
    }

    const handleCheckValidationNumber = async () => {
        setLoading(true)
        const response = await API.Auth.Password.ResetPasswordValidation(email, validationNumber)
        console.log(response)
        if (response.status) {
            navigate(PATH.RESET_PASSWORD + "/" + response.url_hash)
        }
        setLoading(false)
    }
    useEffect(() => {
        if (validationNumber.length === 6) {
            handleCheckValidationNumber()
        }
    }, [validationNumber]);

    return (
        <div className="reset-password-validation">
            <div className="title is-5">Reset Password Validation</div>
            <p>To make sure you are the account holder, please validate this form by entering validate number that we email to you.</p>

            <div className="field">
                <div className="label">Enter 6 digit validation number</div>
                <Input
                    placeholder='e.g. 123456'
                    onChange={handleValidationInput}
                    disabled={loading}
                    suffix={loading && <Spin size='small' />}
                />
            </div>


        </div>
    )
}
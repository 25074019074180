import React, { useEffect, useState } from 'react'

import { getToken } from 'functions';

import "./notification.css"
import { Drawer, Empty, Tag } from 'antd';
import { CheckOutlined } from "@ant-design/icons"
import { WS_HOST } from 'consts';
import { API } from 'api';

export default function Notification() {
    const accessToken = getToken();
    const endpoint = WS_HOST + `/ws/get-notification/${accessToken}/`;
    const [notifications, setNotifications] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [socket, setSocket] = useState<WebSocket | null>(null);

    useEffect(() => {
        if (!socket) {
            const newSocket = new WebSocket(endpoint);
            setSocket(newSocket);

            newSocket.onopen = (event) => {
                console.log('WebSocket connection opened', event);

            };

            newSocket.onmessage = (event) => {
                console.log("WebSocket message received:", event);
                const data = JSON.parse(event.data);
                setNotifications(data);
            };

            newSocket.onclose = (event) => {
                console.log('WebSocket connection closed', event);
            };
        }

        return () => {
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.close();
            }
        };
    }, [endpoint, socket]);

    const handleDisplayNotification = () => {
        setShowModal(prev => !prev);
    }


    return (
        <>
            <NotificationModal
                items={notifications}
                open={showModal}
                close={handleDisplayNotification}
                socket={socket}
            />
            <div className="_notification notif" onClick={handleDisplayNotification}>
                <div className="notification-icon">
                    {notifications.length > 0 &&
                        <div className="notif-count">
                            {notifications.length}
                        </div>
                    }
                    <i className="fas fa-bell _darkgray"></i>
                </div>
            </div>
        </>
    )
}

const NotificationModal = (props: {
    items: any[];
    open: boolean;
    close: () => void;
    socket: WebSocket | null;

}) => {
    const { open, items, close } = props;

    return (
        <Drawer className='notification-modal' title="Notification" placement="right" onClose={close} open={open}>
            {items.length === 0 && <Empty />}

            {items.map(item => (
                <NotifItem key={item.id} item={item} {...props} />
            ))}
        </Drawer>
    )
}


const NotifItem = (props: {
    item: any;
    socket: WebSocket | null;
}) => {
    const { item } = props;

    const handleMarkAsRead = async () => {
        const response = await API.Notification.MarkAsRead(item.id)
        console.log(response)
    }
    return (
        <div className="notif-item"
        >
            <div className="group">
                <Tag className='mb-1'>{item.notif_subject}</Tag>
                <div className="is-link" onClick={() => handleMarkAsRead()}>
                    <CheckOutlined className='mr-2' />
                    Mark as read
                </div>
            </div>

            <p>{item.notif_text}</p>

        </div>
    )
}
import React, { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Spin } from 'antd'
import { PATH } from 'consts'
import { API } from 'api'

export default function Activation() {

    const { token } = useParams()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const next = searchParams.get("next")

    const handleActivation = async () => {
        if (!token) return
        const response = await API.Auth.Activation(token)
        console.log(response)
        if (response.status) {
            const nextUrl = next ? `${PATH.LOGIN}?next=${next}` : PATH.LOGIN
            navigate(nextUrl)
        }
    }

    useEffect(() => {
        handleActivation()
    }, [token]);

    return (
        <Spin />
    )
}

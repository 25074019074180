import { notification } from 'antd';
import React, { useEffect, useState } from 'react'
import OptionQuestionForm from './questionForm/OptionQuestionForm';
import YesNoQuestionForm from './questionForm/YesNoQuestionForm';
import { Answer, Question, Quiz } from 'types';
import { API } from 'api';

const responseTypeOption = [
    { label: "Option", value: "Option" },
    { label: "Yes / No", value: "Yes/No" }
]

const initialData = {
    question: "",
    options: null,
    multiple_answer: false,
    response_type: null
}
export default function EditQuestion(props: {
    quiz: Quiz,
    question?: Question,
    reload: () => void,
    close: () => void
}) {
    const { quiz, question: propsQuestion, reload, close } = props;
    const [ready, setReady] = useState(false)
    const [answer, setAnswer] = useState(null);
    const [question, setQuestion] = useState<Question>(propsQuestion ? propsQuestion : initialData)
    const [api, contextHolder] = notification.useNotification()

    console.log('question', propsQuestion)

    const context = "Course"
    // console.log(quiz)
    useEffect(() => {
        if (propsQuestion) {
            setQuestion(propsQuestion)
            setAnswer(propsQuestion.options)
        }
    }, [propsQuestion])

    const updateAnswer = (answer: Answer) => {
        console.log(answer);
        setAnswer(answer);
    };

    const updateQuestion = ({ name, value }: any) => {
        setQuestion((prev: any) => ({
            ...prev, [name]: value
        }));
    }

    const handleResponseTypeSelect = (value: string) => {
        updateQuestion({ name: "response_type", value: value })
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setReady(true);
        }, 100);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [ready]);

    const saveQuestion = async () => {
        const pk = question.id ? question.id : quiz.id
        const data = { question: question, answer: answer, quiz: quiz.id }

        console.log('question.id for save', question.id)
        const response = question.id ?
            await API.Course.Question.UPDATE({
                context: context, type: "Quiz", questionId: pk, data: data
            })
            : await API.Course.Question.CREATE({
                context: context, type: "Quiz", pk: pk, data: data
            })

        console.log(response)
        if (response.status) {
            reload()
            api.success({
                message: "Question Save!",
                duration: 2
            })
            close()
        } else {
            api.error({
                message: response.message,
                duration: 2
            })
        }
    }


    console.log('question.response_type', question.response_type)


    return (
        <>
            {contextHolder}
            <div className={ready ? "edit-question modal ready" : "edit-question modal"}>
                <div className="modal-box">
                    <div className="head">
                        <div className="delete" onClick={close}></div>
                    </div>

                    <div className="body">
                        {!question.response_type &&
                            <div className="response-type-option m-6">
                                <p className="title is-5 mb-6">Select Response Type</p>
                                <div className="option-group">
                                    {responseTypeOption.map(option => (
                                        <TypeOption
                                            key={option.value}
                                            label={option.label}
                                            value={option.value}
                                            onClick={handleResponseTypeSelect}
                                        />
                                    ))}
                                </div>
                            </div>
                        }

                        {question.response_type === "Option" ? (
                            <OptionQuestionForm
                                {...props}
                                updateAnswer={updateAnswer}
                                updateQuestion={updateQuestion}
                                question={question}
                                answer={answer}
                                type={"Quiz"}
                            />
                        ) : question.response_type === "Yes/No" ? (
                            <YesNoQuestionForm
                                {...props}
                                updateAnswer={updateAnswer}
                                updateQuestion={updateQuestion}
                                question={question}
                                answer={answer}
                                type={"Quiz"}
                            />
                        ) : null}

                    </div>

                    {question.response_type && <div className="button is-secondary mt-5" onClick={saveQuestion}>Save</div>}

                </div>

                <div className="overlay" onClick={close}></div>
            </div>
        </>
    )
}

const TypeOption = (props: {
    label: string,
    value: string,
    onClick: (value: string) => void
}) => {
    const { label, value, onClick } = props
    return (
        <div className="option" data-type={value} onClick={() => onClick(value)}>
            {value === "Option" ?
                <i className="las la-tasks response-type-icon"></i> : <i className="las la-check-square response-type-icon"></i>
            }
            <p className='mt-5'>{label}</p>

        </div>
    )
}


import { Input, Upload } from "antd";
import React, { useState } from "react";
import { PlusOutlined } from '@ant-design/icons';



export default function Test() {


  const uploadProps = {
    name: 'file',
    action: "/upload",
    listType: "picture-card",

  };
  return (
    <>
      testt
      <Input />
      <Upload {...uploadProps}>
        <PlusOutlined />
      </Upload>
    </>
  );
}


import React, { useEffect, useState } from 'react'
import 'react-dropdown/style.css';
import { Button, Drawer, Empty, Tag, Tooltip, message, Modal, Collapse, Spin } from 'antd';
import { ExportOutlined, SearchOutlined, ExclamationCircleFilled, MailOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import AssignCourseForm from './AssignCourseForm';
import ReviewCourse from '../../course/course-review/ReviewCourse';
import { API } from 'api';
import { Course, CourseAssigned, CourseInvitation } from 'types';
import { FORMAT } from 'consts';
import { isMobile } from 'react-device-detect';


export default function DetailAuthoredCourse(props: {
    courseId: string,
    open: boolean,
    close: () => void,
    reload: () => void
}) {
    const { courseId, open, close, reload } = props;
    const [showForm, setShowForm] = useState(false)
    const [invitations, setInvitations] = useState([])
    const [assignee, setAssignee] = useState([])
    const [courseAssignedId, setCourseAssignedId] = useState<string | undefined>(undefined)
    const [loading, setLoading] = useState(false)
    const [course, setCourse] = useState<Course | null>(null)

    // console.log(data)

    const handleInvite = () => {
        setShowForm(prev => !prev)
    }

    const handleGetAuthoredCourse = async () => {
        try {
            const response = await API.Profile.GetAuthoredCourses("pk=" + courseId)
            console.log(response)
            if (response.status) {
                setCourse(response.data)
            }

        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        handleGetAuthoredCourse()
        return () => {
            setCourse(null)
        }
    }, [courseId]);

    useEffect(() => {
        if (course) {
            setInvitations(course.invitations)
            setAssignee(course.assignee)
        }

        return () => {
            setInvitations([])
            setAssignee([])
        }

    }, [course]);

    const handleInvitationSuccess = (response: any) => {
        response.status && setInvitations(response.data)
    }

    const handleDeleteInvitation = async (invitationId: string) => {
        setLoading(true)
        const response = await API.Course.Invitation.DELETE(invitationId)
        console.log(response)
        if (response.status) {
            setInvitations(prev => prev.filter((item: CourseInvitation) => item.id !== invitationId));
            reload()
        }
        setLoading(false)

    }

    const handleDeleteAssignment = async (assignmentId: string) => {
        const response = await API.Course.Assignment.DELETE(course.id, assignmentId)
        console.log(response)
        if (response.status) {
            setAssignee((prev: any) => prev.filter((item: any) => item.id !== assignmentId))
            reload()
        }

    }

    const handleResendInvitation = async (invitationId: string) => {
        const response = await API.Course.Invitation.RESEND(invitationId)
        console.log(response)
        if (response.status) {
            message.success("Invitation has sent!")
        }
    }

    const showDeleteConfirm = (invitationId: string) => {
        console.log(invitationId)
        Modal.confirm({
            title: 'Delete Invitation',
            icon: <ExclamationCircleFilled />,
            content: 'Do you want to delete these invitation?',
            onOk() {
                handleDeleteInvitation(invitationId)
                console.log('OK');
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const showDeleteAssignmentConfirm = (assignmentId: string) => {
        console.log(assignmentId)
        Modal.confirm({
            title: 'Delete Assignment',
            icon: <ExclamationCircleFilled />,
            content: 'Do you want to delete these assignment?',
            onOk() {
                handleDeleteAssignment(assignmentId)
                console.log('OK');
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    // console.log("assignee", assignee)

    return (
        <div>
            {course &&
                <Drawer open={open} onClose={close}
                    width={800} title={course.course_name}
                    bodyStyle={{
                        paddingTop: 0,
                        // backgroundColor: "#fafafd"
                    }}
                    className='authored-course-detail'
                >
                    {showForm &&
                        <AssignCourseForm
                            courses={[course]}
                            close={handleInvite}
                            callback={handleInvitationSuccess}
                            reload={reload} />
                    }

                    <ReviewCourse
                        courseAssignedId={courseAssignedId}
                        close={() => setCourseAssignedId(undefined)}
                        isPopup={true}
                        mode="review"
                    />

                    <div className="header mt-2">
                        <div className="title is-6 m-0">
                            <SearchOutlined className='mr-2'
                                style={{ cursor: "pointer" }}
                            />
                            Assignee</div>
                        <Button type='primary' onClick={handleInvite} >Invite</Button>
                    </div>
                    {course.assignee?.length > 0 || invitations?.length > 0 ?
                        <div className="assignee-head">
                            <span>Name</span>
                            <span>Date Assigned</span>
                            <div className="extra">
                                <span>Status</span>
                                <span>Result</span>
                            </div>
                            <span> </span>
                        </div> :
                        <Empty description={"No data"} />
                    }

                    {assignee?.map((item: any) => (
                        <div key={item.id} className='assignee'>
                            <div className="name">
                                {item.user ? `${item.user_firstname} ${item.user_lastname}` : "Anonymous User"}
                            </div>
                            <div className="date">
                                {moment(item.date_assigned).format(FORMAT.DATE)}
                            </div>
                            <div className="extra">
                                <div className="status">
                                    <Tag className={item.status + " mr-1"}>
                                        {item.status}
                                    </Tag>
                                    {item.status === "Completed" &&
                                        <Button
                                            className='button-review'
                                            onClick={() => setCourseAssignedId(item.id)}
                                        >Review</Button>
                                    }
                                </div>
                                <div className="result">
                                    <Result data={item} />
                                </div>
                            </div>
                            <div className="action">
                                <Tooltip title={"Delete"}>
                                    <Button
                                        onClick={() => showDeleteAssignmentConfirm(item.id)}
                                        type='text'
                                        className='px-2'
                                    ><DeleteOutlined /></Button>
                                </Tooltip>
                            </div>
                        </div>
                    ))}

                    {/* invitations  */}
                    {invitations && invitations.map((item: CourseInvitation) => (
                        <div key={item.id} className='invited'>
                            <div className="name">
                                {item.email}
                            </div>
                            <div className="date">
                                {moment(item.date_assigned).format(FORMAT.DATE)}
                            </div>
                            <div className="extra">
                                <div className="status">
                                    <Tag className={item.status}>
                                        {item.status}
                                    </Tag>
                                </div>
                                <div className="result"></div>
                            </div>
                            <div className="action">
                                {!isMobile &&
                                    <Tooltip title={"Resend Invitation"}>
                                        <Button
                                            onClick={() => handleResendInvitation(item.id)}
                                            className='px-2'
                                            type='text'
                                            loading={loading}
                                        ><MailOutlined /></Button>
                                    </Tooltip>
                                }
                                <Tooltip title={"Delete"}>

                                    <Button
                                        onClick={() => showDeleteConfirm(item.id)}
                                        type='text'
                                        className='px-2'
                                    ><DeleteOutlined /></Button>
                                </Tooltip>
                            </div>
                        </div>
                    ))

                    }
                </Drawer>
            }
        </div>
    )
}

const Result = (props: {
    data: any
}) => {

    const { data } = props;
    const quizResults = data.quiz_results;
    const taskResults = data.task_results;
    const totalResults = quizResults.length + taskResults.length
    const [displayResult, setDisplayResult] = useState(false)
    const handleShowResult = () => {
        setDisplayResult(prev => !prev)
    }

    return (
        <>
            <DisplayAssineeResult
                assignee={data}
                open={displayResult}
                close={() => setDisplayResult(false)}
            />
            <p className='is-link' onClick={handleShowResult} >{totalResults} Result <ExportOutlined className='ml-1' /></p>

        </>
    )
}

const DisplayAssineeResult = (props: {
    assignee: any,
    open: boolean,
    close: () => void
}) => {
    const { assignee, open, close } = props
    // console.log(data)
    // console.log(open)
    const [courseAssigned, setCourseAssigned] = useState<CourseAssigned | null>(null)
    const [loading, setLoading] = useState(true)

    const handleGetResult = async () => {
        setLoading(true)
        const response = await API.Course.Assignment.GET(assignee.id)
        console.log(response)
        if (response.status) {
            setCourseAssigned(response.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        handleGetResult()
    }, [open]);

    const { Panel } = Collapse
    return (
        <Drawer title={`${assignee?.user_firstname} ${assignee?.user_lastname}`} placement="right" width={600} onClose={close} open={open}>
            {
                loading && <Spin />
            }

            {
                !loading &&
                <Collapse defaultActiveKey={[1, 2]}  >

                    {courseAssigned && courseAssigned.quiz_results.length > 0 &&
                        <Panel key={1} header={"Quiz Results"}>
                            {courseAssigned.quiz_results.map((item: any, index: number) => (
                                <div className="quiz-result-item" key={"result-item" + index}>
                                    <p> {item.result && <Tag className={item.result}>{item.result}</Tag>}
                                        {item.quiz}
                                    </p>
                                    <p className='score' >Score : {item.score.toFixed(1)}
                                    </p>
                                </div>
                            ))}
                        </Panel>
                    }

                    {courseAssigned && courseAssigned.task_results.length > 0 &&
                        <Panel key={2} header={"Task Results"}>
                            {courseAssigned.task_results.map((item: any, index: number) => (
                                <div className="quiz-result-item" key={"result-item" + index}
                                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                >
                                    <div className="reviewer">
                                        <p><strong>{item.task}</strong></p>
                                        <p><span style={{ color: "var(--text-secondary)" }} >Reveiwer : </span>  {item.reviewer_name.trim() ? item.reviewer_name : item.reviewer_email}</p>
                                    </div>

                                    <p className='score' >
                                        {!item.has_review ?
                                            <span style={{
                                                fontSize: "0.85rem",
                                                opacity: 0.8,
                                            }}> (No reviews yet)</span> :
                                            <span>
                                                Score : {item.total_score.toFixed(1)}
                                            </span>
                                        }
                                    </p>
                                </div>
                            ))}

                        </Panel>
                    }
                </Collapse>
            }
        </Drawer >
    )
}
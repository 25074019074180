import moment from 'moment'
import React from 'react'
import { GithubOutlined, TwitterOutlined, InstagramFilled, LinkedinFilled, FacebookFilled } from '@ant-design/icons';


import "./footer.css"

const curiobitesIcon =
    <svg version="1.1" id="logo-icon" x="0px" y="0px"
        viewBox="0 0 283.7 293"  >
        <path d="M138.7,14.7c1.1,0,2.2,0,3.3,0s2.2,0,3.3,0c29.3,0,55.1,9.9,77.8,30c25.8,22.9,38.6,51.6,38.6,85.9
		c0,31.9-11.5,59.3-34.1,81.7c-11.2,11-23.6,19.2-37,24.9c-5.7,3.3-11.9-2-11-8.6c15.6-40.3,39.4-48,39.4-90.3
		c0-8.2-1.3-15.9-3.7-23.1c-1.5-6.8-4.8-13.2-9-18.7c-13.7-20-36.4-33-62.3-33c-0.4,0-1.1,0-1.8,0s-1.3,0-2,0
		c-25.8,0-48.7,13-62.1,33c-4.4,5.5-7.5,11.9-9.3,18.7c-2.2,7.3-3.5,15-3.5,23.1c0,42.3,23.8,50,39.4,90.3
		c0.9,6.6-5.3,11.9-11.2,8.6c-13.4-5.7-25.8-13.9-36.8-24.9c-22.9-22.5-34.4-49.8-34.4-81.7c0-34.4,13-63,38.8-85.9
		C83.4,24.7,109.4,14.7,138.7,14.7z M138.9,259.7h4c5.3,0,9.5,4.2,9.5,9.3l0,0c0,5.1-4.2,9.3-9.5,9.3h-4c-5.1,0-9.3-4.2-9.3-9.3l0,0
		C129.6,263.9,133.8,259.7,138.9,259.7z M126.1,229.3h29.7c5.9,0,11,5.1,11,11.2l0,0c0,6.2-5.1,11.2-11,11.2h-29.7
		c-6.2,0-11.2-5.1-11.2-11.2l0,0C114.9,234.4,119.9,229.3,126.1,229.3z"/>
        <circle cx="196.8" cy="84.8" r="31.3" />
    </svg>

export default function Footer() {
    const date = new Date()

    return (
        <div className="footer-box">

            <div className="container">
                <div className='start'>Curiobites © {moment(date).format("YYYY")}. All Rights Reserved</div>

                <div className="logo-box">
                    {curiobitesIcon}
                </div>
                <div className="contacts end">
                    <LinkedinFilled />
                    <TwitterOutlined />
                    <GithubOutlined />
                    <InstagramFilled />
                    <FacebookFilled />
                </div>
            </div>

        </div>

    )
}


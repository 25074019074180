import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Spin } from 'antd'
import { API } from 'api'

export default function CourseInvitationHandler() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const hash = searchParams.get("hash")
    const [loading, setLoading] = useState(true)

    const handleAcceptCourseInvitation = async () => {
        if (!hash) return
        setLoading(true)
        const response = await API.Course.Invitation.ACCEPT(hash)
        console.log(response)
        if (response.redirect) {
            navigate(response.redirect)
        }
        setLoading(false)
    }

    useEffect(() => {
        handleAcceptCourseInvitation()
    }, []);

    return (
        <div>
            <Spin spinning={loading} />
        </div>
    )
}

import { notification } from 'antd';
import React, { useEffect, useState } from 'react'
import AttachmentQuestionForm from './questionForm/AttachmentQuestionForm';
import DateQuestionForm from './questionForm/DateQuestionForm';
import MoodQuestionForm from './questionForm/MoodQuestionForm';
import OptionQuestionForm from './questionForm/OptionQuestionForm';
import TextQuestionForm from './questionForm/TextQuestionForm';
import YesNoQuestionForm from './questionForm/YesNoQuestionForm';
import { Spin } from 'antd'
import { API } from 'api';
import { Answer } from 'types';

const responseTypeOption = [
    { label: "Option", value: "Option" },
    { label: "Yes / No", value: "YesNo" },
    { label: "Text", value: "Text" },
    { label: "Attachment", value: "Attachment" },
    { label: "Date", value: "Date" },
    { label: "Mood", value: "Mood" },
]

const defaultQuestion = {
    question: "",
    multiple_answer: false,
    response_type: null,
    limit_length: false,
    max_length: 200,
    question_order: null,
    attachment_type: "Document",
}

export default function EditTaskQuestion(props: {
    root: any
    task: any
    getTask: () => void
    questionId?: string
}) {
    const { root, task, getTask, questionId } = props;
    const [question, setQuestion] = useState(defaultQuestion);
    const [answer, setAnswer] = useState(null);
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification()

    const context = task.course ? "Course" : "Classroom"

    const getQuestion = async () => {
        if (!questionId) return
        setLoading(true)
        const response = await API.Course.Question.GET({ context: context, type: "Task", questionId: questionId })
        console.log('response ', response)
        if (response.status) {
            setQuestion(response.data)
            setAnswer(response.data.options)
        }

        setLoading(false)
    };


    const updateAnswer = (answer: Answer) => {
        // console.log(answer);
        setAnswer(answer);
    };

    const updateQuestion = ({ name, value }: any) => {
        console.log(name, value);
        setQuestion(prev => ({
            ...prev, [name]: value
        }));
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setReady(true);
        }, 100);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [ready]);


    useEffect(() => {
        if (questionId) {
            getQuestion()
        } else {
            setQuestion(prev => ({
                ...prev,
                ['task']: task.id
            }))
        }
    }, [])

    const saveQuestion = async () => {

        const data = { question: question, answer: answer, }
        const response = questionId ?
            await API.Course.Question.UPDATE({
                context: context, type: "Task", questionId: questionId, data: data
            }) :
            await API.Course.Question.CREATE({
                context: context, type: "Task", pk: task.id, data: data
            })

        if (response.status) {
            console.log(response)
            getTask()
            api.success({
                message: "Question Save!",
                duration: 2
            })
            root.unmount()
        }
    }

    return (
        <>
            {contextHolder}
            <div className={ready ? "edit-question modal ready" : "edit-question modal"}>
                <div className="modal-box">
                    <div className="head">
                        <div className="delete" onClick={() => root.unmount()}></div>
                    </div>

                    <div className="body">
                        <Spin spinning={loading} size="large" />
                        {!question.response_type && (
                            <div className="response-type-option m-3">
                                <p className="title is-5 mb-6">Select Response Type</p>
                                <div className="option-group">
                                    {responseTypeOption.map(option => (
                                        <TypeOption
                                            key={"response-" + option.value}
                                            label={option.label}
                                            value={option.value}
                                            updateQuestion={updateQuestion}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}

                        {question.response_type === "Option" ? (
                            <OptionQuestionForm
                                updateAnswer={updateAnswer}
                                updateQuestion={updateQuestion}
                                question={question}
                                answer={answer}
                                type="task"
                            />
                        ) : question.response_type === "YesNo" || question.response_type === "Yes/No" ? (
                            <YesNoQuestionForm
                                updateAnswer={updateAnswer}
                                updateQuestion={updateQuestion}
                                question={question}
                                answer={answer}
                                type="task" />
                        ) : question.response_type === "Text" ? (
                            <TextQuestionForm
                                updateQuestion={updateQuestion}
                                question={question}
                            />
                        ) : question.response_type === "Attachment" ? (
                            <AttachmentQuestionForm
                                updateQuestion={updateQuestion}
                                question={question}
                            />
                        ) : question.response_type === "Date" ? (
                            <DateQuestionForm
                                updateQuestion={updateQuestion}
                                question={question}
                            />
                        ) : question.response_type === "Mood" ? (
                            <MoodQuestionForm
                                updateAnswer={updateAnswer}
                                updateQuestion={updateQuestion}
                                question={question}
                                answer={answer} />
                        ) : null}

                    </div>

                    {question.response_type && <div className="button is-secondary mt-5" onClick={saveQuestion}>Save</div>}

                </div>

                <div className="overlay" onClick={() => root.unmount()}></div>
            </div>
        </>
    )
}


const TypeOption = (props: {
    label: string,
    value: string,
    updateQuestion: (data: { name: string, value: any }) => void
}) => {
    const { label, value, updateQuestion } = props

    const handleSelect = (e: any) => {
        updateQuestion({ name: "response_type", value: e.currentTarget.dataset.type })
    }
    return (
        <div className="option" data-type={value} onClick={handleSelect}>
            {getIcon(value)}
            <p className='mt-5'>{label}</p>

        </div>
    )
}

const getIcon = (value: string) => {
    switch (value) {
        case "Option":
            return <i className="las la-list response-type-icon"></i>;
        case "YesNo":
            return <i className="las la-check-circle response-type-icon"></i>;
        case "Text":
            return <i className="las la-align-center response-type-icon"></i>;
        case "Attachment":
            return <i className="las la-paperclip response-type-icon"></i>;
        case "Date":
            return <i className="las la-calendar response-type-icon"></i>;
        default:
            return <i className="las la-grin response-type-icon"></i>;
    }
};

import React, { useEffect, useState } from 'react'
import AttachmentQuestionView from './questionView/AttachmentQuestionView'
import DateQuestionView from './questionView/DateQuestionView'
import MoodQuestionView from './questionView/MoodQuestionView'
import OptionQuestionView from './questionView/OptionQuestionView'
import TextQuestionView from './questionView/TextQuestionView'
import YesNoQuestionView from './questionView/YesNoQuestionView'
import { API } from 'api'
import { Question } from 'types'
import { QuestionViewProps } from './QuestionView'

export default function QuestionViewForm(props: QuestionViewProps & {
    obj_type: string
    setPage: (arg: any) => void

}) {
    const { data, obj_type, setPage } = props
    // const mode = window.location.pathname.split('/')[2];
    const [questions, setQuestions] = useState<Question | null>(null)

    const handleGetTask = async (taskId: string) => {
        const response = await API.Course.Task.GET(taskId)
        setQuestions(response.questions)
        console.log(response.questions.length)
        setPage((prev: number) => prev + response.questions.length - 1)
        // setPage(prev => prev + response.questions.length - 1)
    }

    console.log(data)
    useEffect(() => {
        obj_type === "TaskQuestion" && handleGetTask(data.task)
    }, [data])

    console.log(questions)

    return (

        <div className="question-group my-5">

            {questions && questions.map((item: Question, index: number) => (
                <div className="question-view">
                    {item.response_type === "Option" &&
                        <OptionQuestionView key={index} type={item.obj_type}  {...props} />
                    }

                    {item.response_type === "Yes/No" || item.response_type === "YesNo" &&
                        <YesNoQuestionView key={index} type={item.obj_type}  {...props} />
                    }

                    {item.response_type === "Mood" &&
                        <MoodQuestionView key={index} type={item.obj_type}  {...props} />
                    }

                    {item.response_type === "Attachment" &&
                        <AttachmentQuestionView key={index} type={item.obj_type}  {...props} />
                    }

                    {item.response_type === "Date" &&
                        <DateQuestionView key={index} type={item.obj_type}  {...props} />
                    }

                    {item.response_type === "Text" &&
                        <TextQuestionView key={index} type={item.obj_type}  {...props} />
                    }


                </div>
            ))}
        </div>


    )
}


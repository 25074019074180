import { Input, Popconfirm, notification } from 'antd';
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import EmptyState from './EmptyState';
import { GetModule, UpdateModule } from 'api';
import { TERM } from 'consts';
import { Module } from 'types';
import MyEditor from 'components/ui/FroalaEditor';

export default function EditModule(props: {
    moduleId?: string,
    setContentDisplay: React.Dispatch<React.SetStateAction<JSX.Element>>,
    reload: () => void
}) {

    const { moduleId, setContentDisplay, reload } = props;
    const [searchParams] = useSearchParams();
    const [api, contextHolder] = notification.useNotification()
    const courseId = searchParams.get("c")

    const initialData = {
        course: courseId,
        title: "New " + TERM.MODULE,
        description: ""
    }

    const [module, setModule] = useState<Module>(initialData)

    const close = () => {
        setContentDisplay(<EmptyState />)
    }

    const hanldeGetModule = async () => {
        if (!moduleId) return
        const { status, data } = await GetModule(moduleId)
        console.log(data);
        status && setModule(data)
    }

    const handleUpdateModule = async (method: string) => {
        const pk = moduleId ? module.id : courseId
        if (moduleId) {
            if (method !== "DELETE") {
                method = "PUT"
            }
        }

        const response = await UpdateModule(module, pk, method)

        console.log(response)
        if (response.status) {
            if (method === "PUT") {
                api.success({
                    message: `${TERM.MODULE} Save!`,
                    duration: 2
                })
            }
            reload()
            close()
        }
    }

    useEffect(() => {
        if (moduleId) {
            hanldeGetModule()
        }
    }, [moduleId])



    const handleUpdate = ({ name, value }: any) => {
        setModule((prev: any) => ({
            ...prev,
            [name]: value
        }));
    }

    const onModelChange = (value: any) => {
        handleUpdate({ name: "description", value: value })
    }

    return (
        <div className="edit-module">
            {contextHolder}
            <div className="header">
                <div className="title is-5 m-0">{module.title}</div>
            </div>
            <div className="form-box">
                <div className="form">
                    <div className="field">
                        <div className="control">
                            <div className="label mb-1">Name</div>
                            <Input value={module.title}
                                onChange={e => handleUpdate({ name: "title", value: e.target.value })} />
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <div className="label mb-1">Description</div>
                            <MyEditor
                                content={module.description}
                                onContentChange={onModelChange}
                            />
                            {/* <Input.TextArea value={module.description} showCount
                                onChange={e => handleUpdate({ name: "description", value: e.target.value })} /> */}
                        </div>
                    </div>

                    <div className="field flex-group mt-6">
                        <div className="control">
                            <div className="button is-secondary mr-2" onClick={() => handleUpdateModule("POST")}>Save</div>
                            <div className="button" onClick={close}>Cancel</div>
                        </div>
                        {
                            module && module.id &&
                            <Popconfirm
                                placement="topRight"
                                title={"Delete " + TERM.MODULE}
                                description={"Are you sure to delete this " + TERM.MODULE + " ?"}
                                onConfirm={() => handleUpdateModule("DELETE")}
                                okText="Delete"
                                cancelText="No"
                            >
                                <div className="button is-danger">Delete</div>
                            </Popconfirm>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}





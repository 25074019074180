import React, { useEffect, useState, } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import { message, Modal, Upload } from 'antd';
import { getToken } from 'functions';

const getBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const UploadFile = React.memo((props: {
    endpoint: string,
    fileList: any,
    handleChange: (fileList: any) => void,
    handleRemove: (file: any) => void,
    accept: string,
    className?: string
    style?: React.CSSProperties
}) => {

    const { endpoint, fileList: propFileList, handleRemove, accept, className, style } = props;

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState(propFileList ? propFileList : []);


    useEffect(() => {
        setFileList(propFileList)
    }, [propFileList])

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.fileName || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = (info: any) => {
        // setFileList(info.fileList)
        // console.log(info);
        props.handleChange(info.fileList)

        if (info.file.status === "uploading") {
            console.log("aku loading");
            // message.loading("loading...", 0)
        }
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.destroy()
            message.success(`${info.file.name} file uploaded successfully`);
            // props.onChange(info.file)

        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }



    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <>
            <Upload
                action={endpoint}
                headers={{
                    Authorization: `Bearer ${getToken()}`,
                }}
                listType="picture-card"
                //@ts-ignore
                limit={1}
                fileList={fileList}
                accept={accept}
                className={className}
                shouldUpdate={false}
                onChange={handleChange}
                onPreview={handlePreview}
                onRemove={handleRemove}
                style={style}
            >
                {fileList.length < 1 && uploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    );
})


export default UploadFile
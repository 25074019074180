import React from 'react'
import { Task } from 'types'

export default function TaskView(props: {
    data: Task
}) {
    const { data } = props
    return (

        <div className="task-view">
            {
                data &&
                <div className="content-body">
                    <div className="title task-title">{data.title}</div>
                    <p className="description">{data.description}</p>
                </div>
            }
        </div>
    )
}


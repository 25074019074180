import { HOST } from 'consts'
import { sendRequest } from './Main'


export const SetRoleAsAuthor = async () => {
    const endpoint = HOST + "/api/SetRoleAsAuthor/"
    const method = "PUT"
    return await sendRequest(endpoint, method)
}


export const UpdatePassword = async (data: any) => {
    const endpoint = HOST + "/api/Auth/UpdatePassword/"
    const method = "POST"
    const body = JSON.stringify(data)

    return await sendRequest(endpoint, method, body)

}

export const UpdateProfile = async (data: any) => {
    const endpoint = HOST + "/api/UserProfile/"
    const method = "PUT"
    const body = JSON.stringify(data)

    return await sendRequest(endpoint, method, body)

}


import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Table, Menu, Popconfirm, Spin } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { getRandomInt } from 'functions';
import moment from 'moment';
import TaskLesson from './classroom-task/TaskLesson';
import TaskQuiz from './classroom-task/TaskQuiz';
import { Classroom, Task } from 'types';
import { TERM } from 'consts';
import { API } from 'api';




export default function ClassroomTask(props: {
    classroom: Classroom;
}) {
    const { classroom } = props;



    const [loading, setLoading] = useState(false)
    const [tab, setTab] = useState("table")
    const [taskId, setTaskId] = useState<string | undefined>(undefined)
    const [tasks, setTasks] = useState<Task>([])

    const handleDisplay = (tab: string, taskId?: string) => {
        setTab(tab)
        taskId && setTaskId(taskId)
    }

    const getTasks = async () => {
        setLoading(true)
        const response = await API.Classroom.Tasks.GET_ALL(classroom.id)
        console.log(response)
        if (response.status) {
            const tasks = response.data.map((item: any) => ({
                id: item.id,
                name: item.title,
                date_modified: item.date_modified,
                type: item.type,
                image: `https://source.unsplash.com/random/sig=${getRandomInt(1, 100)}?abstract`
            }));
            setTasks(tasks)
        }
        setLoading(false)
    }

    useEffect(() => {
        getTasks()
    }, [])


    return (
        <div className="classroom-tasks">
            <Spin spinning={loading} size="large" />

            {tasks && tab === "table" &&
                <TaskTable
                    data={tasks}
                    handleDisplay={handleDisplay}
                    setTasks={setTasks}
                    classroom={classroom}
                />
            }

            {tab === "lesson" &&
                <TaskLesson classroom={classroom}
                    handleDisplay={handleDisplay}
                    taskLessonId={taskId}
                    getTasks={getTasks}
                />
            }

            {tab === "quiz" &&
                <TaskQuiz classroom={classroom}
                    handleDisplay={handleDisplay}
                    taskQuizId={taskId}
                    getTasks={getTasks}
                />
            }
        </div>
    )

}

const TaskTable = (props: {
    data: Task,
    handleDisplay: (tab: string, taskId?: string) => void,
    setTasks: (tasks: Task) => void,
    classroom: Classroom
}) => {
    const { data, handleDisplay, setTasks, classroom } = props;
    const [loading, setLoading] = useState(false)


    const handleRowClick = (record: any) => {
        console.log('record', record)
    }

    const handleEdit = (record: Task) => {
        handleDisplay(record.type.toLowerCase(), record.id)
    }

    const deleteTask = async (record: Task) => {
        setLoading(true)
        const response = await API.Classroom.Tasks.DELETE(classroom.id, record.id, record.type)

        console.log(response)
        if (response.status) {
            setTasks((prev: any) => (
                prev.filter((item: any) => item.id !== record.id)
            ));
        }
        setLoading(false)
    }

    const menu = (
        <Menu>
            <Menu.Item key="add-quiz" onClick={() => handleDisplay("quiz")}>
                + Add Quiz
            </Menu.Item>
            <Menu.Item key="add-lesson" onClick={() => handleDisplay("lesson")}>
                + Add Lesson
            </Menu.Item>
        </Menu>
    );



    const ButtonAddTask = () => {
        return (
            <Dropdown overlay={menu} >
                <Button type='primary'>+ Add</Button>
            </Dropdown>
        );
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: any, record: any) => {
                const imageUrl = record.image;
                console.log(name, record)

                return (
                    <>
                        <img src={imageUrl} className={'member-avatar'} alt="course" style={{ width: 30, height: 30, marginRight: 10 }} />
                        <span onClick={() => handleEdit(record)}
                            style={{ cursor: "pointer" }}
                        >{name}</span>

                    </>
                );
            },
            sorter: (a: any, b: any) => a.name.localeCompare(b.name),
            className: "has-avatar"

        },
        {
            title: "Type",
            dataIndex: 'type',
            key: 'type',
            sorter: (a: any, b: any) => a.type.localeCompare(b.type),

        },
        {
            title: 'Date Modified',
            dataIndex: 'date_modified',
            key: 'date_modified',
            sorter: (a: any, b: any) => a.date_modified - b.date_modified,
            render: (date_modified: string) => (
                <span>{moment(date_modified).fromNow()}</span>
            )

        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            className: "action",
            width: 100,
            render: (record: any) => (
                <>
                    <Button icon={<EditOutlined />} className='mr-1'
                        onClick={() => handleEdit(record)}></Button>
                    <Popconfirm
                        placement="bottomRight"
                        title={"Delete " + TERM.TASK}
                        description={`Are you sure to delete this ${TERM.TASK}?`}
                        onConfirm={() => deleteTask(record)}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ loading: loading }}
                    >
                        <Button icon={<DeleteOutlined />}></Button>
                    </Popconfirm>
                </>
            ),
        },
    ];


    return (
        <div className="p-5">
            <div className="flex-group">
                <div className="title is-5 mb-3">{TERM.TASK}s</div>
                <ButtonAddTask />
            </div>
            <Table
                key={"classroom-member-table"}
                className={"is-table"}
                columns={columns}
                dataSource={data}
                onRow={(record: any) => ({
                    onClick: () => handleRowClick(record),
                })} />
        </div>

    )
}

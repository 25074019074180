import { useUserContext } from 'UserContext';
import { Button, Collapse, Empty, Input, Rate, Tabs, Skeleton } from 'antd';
import EditorJSBlock from 'components/ui/EditorJSBlock';
import { HTMLRenderer, getAvatarUrlByName } from 'functions';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Course, Review } from 'types'

export function LeftPanel(props: {
    course: Course,
}) {

    const { course } = props

    const [description, setDescription] = useState<any>("")
    const [reviewLenght, setReviewLength] = useState<number>(0)

    useEffect(() => {
        if (course?.description) {
            let blocks = null
            try {
                blocks = JSON.parse(course?.description)
                typeof (blocks) === 'object' && setDescription(blocks.blocks.map((block: any, index: number) => <EditorJSBlock key={index} block={block} />))
            } catch (error) {
                const string = <HTMLRenderer content={course?.description} />
                setDescription(string)
            }

        }

        course && setReviewLength(course.reviews.items.length)
    }, [course]);


    const tabItems = [
        {
            key: 'discussion',
            label: `Discussion`,
            children: <Discussion />,
        },
        {
            key: 'review',
            label: reviewLenght > 0 ? `Reviews (${reviewLenght})` : `Reviews`,
            children: <ReviewTab course={course} />,
        },
    ];

    const onChangeTab = (key: any) => {
        console.log(key)
    }

    return (
        <div className="left-side">
            {course ?
                <>
                    <div className="description editorjs-block">
                        {description ? description : "This course has no description."}
                    </div>
                    <div className="course-contents">
                        <p className="title is-6 mt-5">Course Contents</p>

                        <div className="module-list">
                            {course?.modules?.length > 0 ?
                                <ModuleItems data={course.modules} />
                                :
                                <Empty description={"No content"} />
                            }
                        </div>
                    </div>

                    <Tabs
                        className='mt-5'
                        defaultActiveKey="1"
                        items={tabItems}
                        onChange={onChangeTab}
                    />
                </>
                :
                <Skeleton active />
            }

        </div>
    )
}


const ModuleItems = (props: {
    data: any
}) => {

    const { data } = props
    const onChange = (key: any) => {
        console.log(key);
    };

    return (
        <Collapse onChange={onChange}>
            {data.map((item: any) => (
                <Collapse.Panel header={item.title} key={item.id} >
                    <ul>
                        {item.contents.map((item: any) => (
                            <li key={item.id} className='content-item'>
                                {icons[item.obj_type]}
                                {item.title}
                            </li>
                        ))}
                    </ul>

                </Collapse.Panel>
            ))}
        </Collapse>
    )
}



const Discussion = () => {
    const { user } = useUserContext()
    const [comment, setComment] = useState("")

    return (
        <div className="discussion">

            <div className="comment-panel">
                {user ?
                    <div className="avatar">
                        <img src={user.avatar ? user.avatar : getAvatarUrlByName(user.firstname + " " + user.lastname)} alt="" />
                    </div> : ""
                }
                <div>
                    <Input.TextArea value={comment} onChange={(e) => setComment(e.target.value)} />
                    <Button type='primary' className=' mt-3 button is-secondary'>Send</Button>
                </div>
            </div>
        </div>
    )
}

const ReviewTab = (props: {
    course: Course

}) => {
    const { course } = props
    console.log(course)
    return (
        <div className="review">
            {course?.reviews.items.length > 0 && course?.reviews.items.map((item: Review, index: number) => (
                <div className="review-item mb-4" key={"review-" + index}>
                    <div className="user">
                        <img src={item.avatar} alt="" />
                        <div className="group ml-3">
                            <p>{item.name}</p>
                            <p className='tip'>{moment(item.date_review).fromNow()}</p>
                        </div>
                    </div>
                    <div className="review-detail">
                        <Rate style={{ scale: .8 }} disabled defaultValue={item.review} />
                        <div className="comment">{item.comment}</div>
                    </div>
                </div>
            ))}

            {course?.reviews.items.length === 0 &&
                <div className="no-review mb-6">No review yet</div>
            }

        </div>
    )
}


const icons: any = {
    Module: <i className="fa-solid fa-folder"></i>,
    Lesson: <i className='fas fa-graduation-cap'></i>,
    Quiz: <i className='fas fa-question-circle'></i>,
    Quizes: <i className='fas fa-question-circle'></i>,
    Task: <i className="fa-solid fa-clipboard-list"></i>,
    Question: null,
    TaskQuestion: null,
};

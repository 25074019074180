import { getToken } from "functions";
import { METHOD } from "types";


export const sendPublicRequest = async (endpoint: string, method: METHOD | string, body?: any) => {

    const headers = {
        "Content-Type": "application/json",
    }
    const response = await fetch(endpoint, { method, headers, body });
    if (!response.ok) {
        throw new Error("Something went wrong!");
    }
    const result = await response.json();
    return result;
}

export const sendRequest = async (endpoint: string, method: METHOD | string, body?: any) => {
    const headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${getToken()}`
    }
    const response = await fetch(endpoint, { method, headers, body });
    if (!response.ok) {
        throw new Error("Something went wrong!");
    }
    const result = await response.json();
    return result;
}

export const sendCustomHeadersRequest = async (endpoint: string, method: METHOD | string, headers: any, body?: any) => {
    try {
        const response = await fetch(endpoint, { method, headers, body });
        if (!response.ok) {
            throw new Error("Something went wrong!");
        }
        const result = await response.json();
        return result;

    } catch (error) {
        return error
    }
}



import React from 'react'
import "./navbar.css"
import { Link, useNavigate } from 'react-router-dom'
import { IMAGE } from 'consts'
import { Course } from 'types'


export default function NavBuilder(props: {
    course: Course,
    setTab: any
}) {
    const { course, setTab } = props
    const navigate = useNavigate()
    const handleTab = (e: any) => {
        let tabs = e.currentTarget.querySelectorAll(".tab")
        tabs.forEach((el: HTMLElement) => {
            el.classList.remove("active")
        });

        e.target.classList.add("active")
        setTab(e.target.dataset.view)
    }

    const handlePreviewCourse = () => {
        navigate("/course/preview/?c=" + course.id)
    }

    return (
        <>
            <div id="app-modal"></div>
            <div className="navbar nav-builder">
                <div className="nav-start ml-4">
                    <img src={IMAGE.CURIOBITES_ICON} alt="" />
                    <div className="title is-5 ml-4">{course.course_name}</div>
                </div>
                <div className="nav-center">
                    <div className="switch" onClick={e => handleTab(e)}>
                        <div className="tab is-left active" data-view="Content">Content</div>
                        <div className="tab is-right" data-view="Preferences">Preferences</div>
                    </div>
                </div>
                <div className="nav-end">
                    <div className="preview" onClick={handlePreviewCourse}>Preview</div>

                    <Link to={"/profile?tab=authored-courses"} className="close"><i className="fa-solid fa-xmark"></i></Link>

                </div>
            </div>
        </>
    )
}


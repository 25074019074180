import React, { useEffect } from 'react'
import { useState } from 'react'
import ContentItem from './ContentItem'
import { COURSE } from 'consts'

export default function ContentGroup(props: {
    data: any,
    active: string | null,
    contents: any,
    handleDirect: (data: any, type?: string) => void,
    handleActive: (arg: string) => void,
    mode: string

}) {

    const { data, active, contents, handleDirect, mode } = props
    const [expand, setExpand] = useState(false)
    const className = active === data.id ? "content-header active" : "content-header"
    const [complete, setComplete] = useState(false)
    console.log(data)
    const handleClick = (e: any) => {
        e.stopPropagation()
        handleDirect(data, "Module")

    }

    const handleExpand = (e: any) => {
        e.stopPropagation()
        setExpand(!expand)
    }

    useEffect(() => {
        if (mode === COURSE.ENROLL_MODE.ENROLL || mode === COURSE.ENROLL_MODE.PUBLIC) {
            const id = data.id;
            // console.log(contents, obj_id)
            const target = contents.filter((item: any) => item.obj_id === id);
            try {
                setComplete(target[0].is_complete)
            } catch (error) {
                console.log(error)
            }
        }
    }, [contents]);

    return (
        <div className={'content'} >
            <div className={complete ? `complete ${className}` : className} data-type={data.obj_type} data-pk={data.id} onClick={handleClick} >
                <span>{data.title}</span>
                <div className="expand" onClick={handleExpand}>
                    {expand ?
                        <i className="las la-angle-down"></i>
                        : <i className="las la-angle-up"></i>
                    }
                </div>
            </div>
            {!expand &&
                <div className="content-item-group">
                    {data.contents.map((item: any) => {
                        // if (mode === COURSE.ENROLL_MODE.PUBLIC && item.obj_type === COURSE.CONTENT_TYPE.TASK) {
                        //     return null;  // Skip rendering for this item
                        // }
                        return (
                            <ContentItem
                                {...props}
                                key={item.id}
                                data={item}
                            />)
                    })}
                </div>
            }
        </div>
    )
}


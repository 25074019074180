import React from 'react'
import { HTMLRenderer } from 'functions'
import { Module } from 'types'

export default function ModuleView(props: {
    data: Module
}) {
    const { data } = props
    console.log(data)
    return (

        <div className="module-view">
            {
                data &&
                <div className="content-body mt-4">
                    <div className="title module-title">{data.title}</div>
                    <div className="description">
                        <HTMLRenderer content={data.description} />
                    </div>
                </div>
            }
        </div>
    )
}

import { HOST } from "consts"
import { sendRequest } from "./Main"

export namespace PaymentAPI {
    export const CreateCheckoutSession = async (data: any) => {
        const endpoint = HOST + '/api/Payment/CheckOut/'
        const method = 'POST'
        const body = JSON.stringify(data)
        return await sendRequest(endpoint, method, body)
    }

    export const GetOrders = async () => {
        const endpoint = HOST + "/api/Payment/Orders/"
        const method = "GET"
        return await sendRequest(endpoint, method)
    }

    export const PaymentSuccessConfirmation = async (token: string) => {
        const endpoint = HOST + "/api/Payment/PaymentConfirmation/?payment_token=" + token
        const method = "GET"
        return await sendRequest(endpoint, method)
    }
};


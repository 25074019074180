import React, { useState } from 'react'
import 'react-dropdown/style.css';
import { Alert, Button, Input, message } from 'antd';
import FormModal from 'components/ui/FormModal';
import { Course } from 'types';
import { API } from 'api';

export default function AssignCourseForm(props: {
    courses: Course[],
    close: () => void,
    callback?: (data: any) => void,
    reload?: () => void
}) {
    const { courses, close, callback, reload } = props;
    console.log(courses)
    const initInvitation = { courses: courses.map(item => item.id), email: "" }

    const [loading, setLoading] = useState(false)
    const [invitation, setInvitation] = useState(initInvitation)

    const handleUpdate = ({ name, value }: any) => {
        setInvitation(prev => ({
            ...prev, [name]: value
        }))
    }
    const handleSendInvitation = async () => {
        setLoading(true)
        const data = JSON.stringify(invitation)
        const response = await API.Course.Invitation.INVITE_BY_EMAIL(data)
        console.log(response)
        if (response.status) {
            setInvitation(initInvitation)
            reload && reload()
            callback && callback(response)
            message.success("Invitation Send!")
            close()
        }
        setLoading(false)

    }



    const hasDraft = courses.some(item => item.status === "Draft")


    return (
        <FormModal close={close} width='500px' className='assign-course-form' >
            <FormModal.Header>
                <div className="title is-6 m-0">Invitation</div>
                <div className="delete" onClick={close}></div>
            </FormModal.Header>
            <FormModal.Body>
                <div className="field">
                    <div className="label">Course</div>
                    <ul >
                        {courses.map((item, index) => {
                            return <CourseItem key={index} course={item} />
                        })}
                    </ul>

                </div>
                <div className="field">
                    <div className="label">Email</div>
                    <Input
                        placeholder='email'
                        onChange={e => handleUpdate({ name: "email", value: e.target.value })}
                    />
                    <p className="tip">
                        Enter the email address of the person you wish to invite.
                    </p>
                </div>

                {hasDraft &&
                    <Alert
                        message="You have selected draft course. The invitation will not be sent to the draft course."
                        type="warning"
                        showIcon
                    />
                }

                <div className="group mt-4">
                    <Button type='primary'
                        loading={loading}
                        className='mr-2'
                        onClick={handleSendInvitation}
                        disabled={hasDraft || !invitation.email || loading}
                    >Send</Button>
                    <Button
                        onClick={close}
                    >Cancel</Button>
                </div>

            </FormModal.Body>

        </FormModal>
    )
}



const CourseItem = (props: {
    course: Course
}) => {
    const { course } = props;
    return (
        <li className='mb-1'>
            {course.status !== "Draft" &&
                <Alert message={`${course.course_name}`}
                    type="success"
                    showIcon
                />
            }

            {course.status === "Draft" &&
                <Alert
                    message={`${course.course_name}`}
                    type="error"
                    showIcon
                />
            }

        </li>
    )
}
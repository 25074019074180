import React from 'react'
import dayjs from 'dayjs'
import { COURSE, FORMAT } from 'consts'
import { DownloadOutlined } from '@ant-design/icons';
import { GetFileNameFromAWSUrl, getYouTubeVideoId } from 'functions'
import YouTube from 'react-youtube';
import { Answer } from 'types';

export const ReviewAnswer = (props: {
    answer: Answer
}) => {
    const { answer } = props
    console.log('answer', answer)
    return (
        <div className="answer" >
            {(answer.response_type === COURSE.QUESTION.RESPONSE_TYPE.YES_NO ||
                answer.response_type === COURSE.QUESTION.RESPONSE_TYPE.YESNO ||
                answer.response_type === COURSE.QUESTION.RESPONSE_TYPE.MOOD ||
                answer.response_type === COURSE.QUESTION.RESPONSE_TYPE.OPTION) &&
                answer.answer_text
            }

            {answer.response_type === COURSE.QUESTION.RESPONSE_TYPE.TEXT &&
                answer.user_answer_text}

            {answer.response_type === "Date" &&
                dayjs(answer.user_answer_date).format(FORMAT.DATE)}

            {answer.response_type === "Attachment" && answer.attachment_type === "Audio" &&
                <audio className='attachment-audio' src={answer.user_answer_attachment_url} controls />
            }

            {answer.response_type === "Attachment" && answer.attachment_type === "Image" &&
                <img src={answer.user_answer_attachment_url} alt="" />
            }

            {answer.response_type === "Attachment" && answer.attachment_type === "Document" &&
                <a href={answer.user_answer_attachment_url} target='_blank' className='is-file-link'>
                    <DownloadOutlined className='mr-1' />
                    {GetFileNameFromAWSUrl(answer.user_answer_attachment_url)}
                </a>
            }

            {answer.response_type === "Attachment" && answer.attachment_type === "Video" &&
                <YouTube
                    videoId={getYouTubeVideoId(answer.user_answer_attachment_url) || ""}
                />
            }

        </div>
    )
}

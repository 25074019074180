import React, { useEffect, useState } from 'react'
import { getRandomImage } from 'functions'
import { Button, Drawer, Skeleton } from 'antd'
import { API } from 'api'
import { Course } from 'types'
import { LeftPanel } from './LeftPanel'
import { RightPanel } from './RightPanel'
import "./course-detail-modal.css"
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { AuthorSection } from './AuthorSection'
import { CheckOutModal } from 'components/payment/CheckOutModal'
import { useUserContext } from 'UserContext'


export default function CourseDetailModal(props: {
    courseId?: string,
    assignmentId?: string,
    close: () => void,
    open: boolean
    img?: string

}) {

    // console.log('akus sudah dipanggil')
    const { courseId, close, open, img = getRandomImage() } = props;
    const [purchaseCourseId, setPurchaseCourseId] = useState<string | undefined>(undefined)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { user } = useUserContext()

    useEffect(() => {
        if (open) (
            document.documentElement.classList.add("no-scroll")
        )
        return () => {
            document.documentElement.classList.remove("no-scroll")
        }
    }, [open])

    const startCourse = () => {
        console.log('start course ---> course.id', course.id)
        // console.log('navigate', navigate)
        const path = "/course/enroll/?c=" + course.id
        console.log('path', path)
        navigate(path)
    }

    const [course, setCourse] = useState<Course | null>(null)


    const getCourseDetail = async () => {
        if (!courseId) return
        const response = await API.Course.Course.GET_PUBLIC_DETAIL(courseId)

        console.log(response)
        if (response.status) {
            setCourse(response.data)

        }
    }


    useEffect(() => {
        if (courseId && open) {
            getCourseDetail()
        }

        return () => {
            setCourse(null)
        }
    }, [courseId, open])


    const handleClose = () => {
        close()
    }

    const handlePurchase = () => {
        setLoading(true)
        console.log('course.id', course.id)
        if (course.price === 0) {
            startCourse()
            setLoading(false)
            return
        }
        setPurchaseCourseId(course.id)
        setLoading(false)
    }

    return (
        <Drawer className="course-detail-modal"
            width={isMobile ? "100vw" : 1100}
            open={open}
            onClose={handleClose}
        >
            <div className="modal-box">
                <div className="head">
                    <div className="img-overlay"></div>
                    <img src={getRandomImage()} alt="" />
                    <p className='title'>
                        {course ?
                            course.course_name :
                            <Skeleton.Input active style={{
                                width: "300px",
                                backgroundColor: "rgba(255,255,255,0.4)",
                                height: "20px"
                            }} />
                        }
                    </p>

                    <div className="delete" onClick={handleClose}></div>
                </div>

                <div className="body">
                    <LeftPanel course={course} />
                    {!isMobile && course &&
                        <RightPanel
                            loading={loading}
                            course={course}
                            img={img || course.image || ""}
                            handlePurchase={handlePurchase}
                            startCourse={startCourse}
                        />

                    }

                    {isMobile && course &&
                        <AuthorSection author={course.author} />
                    }
                </div>

                {isMobile && course &&
                    <div className="foot">

                        <Button
                            type="primary"
                            loading={loading}
                            style={{ width: "100%" }}
                            onClick={() => {
                                if (user) {
                                    course.is_assigned ? startCourse() : handlePurchase();
                                } else {
                                    navigate("/login?next=/course/enroll/?c=" + course.id);
                                }
                            }}
                        >
                            {getCourseAction(course)}
                        </Button>
                    </div>
                }
            </div>

            <CheckOutModal
                type="course"
                id={purchaseCourseId}
                close={() => setPurchaseCourseId(undefined)}
            />
        </Drawer >
    )

}





export function getCourseAction(course: Course) {
    if (course.price > 0) {
        return course.is_assigned ? "Start Course" : "Purchase";
    }
    return "Start Course";
}
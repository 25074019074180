import React, { useState } from 'react'
import { HTMLRenderer } from 'functions'
import JSZip from 'jszip';
import { Button } from 'antd';
import { DownloadOutlined, PaperClipOutlined } from "@ant-design/icons"
import { Lesson } from 'types';

export default function LessonView(props: {
    data: Lesson
}) {

    const { data } = props;
    console.log(data)
    return (
        <div className="lesson-view">
            {
                data &&
                <>
                    <div className="content-head">
                        {data.title}
                    </div>
                    <div className="content-body mt-5 fr-element fr-view">
                        <HTMLRenderer content={data.content} />
                    </div>
                </>
            }
            {
                data && data.attachment.length > 0 &&
                <LessonAttachment
                    key={"attachment-" + data.id}
                    data={data} />
            }
        </div>
    )
}

const LessonAttachment = (props: {
    data: Lesson
}) => {
    const { data } = props
    const attachments = data.attachment
    const [loading, setLoading] = useState(false)

    const getFileName = (url: string) => {
        const pathname = new URL(url).pathname;
        const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
        return filename
    }

    const downloadAllAttachments = async () => {
        setLoading(true)
        const zip = new JSZip();
        const fetchPromises: Array<any> = [];

        // console.log(data)

        // Add each attachment to the zip
        attachments.forEach((item: any) => {
            const fileName = getFileName(item.url);
            const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
            const filePromise = fetch(item.url)
                .then(response => response.blob())
                .then(blob => zip.file(`${fileName}.${fileExtension}`, blob));

            fetchPromises.push(filePromise);
        });

        // Wait for all file fetches to complete
        await Promise.all(fetchPromises)
            .then(() => {
                // Generate the zip file
                zip.generateAsync({ type: 'blob' })
                    .then(blob => {
                        // Create a download link for the zip file
                        const url = URL.createObjectURL(blob);

                        // Create a temporary link element
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `${data.title}_attachments.zip`;

                        // Trigger the download
                        link.click();
                    });
            })
            .catch(error => {
                console.error('Error fetching files:', error);
                // Handle the error if needed
            });

        setLoading(false)
    };

    return (
        <div className="lesson-attachment">
            <h3>Attachment</h3>
            {attachments.map((item: any, index: number) => (
                <div className="lesson-attachment-item py-1" key={"att-" + index}>
                    <a href={item.url} target="_blank" >
                        <p>
                            <PaperClipOutlined className='mr-2' />
                            {getFileName(item.url)}
                        </p>
                    </a>
                </div>
            ))}

            <Button
                className='mt-4'
                onClick={downloadAllAttachments}
                icon={<DownloadOutlined />}
                loading={loading}
            >
                Download All
            </Button>
        </div>
    )
}

import React from 'react';
import { HOST } from 'consts';
import Editor from 'react-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import { cls } from 'functions';

export default function FroalaEditor(props: {
    content: string,
    onContentChange: (model: string) => void,
    className?: string,
    placeholder?: string
    config?: any
    theme?: string

}) {

    const { content, onContentChange, className, placeholder } = props;
    const handleContentChange = (model: string) => {
        // console.log(model);
        onContentChange(model);
    };

    const key = process.env.REACT_APP_FROALA_KEY
    const config = {
        className: className,
        quickInsertEnabled: false,
        imageUpload: true,
        imageDefaultWidth: 200,
        imageMaxSize: 1024 * 1024 * 2.5,

        imageEditButtons: [
            "imageReplace",
            "imageAlign",
            "imageRemove",
            "imageSize",
            "-",
            "imageLink",
            "linkOpen",
            "linkEdit",
            "linkRemove"
        ],
        imageAllowedTypes: ["jpeg", "jpg", "png", "gif"],
        imageInsertButtons: ["imageBack", "|", "imageUpload"],
        imageUploadParam: 'image',
        imageUploadMethod: 'POST',
        imageUploadURL: HOST + '/api/UploadFroalaImage/',
        imageResize: true,
        placeholderText: placeholder ? placeholder : 'Edit Your Content Here!',
        charCounterCount: true,
        toolbarButtons: ['paragraphFormat', "|", 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', '|', 'align', 'formatOL', 'formatUL', 'quote', '-', 'insertLink', 'insertImage', 'insertVideo', 'embedly', 'insertTable', '|', 'undo', 'redo', 'clearFormatting', 'selectAll', 'help'],
        quickInsertButtons: ['image', 'video', 'embedly', 'table', 'ul', 'ol', 'hr'],
        events: {
            'initialized': function () {
                console.log('Editor initialized');
            },
        }
    };

    return (
        <div className={cls(className, "content")} id='froala-editor-field'>
            <Editor
                // ref={editorRef}

                tag='textarea'
                config={props.config ?
                    { ...props.config, key } :
                    { ...config, key }
                }
                model={content}
                onModelChange={handleContentChange}

            />
        </div>
    );
}


import React, { useEffect, useRef, useState } from "react"
import { TagAPI } from 'api'
import { CoursePreferencesFieldProps } from "./CoursePreferences";
import { Button, Divider, Input, InputRef, Select, Space, notification } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

interface TagType {
    id: string;
    name: string;
}

export const TagsField = (props: CoursePreferencesFieldProps) => {
    const { course, handleInputChange } = props;

    const [tags, setTags] = useState<TagType[]>([])
    const [loading, setLoading] = useState(false)
    const [newTag, setNewTag] = useState('');
    const inputRef = useRef<InputRef>(null);
    const [api, contextHolder] = notification.useNotification();


    const handleGetTags = async () => {
        const response = await TagAPI.GET_ALL()
        console.log('response', response)
        if (response.status) {
            setTags(response.data)
        }
    }

    console.log('tags', tags)

    const handleUpdate = (value: any) => {
        console.log('value', value)
        handleInputChange({ name: "tags", value: value });
    }


    useEffect(() => {
        handleGetTags()
        return () => {
            setTags([])
        }
    }, []);


    const handleAddNewTag = async () => {
        setLoading(true);
        try {
            const response = await TagAPI.CREATE(newTag);
            if (response.status) {
                setTags(prev => [...prev, response.data]);
            }else{
                api.error({
                    message: response.message
                })
            }
        } catch (error) {
            api.error({
                message: 'Failed creating tag'
            })
            console.error('Error creating tag:', error);
        }
        setLoading(false);
        setNewTag("")
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const handleDeleteTag = async (id: string) => {
        setLoading(true);
        try {
            const response = await TagAPI.DELETE(id);
            if (response.status) {
                setTags(prev => prev.filter(tag => tag.id !== id));
            }else{
                api.error({
                    message: response.message
                })
            }
        } catch (error) {
            api.error({
                message: 'Failed deleting tag'
            })
            console.error('Error deleting tag:', error);
        }
        setLoading(false);
    }
   

    return (
        <div className="field ">
            {contextHolder}
            <div className="label">Tags</div>
            <Select
                mode="multiple"
                loading={loading}
                value={course?.tags}
                onChange={handleUpdate}
                style={{ width: "100%" }}
                //@ts-ignore
                filterOption={(input:any, option:any) =>  
                option.children.props.children[0].toLowerCase().includes(input.toLowerCase())
            }
                placeholder="Select or add new tags"
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px', width: "100%" }}>
                            <Input
                                placeholder="Please enter item"
                                ref={inputRef}
                                value={newTag}
                                onChange={(e) => setNewTag(e.target.value)}
                                onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button type="text" icon={<PlusOutlined />} onClick={handleAddNewTag}>
                                Add item
                            </Button>
                        </Space>
                        
                    </>
                
                )}
                // options={tags.map((tag) => ({ label: tag.name, value: tag.id }))}
            >
                {tags.map((tag) => (
                    <Select.Option key={tag.id} value={tag.id}>
                        <Space style={{justifyContent: "space-between", display:"flex"}} >
                        {tag.name} 
                        {!course.tags.includes(tag.id) && (
                        <span onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteTag(tag.id);
                        }}>
                            <DeleteOutlined />
                        </span>
                    )}
                        </Space>
                    </Select.Option>
                ))

                }
            </Select>
        </div>
    )

}

    
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import MetaRouter from './router';

import 'bulma/css/bulma.css';
import './styles/style.css';
import "./styles/custom.css"
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import { UserProvider } from './UserContext';
import Navbar from 'components/navbar/Navbar';
import { isMobile, isTablet, useMobileOrientation } from 'react-device-detect';


export const Term = {
  course: "Course",
  classroom: "Subject",
  module: "Curio",
  lesson: "Lesson",
  quiz: "Quiz",
  task: "Task",
  program: "Module"
}



function App() {

  const { isLandscape } = useMobileOrientation();

  useEffect(() => {
    if (isMobile) {
      document.body.classList.add('is-mobile')
    }

    if (isTablet) {
      document.body.classList.add('is-tablet')
    }

    if (isLandscape) {
      document.body.classList.add('is-landscape')
    }

    return () => {
      document.body.classList.remove('is-mobile');
      document.body.classList.remove('is-tablet');
      document.body.classList.remove('is-landscape');
    };
  }, [isMobile, isLandscape]);


  return (
    <UserProvider>
      <BrowserRouter>
        <>
          <Navbar />
          <MetaRouter />
        </>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;


import React from 'react'
import { KEY } from 'consts'
import cookie from "react-cookies"

export default function Logout() {

    cookie.remove(KEY.TOKEN_ACCESS, { path: '/' })
    cookie.remove(KEY.TOKEN_REFRESH, { path: '/' })
    cookie.remove(KEY.ROLE, { path: '/' })

    if (cookie.load(KEY.TOKEN_ACCESS) == null) {
        window.location.href = "/"
        return <></>
    }

    return <></>
}


import { Space } from "antd";
import { IMAGE } from "consts";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Course } from "types";
import { TagType } from "types";
import { Table, Tag, Button, Tooltip } from "antd";
import { EditOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import DetailAuthoredCourse from "./DetailAuthoredCourse";
import { MultiSelectAction } from "components/ui/MultiSelectAction";
import AssignCourseForm from "./AssignCourseForm";
import { isMobile } from "react-device-detect";

export const minTableHeightRequired = 650

export const AuthoredCoursesTable = (props: {
    courses: Course[],
    reload: () => void,
    loading: boolean
}) => {


    const { courses, reload, loading } = props;
    const [assignCourse, setAssignCourse] = useState<Course[] | null>(null);
    const [selectedRows, setSelectedRows] = useState<Course[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [tableHeight, setTableHeight] = useState<number>(300)

    useEffect(() => {
        const maxTableHeight = window.innerHeight - 230
        setTableHeight(maxTableHeight)
    }, [])

    const navigate = useNavigate();
    const [openDetail, setOpenDetail] = useState(false);

    const [course, setCourse] = useState<Course>(null);


    const editCourse = (e: any, courseId: string) => {
        e.preventDefault();
        navigate("/course/edit/?c=" + courseId);
    };

    const handleAssignCourse = (e: any, course: Course) => {
        console.log(e);
        e.stopPropagation();
        setAssignCourse([course]);
    };

    const handleMultipleInvitationCourse = () => {
        console.log('selectedRows', selectedRows)
        setAssignCourse(selectedRows);
    }

    const handleRowClick = async (course: Course) => {
        // const detail = await getDetailInfo(data.id)
        setCourse(course);
        setOpenDetail(true);
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
            setSelectedRows(selectedRows);
            setSelectedRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
        }),
    };

    const resetAssignCourse = (e?: any) => {
        e && e.stopPropagation();
        setAssignCourse(null);
        setSelectedRows([]);
        setSelectedRowKeys([]);
    };


    const columns = [
        {
            title: "Title",
            dataIndex: "course_name",
            key: "title",

            render: (text: string, record: Course) => {
                let imageUrl = record.image;
                if (!imageUrl) {
                    imageUrl = IMAGE.DEFAULT;
                }

                return (
                    <Space style={{ minWidth: 300 }}>
                        <img
                            src={imageUrl}
                            className={"course-image"}
                            alt="course"
                            style={{ width: 40, height: 40, marginRight: 10, borderRadius: 5, objectFit: "cover" }}
                        />
                        <Link to={"/course/edit/?c=" + record.id}>
                            <span style={{ cursor: "pointer" }}> {text}</span>
                        </Link>
                    </Space>
                );
            },
            sorter: (a: Course, b: Course) =>
                a.course_name.localeCompare(b.course_name),
            className: "has-avatar",
        },
        {
            title: "Tags",
            dataIndex: "tags",
            key: "tags",
            width: 200,
            // sorter: (a: Course, b: Course) => a.price - b.price,
            render: (_: any, record: Course) => (
                <Space>
                    {record.tags.map((tag: TagType) => (
                        <Tag key={tag.id} className="mr-0">{tag.name}</Tag>
                    ))}
                </Space>
            )
        },
        {
            title: "Assignee",
            dataIndex: "assignee",
            key: "assignee",
            width: 120,
            render: (assignee: number) => {
                return (
                    <div style={{ cursor: "pointer" }}>
                        <UserOutlined className="mr-2" />
                        <span>{assignee}</span>
                    </div>
                );
            },
            sorter: (a: Course, b: Course) => {
                const aTotal = a.assignee
                const bTotal = b.assignee
                return aTotal - bTotal;
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 150,
            render: (status: string) => (
                <Tag className={"ant-tag " + status}>{status}</Tag>
            ),
            sorter: (a: Course, b: Course) => a.status.localeCompare(b.status),
        },

        {
            title: " ",
            dataIndex: "",
            key: "x",
            className: "action",
            width: 80,
            render: (record: Course) => (
                <div className="action-group">
                    {/* <div className="button">View</div> */}

                    <Space size={2}>
                        <Tooltip title={"Edit"}>
                            <Button
                                type="text"
                                className="px-2"
                                onClick={(e) => editCourse(e, record.id)}
                            >
                                <EditOutlined />
                            </Button>
                        </Tooltip>

                        <Tooltip title={"Invitation"}>
                            <Button
                                type="text"
                                onClick={(e) => handleAssignCourse(e, record)}
                                className="px-2"
                            >
                                <UserAddOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                </div>
            ),
        },
    ];

    const columnsMobile = [
        {
            title: "Title",
            dataIndex: "course_name",
            key: "title",

            render: (text: string, record: Course) => {
                let imageUrl = record.image;
                if (!imageUrl) {
                    imageUrl = IMAGE.DEFAULT;
                }

                return (
                    <Space style={{ minWidth: 300 }}>
                        <img
                            src={imageUrl}
                            className={"course-image"}
                            alt="course"
                            style={{ width: 40, height: 40, marginRight: 10, borderRadius: 5, objectFit: "cover" }}
                        />

                        <span style={{ cursor: "pointer" }}> {text}</span>
                    </Space>
                );
            },
            sorter: (a: Course, b: Course) =>
                a.course_name.localeCompare(b.course_name),
            className: "has-avatar",
        },
        {
            title: "Extra",
            key: "tags",
            render: (_: any, record: Course) => (
                <div className="extra">
                    <div style={{ cursor: "pointer" }} className="mr-2">
                        <UserOutlined className="mr-2" />
                        <span>{record.assignee}</span>
                    </div>
                    <Tag className={"ant-tag " + record.status}>{record.status}</Tag>
                    <Space>
                        {record.tags.map((tag: TagType) => (
                            <Tag key={tag.id} className="mr-0">{tag.name}</Tag>
                        ))}
                    </Space>
                </div>
            )
        },
        {
            title: "Action",
            dataIndex: "",
            key: "x",
            className: "action",
            width: 80,
            render: (record: Course) => (
                <div className="action-group">
                    {/* <div className="button">View</div> */}

                    <Space size={2}>
                        <Tooltip title={"Invitation"}>
                            <Button
                                type="text"
                                onClick={(e) => handleAssignCourse(e, record)}
                                className="px-1"
                            >
                                <UserAddOutlined />
                            </Button>
                        </Tooltip>
                    </Space>
                </div>
            ),
        },
    ];


    return (
        <div className="table-box">
            {assignCourse && (
                <AssignCourseForm
                    courses={assignCourse}
                    reload={reload}
                    close={resetAssignCourse}
                />
            )}

            {course &&
                <DetailAuthoredCourse
                    open={openDetail}
                    courseId={course?.id}
                    reload={reload}
                    close={() => setOpenDetail(false)}
                />
            }

            {courses && (
                <Table
                    loading={loading}
                    rowSelection={{
                        ...rowSelection,
                        selectedRowKeys: selectedRowKeys
                    }}
                    scroll={{ y: tableHeight }}
                    className="authored-course-table"
                    columns={isMobile ? columnsMobile : columns}
                    dataSource={courses}
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record),
                    })}
                    rowKey={"id"}
                />
            )}

            {selectedRows.length > 0 &&
                <MultiSelectAction
                    selectedRows={selectedRows}
                    buttons={[
                        {
                            type: "primary",
                            label: "Assign Course",
                            icon: <UserAddOutlined />,
                            action: handleMultipleInvitationCourse,
                            ghost: true
                        }
                    ]}
                />
            }

        </div>
    )

}



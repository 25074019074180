import withAuth from 'WithAuth'
import React, { useEffect, useState } from 'react'
import cookie from 'react-cookies'
import { useSearchParams } from 'react-router-dom'
import AuthoredCourses from './authored-courses/AuthoredCourses'
import PersonalCourses from './personal-courses/PersonalCourses'

import "./profile.css"


import { Tabs } from 'antd'
import { KEY, PAGE } from 'consts'
import { useUserContext } from 'UserContext'
import { isMobile } from 'react-device-detect'

function Profile() {


    const role = cookie.load(KEY.ROLE)
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState("personal-courses")
    const { setPage } = useUserContext()

    useEffect(() => {
        setPage(PAGE.HOME)
    }, [setPage]);

    useEffect(() => {
        setSearchParams({ tab: activeTab });
    }, [activeTab]);



    useEffect(() => {
        const tab = searchParams.get("tab")
        tab && setActiveTab(tab)
    }, []);

    // console.log(activeTab)

    const items = [
        {
            key: 'personal-courses',
            label: 'Personal Courses',
            children: <PersonalCourses />,
        },

    ];

    role === "author" && items.push(
        {
            key: 'authored-courses',
            label: 'Authored Courses',
            children: <AuthoredCourses />,
        },
    )


    const handleChangeTab = (e: string) => {
        setActiveTab(e)
    }

    return (
        <div className="profile">
            <div className="s-container">
                <Tabs
                    destroyInactiveTabPane={true}
                    activeKey={activeTab}
                    items={items}
                    onChange={handleChangeTab}
                    tabPosition={isMobile ? "bottom" : "left"}
                    className='profile-tabs'
                />
            </div>
        </div>
    )
}

export default withAuth(Profile)
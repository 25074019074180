import { HOST } from "consts";
import { sendPublicRequest } from "./Main";

export namespace AuthAPI {

    export const Register = async (data: any, next: string | null) => {
        const endpoint = `${HOST}/api/Register/?next=` + next
        const method = "POST";
        const body = JSON.stringify(data)
        return await sendPublicRequest(endpoint, method, body)
    }

    export const Login = async (data: any, next: string | null) => {
        const endpoint = `${HOST}/api/Login/?next=` + next
        const method = "POST";
        const body = JSON.stringify(data)
        return await sendPublicRequest(endpoint, method, body)
    }

    export const Activation = async (token: string) => {
        const endpoint = `${HOST}/api/UserActivation/?token=` + token
        const method = "POST";
        return await sendPublicRequest(endpoint, method)
    }


    export namespace Password {
        export const GetResetPasswordUserWithHash = async (hash: string) => {
            const endpoint = `${HOST}/api/ResetPassword/?hash=` + hash
            const method = "GET";
            return await sendPublicRequest(endpoint, method)
        }

        export const ResetPassword = async (email: string) => {
            const endpoint = `${HOST}/api/ResetPassword/?email=` + email
            const method = "POST";
            return await sendPublicRequest(endpoint, method)
        }

        export const ResetPasswordValidation = async (email: string, n: string) => {
            const endpoint = `${HOST}/api/ResetPasswordValidation/?e=${email}&n=${n}`
            const method = "POST";
            return await sendPublicRequest(endpoint, method)
        }

        export const SubmitNewPassword = async (data: any) => {
            const endpoint = `${HOST}/api/ResetPassword/`
            const method = "PUT";
            return await sendPublicRequest(endpoint, method, data)
        }
    }


}
import React, { useEffect, useState } from 'react'
import { CourseImportContents, DuplicateContent } from '../../api/CourseBuilderAPI'
import { Menu, Spin } from 'antd';
import { COURSE } from 'consts';
import { Course, Module } from 'types';


export default function ImportContentBox(props: {
  close: () => void,
  courseId: string,
  moduleId: string,
  reload: () => void
}) {
  const { close, courseId, moduleId, reload } = props;
  const [loading, setLoading] = useState(true)
  const [courses, setCourses] = useState([])

  const handleCourseImportContents = async () => {

    setLoading(true)
    const response = await CourseImportContents()
    console.log('response', response)
    if (response.status) {
      setCourses(response.data)
    }

    setLoading(false)
  }

  useEffect(() => {
    handleCourseImportContents()
  }, []);

  const handleClose = () => {
    close()
  }



  const getIcon = (obj_type: COURSE.CONTENT_TYPE) => {
    switch (obj_type) {
      case COURSE.CONTENT_TYPE.LESSON:
        return <i className="fas fa-graduation-cap"></i>;
      case COURSE.CONTENT_TYPE.QUIZ:
        return <i className="fas fa-question-circle"></i>;
      case COURSE.CONTENT_TYPE.QUIZES:
        return <i className="fas fa-question-circle"></i>;
      case COURSE.CONTENT_TYPE.TASK:
        return <i className="fa-solid fa-clipboard-list"></i>;
      default:
        return <i className="fas fa-graduation-cap"></i>;
    }
  };


  const renderMenuItems = (data: any) => {
    const items: any = [];

    data.forEach((course: Course) => {
      const courseItem = getItem(course.course_name, course.id, null);
      const moduleItems = course.modules.map((module: Module) => {
        const moduleItem = getItem(module.title, module.id, null);
        const contentItems = module.contents.map((content: any) => {
          const contentItem = getItem(
            <div className='content-import-item'>
              {content.title}
              <div className="import-button">Import</div>
            </div>, // label
            content.id, // key
            getIcon(content.obj_type), // icon
            null // children
          );
          return contentItem;
        });
        moduleItem.children = contentItems; // Nest content items within modules
        return moduleItem;
      });
      courseItem.children = moduleItems; // Nest module items within courses
      items.push(courseItem);
    });

    return items;
  };


  const handleImportContent = async (content: any) => {
    console.log(content)
    const path = content.keyPath
    const data = {
      source: {
        course: path[2],
        module: path[1],
        content: path[0]
      },
      target: {
        course: courseId,
        module: moduleId,
      }
    }

    const response = await DuplicateContent(JSON.stringify(data))
    console.log(response)
    if (response.status) {
      reload()
    }
  }

  console.log(courses)
  return (
    <div className="import-content-box">
      <div className="delete" onClick={handleClose}></div>
      <Spin spinning={loading} />
      <Menu
        className="import-list pb-6"
        style={{ width: 400 }}
        mode="inline" // Set your desired mode (e.g., 'vertical')
        theme="light" // Set your desired theme (e.g., 'dark')
        items={renderMenuItems(courses)} // Provide the menu items array
        onClick={handleImportContent}
      />
    </div>
  )
}


function getItem(label: any, key: any, icon: any, children?: any) {
  return {
    key,
    icon,
    label,
    children,
  };
}
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import cookie from 'react-cookies'


const WithAuth = (Component: React.FC<any>) => {

    const AuthenticatedComponent = (props: any) => {
        const navigate = useNavigate();
        useEffect(() => {
            const accessToken = cookie.load('ta_');
            if (!accessToken) {
                navigate('/login');
            }
        }, []);

        return <Component {...props} />;
    };

    return AuthenticatedComponent;
};

export default WithAuth;

import { Drawer, Spin, Button, notification, Alert } from 'antd'
import React, { useState, useEffect } from 'react'
import { ReviewTask } from './ReviewTask';
import { API } from 'api';
import { Reviewer, Task } from 'types';

interface ReviewData {
    reviewerId: string,
    reviews: any[]
}

export default function ReviewCourse(props: {
    courseAssignedId?: string,
    reviewerId?: string,
    close?: () => void,
    isPopup: boolean,
    mode: string
}) {

    const { courseAssignedId, reviewerId: propReviewerId, close, isPopup = true, mode } = props
    const [tasks, setTasks] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingSave, setLoadingSave] = useState(false)
    const [reviewData, setReviewData] = useState<ReviewData | null>(null)
    const [reviewer, setReviewer] = useState<Reviewer>(null)
    const [api, contextHolder] = notification.useNotification();

    const handleGetReview = async () => {
        setLoading(true)
        const response = await API.Course.Review.GET(courseAssignedId, propReviewerId, mode)
        console.log(response)
        if (response.status) {
            setReviewer(response.data.reviewer)
            setReviewData({
                reviewerId: response.data.reviewer.id,
                reviews: extractReviewData(response.data.tasks)
            })
            setTasks(response.data.tasks)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (courseAssignedId || propReviewerId) {
            handleGetReview()
        }
    }, [courseAssignedId, propReviewerId]);

    const CustomHeader = () => {
        return (
            <div className="task-review-header">
                <p>Task Review</p>
            </div>
        )
    }

    const handleClose = () => {
        setTasks([])
        close && close()
    }

    const handleUpdate = (data: any) => {
        if (!reviewData) return
        const currentReviews = reviewData.reviews;
        const filtered = currentReviews.filter((item: any) => item.id !== data.id);

        // console.log('filtered', filtered)

        // Assuming 'data' is the updated review information, you would add it to the filtered array
        // Make sure 'data' contains the 'id' field and any other relevant information

        setReviewData((prev: any) => ({
            ...prev,
            reviews: [...filtered, data]  // Replace 'currentReviews' with 'filtered' and add the updated 'data'
        }));
    }

    const handleSaveReview = async () => {
        console.log('reviewData', reviewData)
        setLoadingSave(true)
        try {
            const response = await API.Course.Review.SAVE(reviewData, mode)
            console.log('response', response)
            if (response.status) {
                api.success({
                    message: 'Great! Your review has been saved!',
                });
            } else {
                api.info({
                    message: response.message,
                });
            }
        } catch (error) {
            console.log('error', error)
        }
        setLoadingSave(false)
    }

    // console.log('course review data', reviewData)

    return (
        <>
            {isPopup ?

                <Drawer
                    width={800}
                    title={<CustomHeader />}
                    open={courseAssignedId !== undefined}
                    onClose={handleClose}
                >
                    {contextHolder}
                    {loading && <Spin />}

                    {reviewer?.has_review &&
                        <Alert message="Already reviewed" type="info" showIcon
                            className='mb-3'
                        />
                    }

                    {tasks?.length > 0 &&
                        tasks.map((item: Task) => (
                            <ReviewTask
                                key={item.id}
                                task={item}
                                reviewer={reviewer}
                                reviewData={reviewData}
                                handleUpdate={handleUpdate}

                            />
                        ))
                    }

                    {!reviewer?.has_review &&
                        <Button

                            loading={loadingSave}
                            type='primary'
                            onClick={handleSaveReview}
                        >Save
                        </Button>
                    }
                </Drawer>

                :

                <div className="tasks">
                    {contextHolder}
                    {loading && <Spin />}
                    {reviewer?.has_review &&
                        <Alert message="Already reviewed" type="info" showIcon
                            className='mb-3'
                        />
                    }

                    {tasks?.length > 0 &&
                        tasks.map((item: Task) => (
                            <ReviewTask
                                key={item.id}
                                reviewer={reviewer}
                                mode={mode}
                                task={item}
                                reviewData={reviewData}
                                handleUpdate={handleUpdate}

                            />
                        ))

                    }

                    {!reviewer?.has_review &&
                        <Button

                            loading={loadingSave}
                            type='primary'
                            onClick={handleSaveReview}
                        >Save
                        </Button>
                    }
                </div>

            }


        </>
    )
}


const extractReviewData = (tasks: Task[]) => {

    const reviews: any = []
    tasks.forEach(task => {
        task.questions.forEach((question: any) => {
            question.review.forEach((review: any) => {
                reviews.push(review)
            })
        })
    })

    return reviews
}


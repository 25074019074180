import { useUserContext } from 'UserContext';
import { Button, Tooltip } from 'antd';
import { CreateRoom, useChat } from 'curiobites-chat';
import React from 'react'
import { Author } from 'types';
import { FacebookFilled, InstagramFilled, LinkedinFilled, MailFilled, MessageFilled, TwitterSquareFilled } from '@ant-design/icons';
import { getAvatarUrlByName } from 'functions';


export const AuthorSection = (props: {
    author: Author,
}) => {

    const { author } = props;
    const { user } = useUserContext()
    const { chatSocket } = useChat()

    console.log("chek profile ---------->", author, user)

    const handleSocialMediaClick = (url: string) => {
        window.open(url, "_blank")
    }

    // const handleOpenChat = async () => {
    //     const data = {
    //         event_type: "open_room",
    //         user1: user.username,
    //         user2: profile.username,
    //     }
    //     console.log(data)
    //     // chatroomSocket.send(JSON.stringify(data))
    //     const response = await OpenChatRoom(JSON.stringify(data))
    //     console.log(response)
    //     if (response.status) {
    //         setOpenChat(true)
    //     }
    // }


    const handleOpenRoom = async () => {
        if (!user) return
        console.log(chatSocket)
        const users = {
            user1: {
                id: user.user,
                name: `${user.firstname} ${user.lastname}`,
                firstname: user.firstname,
                lastname: user.lastname,
                avatar: user.avatar,
                username: user.username,
                email: user.email,
            },
            user2: {
                id: author.id,
                name: `${author.firstname} ${author.lastname}`,
                firstname: author.firstname,
                lastname: author.lastname,
                avatar: author.avatar,
                username: author.username,
                email: author.email,
            },
        }

        await CreateRoom(users, chatSocket)
    }

    console.log('author', author)
    return (
        <div className="author-section mt-5">
            <div className="p title is-5 mb-3">About author</div>
            <div className="author-box">
                <img src={author.avatar ? author.avatar : getAvatarUrlByName(author.firstname + " " + author.lastname)} alt="" className='avatar' />
                <div className="author">
                    <p className='author-name'>{author.firstname} {author.lastname}</p>

                    <p className='author-contact'>
                        {user &&
                            <Tooltip title="Chat">
                                <MessageFilled onClick={() => handleOpenRoom()} rev={undefined} />
                            </Tooltip>
                        }

                        {author.email &&
                            <Tooltip title="Email">
                                <MailFilled onClick={() => window.location.href = `mailto:${author.email}`} rev={undefined} />
                            </Tooltip>
                        }

                        {author.linkedin &&
                            <LinkedinFilled onClick={() => handleSocialMediaClick(author.linkedin)} rev={undefined} />
                        }

                        {author.twitter &&
                            <TwitterSquareFilled onClick={() => handleSocialMediaClick(author.twitter)} rev={undefined} />
                        }

                        {author.instagram &&
                            <InstagramFilled onClick={() => handleSocialMediaClick(author.instagram)} rev={undefined} />
                        }

                        {author.facebook &&
                            <FacebookFilled onClick={() => handleSocialMediaClick(author.facebook)} rev={undefined} />
                        }

                    </p>
                </div>

                <Button className='is-outlined'>Follow</Button>

            </div>
            {
                author?.bio &&
                <p className="bio">
                    {author.bio}
                </p>
            }
        </div>


    )
}
import { COURSE, HOST } from "consts";
import { sendRequest } from "./Main";

export const GetIntegration = async () => {
    const endpoint = HOST + "/api/Integration/"
    const method = "GET"
    return await sendRequest(endpoint, method)
}

export const CourseImportContents = async () => {
    const endpoint = HOST + "/api/Course/ImportContents/"
    const method = "GET"
    return await sendRequest(endpoint, method)
}

export const DuplicateContent = async (data: any) => {
    const endpoint = HOST + "/api/Course/ImportContents/"
    const method = "POST"
    return await sendRequest(endpoint, method, data)
}

export const EditCourseContent = async (contentType: string, contentId: string) => {
    const endpoint = `${HOST}/api/Course/${contentType}/?pk=${contentId}`
    const method = "GET"
    return await sendRequest(endpoint, method)
}

export const AddModuleContent = async (data: any, contentType: COURSE.CONTENT_TYPE | string, moduleId: string) => {
    const endpoint = `${HOST}/api/Course/${contentType}/?pk=` + moduleId
    const method = "POST"
    const body = JSON.stringify(data)
    return await sendRequest(endpoint, method, body)
}

export const GetModule = async (moduleId: string) => {
    const endpoint = HOST + "/api/Course/Module/?pk=" + moduleId
    const method = "GET"
    return await sendRequest(endpoint, method)
}

export const UpdateModule = async (data: any, pk: string, method: string) => {
    const endpoint = HOST + "/api/Course/Module/?pk=" + pk // moduleId or courseId
    const body = JSON.stringify(data)
    return await sendRequest(endpoint, method, body)
}


export const UpdateLesson = async (data: any, lessonId: string, method: string) => {
    const endpoint = HOST + "/api/Course/Lesson/?pk=" + lessonId
    const body = JSON.stringify(data)
    return await sendRequest(endpoint, method, body)
}

export const GetLessonAttachment = async (lessonId: string) => {
    const endpoint = HOST + "/api/Course/Lesson/Attachment/?pk=" + lessonId
    const method = "GET"
    return await sendRequest(endpoint, method)
}

export const GetUpdateQuiz = async (quizId: string, method: string, data?: any) => {
    const endpoint = HOST + "/api/Course/Quiz/?pk=" + quizId
    const body = JSON.stringify(data)
    return await sendRequest(endpoint, method, body)
}

export const GetUpdateQuestion = async (pk: string, method: string, data?: any) => {
    const endpoint = HOST + "/api/Course/Quiz/Question/?pk=" + pk // questionId or quizId
    const body = JSON.stringify(data)
    return await sendRequest(endpoint, method, body)
}


export const DeleteLessonAttachment = async (lessonId: string, attachmentId: string) => {
    const endpoint = HOST + `/api/Course/Lesson/Attachment/?pk=${lessonId}&attachment=${attachmentId}`
    const method = "DELETE"
    return await sendRequest(endpoint, method)
}


//delete lesson, quiz or task
export const DeleteModuleContent = async (contentType: string, contentId: string) => {
    const endpoint = `${HOST}/api/Course/${contentType}/?pk=` + contentId
    const method = "DELETE"
    return await sendRequest(endpoint, method)
}


export const GetCourse = async (courseId: string) => {
    const endpoint = HOST + "/api/Course/?pk=" + courseId
    const method = "GET"
    return await sendRequest(endpoint, method)
}

export const UpdateCourse = async (data: any, courseId: string) => {
    const endpoint = HOST + "/api/Course/?pk=" + courseId
    const method = "PUT"
    return await sendRequest(endpoint, method, data)
}

export const DeleteCourse = async (courseId: string) => {
    const endpoint = HOST + "/api/Course/?pk=" + courseId
    const method = "DELETE"
    return await sendRequest(endpoint, method)
}


export const GetCourseEditContents = async (courseId: string) => {
    const endpoint = HOST + "/api/Course/e/Contents/?pk=" + courseId
    const method = "GET";
    return await sendRequest(endpoint, method)

}


// Curse Preferences API's

export const CreatePublicShareCourse = async (courseId: string) => {
    const endpoint = HOST + "/api/Course/PublicShareCourse/?pk=" + courseId
    const method = "GET"
    return await sendRequest(endpoint, method)
}


export const DeletePublicShareCourse = async (courseId: string) => {
    const endpoint = HOST + "/api/Course/PublicShareCourse/?pk=" + courseId
    const method = "DELETE"
    return await sendRequest(endpoint, method)
}
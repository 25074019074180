import React, { useEffect, useState } from 'react'
import CourseDetailModal from './course-detail-modal/CourseDetailModal';
import StarRatings from 'react-star-ratings';
import { getAvatarUrlByName, getRandomImage, starSVG } from 'functions';
import { CURRENCY } from 'consts';

import "./course.css"


export default function CourseCard(props: {
    course: any
}) {
    const { course } = props;

    const [showDetail, setShowDetail] = useState(false)
    const [image, setImage] = useState("")

    useEffect(() => {
        if (course.image) {
            setImage(course.image)
        } else {
            setImage(getRandomImage())
        }
    }, [course]);

    let avatar = course.author.avatar
    if (!avatar) {
        avatar = getAvatarUrlByName(course.author.firstname + " " + course.author.lastname)
    }

    const courseDetails = () => {
        setShowDetail(true)
    }
    const handleClose = () => {
        setShowDetail(false)
        console.log('showDetail', showDetail)
    }

    return (
        <div className="public-course-card"  >
            {showDetail &&
                <CourseDetailModal
                    courseId={course.id}
                    close={handleClose}
                    open={showDetail}
                    img={image}
                />
            }

            <div className="card-head" onClick={courseDetails}>
                <img src={image} alt="" loading="lazy" />
            </div>

            <div className="card-body" onClick={courseDetails}>
                <div className="course-title">{course.course_name}</div>
            </div>
            <div className="card-foot">
                <div className="author">
                    <img src={avatar} alt="" className='mr-2' />
                    <p>{course.author.firstname} {course.author.lastname}</p>
                </div>
                <div className="extra">
                    {course.review ?
                        <StarRatings
                            rating={parseFloat(course.review)}
                            starDimension="16px"
                            starSpacing="0"
                            svgIconPath={starSVG}
                            starRatedColor="#ffbb30"
                            svgIconViewBox="0 0 24 24"
                        />
                        : "No review"}
                    <div className="price">{course.price > 0 ? `$${course.price} ${CURRENCY}` : "Free"}</div>
                </div>
            </div>
        </div>
    )
}

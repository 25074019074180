import React from 'react'

interface FormModalProps {
    width?: string,
    close: () => void,
    children: React.ReactNode,
    className?: string
}

export default function FormModal(props: FormModalProps) {
    const { width, close, children, className } = props
    return (
        <div className={`modal form-modal ${className}`}>
            <div className="modal-box" style={{ width: width }}>
                {children}
            </div>
            <div className="overlay"
                onClick={close}
            ></div>
        </div>
    )
}



const Header = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="modal-header">
            {children}
        </div>
    )
}

const Body = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="modal-body">
            {children}
        </div>
    )
}

const Footer = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="modal-footer">
            {children}
        </div>
    )
}

FormModal.Header = Header;
FormModal.Body = Body;
FormModal.Footer = Footer;
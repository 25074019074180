import { Button, DatePicker, notification } from 'antd';
import { HTMLRenderer } from 'functions';
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs';
import { QuestionViewProps } from '../QuestionView';
import { COURSE, FORMAT } from 'consts';
import { Answer } from 'types';
import moment from 'moment';
import { API } from 'api';
import cookie from "react-cookies"


export default function DateQuestionView(props: QuestionViewProps & {
    type: string

}) {
    const { data, mode, session, type, setAllowNext } = props;
    const [answer, setAnswer] = useState<Answer>([])
    const [loading, setLoading] = useState(false)
    const [api, contextHolder] = notification.useNotification()
    const publicUser = cookie.load("public_user")

    const updateAnswer = (answer: any) => {
        const dateString = dayjs(answer).format(FORMAT.DATE)
        setAnswer([dateString])
    }

    const handleConfirm = () => {
        if (mode === COURSE.ENROLL_MODE.ENROLL || mode === COURSE.ENROLL_MODE.PUBLIC) {
            handleSaveAnswer()
        } else {
            setAllowNext(true)
        }
    }

    // console.log(answer)

    useEffect(() => {
        if (data.user_answer) {
            console.log(data.user_answer)
            setAllowNext(true)
            const dateAnswer = moment(data.user_answer[0].user_answer_date).format(FORMAT.DATE)
            setAnswer([dateAnswer])
        }
    }, [data])


    const handleSaveAnswer = async () => {
        setLoading(true)
        const formData = new FormData()
        formData.append('question', JSON.stringify(data));
        formData.append('answer', answer);
        formData.append('session', session);
        formData.append('type', type)
        formData.append('mode', mode);
        if (mode === COURSE.ENROLL_MODE.PUBLIC) {
            formData.append("user", publicUser)
        }

        const response = await API.Course.UserAnswer.SAVE(formData, mode)
        if (response.status) {
            api.success({
                message: "Answer saved successfully.",
            })
            setAllowNext(true)
        }
        setLoading(false)

    }

    console.log(typeof (answer[0]))

    console.log(answer.length > 0)

    console.log('dayjs()', dayjs().format(FORMAT.DATE))

    return (
        <div className={data.response_type + "-view"}>
            {contextHolder}
            <div className="question">
                <div className="question-label">Question</div>
                <HTMLRenderer content={data.question} key={data.id} />
            </div>

            <div className="tip mt-4 mb-3">Select Date</div>

            <div className={"mb-4 answer-box " + data.response_type}>

                <DatePicker value={answer.length > 0 ? dayjs(answer[0], FORMAT.DATE) : dayjs()}
                    format={FORMAT.DATE}
                    onChange={updateAnswer}
                />
                {/* <DatePicker
                    value={dayjs()}
                    format={FORMAT.DATE}
                    onChange={updateAnswer}
                /> */}
            </div>

            <Button
                loading={loading}
                type='primary'
                disabled={!answer.length}
                onClick={handleConfirm}
            >Confirm
            </Button>
        </div>
    )
}

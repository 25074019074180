import React from 'react';
import { Button, Space, Flex } from 'antd';
import "./global.css"

export const MultiSelectAction = (props: {
    selectedRows: any[]
    buttons: {
        label: string,
        icon?: any,
        action?: () => void,
        type?: "primary" | "default" | "link" | "text",
        ghost?: boolean | undefined
    }[]
}) => {
    const { selectedRows, buttons } = props;

    console.log('selectedRows', selectedRows)
    console.log('buttons', buttons)
    return (
        <div className="multi-select-action">
            <Flex justify="space-between" >
                <Space>
                    <span className="selected-count">{selectedRows.length}</span>
                    Selected
                </Space>
                {buttons.map((button, index: number) => (
                    <Button key={index} onClick={button.action} type={button.type} ghost={button.ghost} >
                        <Space>
                            {button.icon}
                            {button.label}
                        </Space>
                    </Button>
                ))}
            </Flex>
        </div>
    )
}
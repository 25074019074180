import React, { useEffect, useState } from 'react'
import { Checkbox, Input, InputNumber, Tooltip } from 'antd';
import { Answer, Question, Quiz } from 'types';
import MyEditor from 'components/ui/FroalaEditor';
import { getKey } from 'functions'


const maxOption = 10

const defaultAnswer = Array.from({ length: 4 }, () => ({
    id: getKey(15),
    correct: false,
    answer: "",
    explanation: "",
    score: 0,
}));


export default function OptionQuestionForm(props: {
    answer: Answer,
    question: Question,
    updateAnswer: (obj: any) => void,
    updateQuestion: (obj: any) => void,
    type: string,
    quiz?: Quiz
}) {

    const { answer: propsAnswer, question, updateAnswer, updateQuestion } = props;

    const [answer, setAnswer] = useState(defaultAnswer);

    useEffect(() => {
        if (propsAnswer) {
            setAnswer(propsAnswer);
        }
    }, [propsAnswer]);


    useEffect(() => {
        if (!question.multiple_answer) {
            resetAnswerCheckbox()
        }
    }, [question.multiple_answer]);

    const resetAnswerCheckbox = () => {

    }

    const handleMultiSelect = (e: any) => {
        // console.log(e);
        updateQuestion({ name: "multiple_answer", value: e.target.checked })
        // handle option on answer if multiselect or not 
        if (!e.target.checked) {
            const newAnswer = answer.map((ans) => {
                // console.log(ans);
                return { ...ans, correct: false };
            });
            updateAnswer(newAnswer);
        }
    }


    const handleAnswerChange = (incomingAnswer: any) => {
        console.log("perubahan answer!")
        let updatedAnswer;

        if (question.multiple_answer) {
            updatedAnswer = answer.map((item) => {
                if (item.id === incomingAnswer.id) {
                    return incomingAnswer;
                }
                return item;
            });
        } else {
            updatedAnswer = answer.map((item) => {
                // If current item matches the incomingAnswer's ID, update the item.
                if (item.id === incomingAnswer.id) {
                    return incomingAnswer;
                }

                // If current item's correct is true and incomingAnswer's correct is also true, set current item's correct to false.
                if (item.correct && incomingAnswer.correct) {
                    return { ...item, correct: false };
                }

                // Otherwise, leave the item unchanged.
                return item;
            });
        }

        updateAnswer(updatedAnswer);
    };

    const handleAddAnswer = () => {
        if (answer.length >= maxOption) { return }
        const newAnswer = [
            ...answer,
            {
                id: getKey(15),
                correct: false,
                answer: "",
                explanation: "",
                score: 0,
            }
        ];
        updateAnswer(newAnswer);
    };

    const handleRemoveAnswer = (id: string) => {
        const newAnswer = answer.filter((ans) => ans.id !== id);
        updateAnswer(newAnswer);
    };

    return (
        <div className="question-box" datatype="Option">
            <div className="question-field form-box">
                <div className="label">Question</div>
                <MyEditor
                    className={"question-editor"}
                    content={question.question}
                    onContentChange={content => updateQuestion({ name: "question", value: content })}
                />
            </div>
            <hr />

            <div className="field">
                <div className="control">
                    <Checkbox
                        checked={question.multiple_answer}
                        onChange={handleMultiSelect}
                    > Multi Select</Checkbox>
                </div>
            </div>

            <div className="options mt-5">
                <div className="flex-group mb-4">
                    <div className="title is-6 m-0">Options</div>
                    <div className="add-option is-link" onClick={handleAddAnswer}>
                        + Add Option
                    </div>
                </div>
                {answer &&
                    answer.map((ans: any) => (
                        <OptionInput
                            key={ans.id}
                            ans={ans}
                            handleAnswerChange={handleAnswerChange}
                            handleRemoveAnswer={handleRemoveAnswer}
                            {...props}
                        />
                    ))}
            </div>
        </div>
    );
}


function OptionInput(props: {
    ans: Answer,
    handleAnswerChange: (a: any) => void,
    handleRemoveAnswer: (id: string) => void,
    type: string,
    quiz?: any

}) {
    const { ans, handleRemoveAnswer, handleAnswerChange, type, quiz } = props;
    const [toggleExplanation, setToggleExplanation] = useState(ans.explanation ? true : false)
    const [answer, setAnswer] = useState(ans)
    const [needUpdate, setNeedUpdate] = useState(false)

    // useEffect(() => {
    console.log(ans)
    //     setAnswer(ans)
    // }, [ans]);

    useEffect(() => {
        if (needUpdate) {
            handleAnswerChange(answer);
            setNeedUpdate(false)
        }
    }, [answer, needUpdate]);

    const handleChange = ({ name, value }: any) => {
        setAnswer((prev: any) => ({
            ...prev,
            [name]: value
        }))
        setNeedUpdate(true)

    };


    const handleToogle = () => {
        setToggleExplanation((prevToggle) => !prevToggle);
    };

    console.log(type)

    return (
        <div className={(type !== "task" && quiz.score_basis === 2) ? "option-answer-item with-score" : "option-answer-item"}  >
            <div className={"answer-box " + type}>
                <Checkbox checked={ans.correct}
                    onChange={(e) => handleChange({ name: "correct", value: e.target.checked })}
                    className={type === "task" ? "input-check non" : "input-check"}>is True</Checkbox>

                {type !== "task" && quiz.score_basis === 2 &&
                    <Tooltip title={"Score"}>
                        <InputNumber
                            onChange={(e) => handleChange({ name: "score", value: e })}
                            placeholder='Score' className='input-score' value={ans.score} />
                    </Tooltip>
                }
                <Input
                    placeholder='Option'
                    className='input-value'
                    value={answer.answer}
                    onChange={(e) => handleChange({ name: "answer", value: e.target.value })}
                />
                <i
                    className="fas fa-trash"
                    onClick={() => handleRemoveAnswer(ans.id)}
                ></i>
            </div>

            {
                type !== "task" &&
                <div className="explanation-box">
                    {toggleExplanation ?
                        <>
                            <div className="button-link is-danger mb-2" onClick={handleToogle}>- Explanation</div>
                            <div className="field">
                                <div className="control">
                                    <MyEditor
                                        placeholder="Explanation"
                                        content={answer.explanation}
                                        onContentChange={(value) => handleChange({ name: "explanation", value: value })}
                                    />
                                </div>
                            </div>
                        </> :
                        <div className="button-link" onClick={handleToogle}>+ Explanation</div>
                    }

                </div>
            }
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { COURSE, LOGO, PAGE, PATH } from 'consts';
import { Spin } from 'antd';
import ReviewCourse from '../course-review/ReviewCourse';
import { API } from 'api';
import { Reviewer as ReviwerType } from 'types';
import { useUserContext } from 'UserContext';
import './reviewer.css'

export default function Reviewer() {

    const { mode, reviewerId } = useParams()

    const [reviewer, setReviewer] = useState<ReviwerType | null>(null);
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const { setPage } = useUserContext()

    useEffect(() => {
        setPage(PAGE.PUBLIC_REVIEW)
    }, []);

    const handleGetReviewer = async () => {
        if (!reviewerId) return
        setLoading(true)
        const response = await API.Course.Review.GET_REVIEWER(reviewerId);
        console.log(response)
        if (response.status) {
            setReviewer(response.data.reviewer)
            setTasks(response.data.tasks)
        }
        setLoading(false)
    }

    console.log('tasks', tasks)


    useEffect(() => {
        if (mode === COURSE.ENROLL_MODE.ENROLL) {
            handleGetReviewer();
        }
    }, [reviewerId, mode]);

    const navigate = useNavigate()

    return (
        <div id="review-course">
            <div className="container">
                <div className="logo-holder" onClick={() => navigate(PATH.HOME)}>
                    <img src={LOGO} alt="" style={{ cursor: "pointer" }} />
                </div>
                <div className="review-form">
                    <Spin spinning={loading} />
                    {reviewer &&
                        <ReviewCourse
                            courseAssignedId={reviewer.course_assigned}
                            reviewerId={reviewer.id}
                            isPopup={false}
                            mode={"Public"}
                        />
                    }
                </div>
            </div>
        </div>
    )
}



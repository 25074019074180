import React, { useEffect, useState } from 'react'
import { Button, Modal, Space, Spin } from 'antd'
import { API } from 'api'
import { CURRENCY } from 'consts'
import { loadStripe } from '@stripe/stripe-js';
import { ArrowRightOutlined } from '@ant-design/icons';
import { getRandomImage } from 'functions';
import './payment.css'

interface CheckOutItem {
    id: string
    type: "course" | "classroom"
    title: string
    price: number
    image: string
}

export function CheckOutModal(props: {
    type: "course" | "classroom",
    id?: string
    close: () => void
}) {

    const { type, id, close } = props

    const [loading, setLoading] = useState(true)
    const [loadingCheckOut, setLoadingCheckOut] = useState(false)
    const [checkOutItems, setCheckOutItems] = useState<CheckOutItem[] | null>(null)


    useEffect(() => {
        const loadData = async () => {
            if (!id) return
            setLoading(true)
            if (id && type === "course") {
                await handleGetCourse(id, setCheckOutItems)
            }
            setLoading(false)
        }

        id && loadData()
    }, [id, type]);

    const handleStripeCheckout = async () => {
        setLoadingCheckOut(true)
        const data = { pk: id, type: type }
        const response = await API.Payment.CreateCheckoutSession(data)
        console.log('response', response)
        if (response.status) {
            return await handleStripeCheckoutSession(response.data)
        }
        setLoadingCheckOut(false)
    }

    console.log('check` ', checkOutItems)

    const defaultImage = getRandomImage()
    return (
        <div >
            <Modal
                title="Check Out"
                open={id !== undefined}
                onCancel={close}
                footer={null}
                className="checkout-modal"
            >

                <Spin spinning={loading} />

                {checkOutItems?.map((item, index) => (
                    <div key={index} className="checkout-item">
                        <img src={item.image || defaultImage} alt="" />
                        <div className="info">
                            <h6 >{item.title}</h6>
                            <div className="price">$ {item.price} {CURRENCY}</div>
                        </div>
                    </div>
                ))}

                <Space className='mt-5'>
                    <Button onClick={close}>Cancel</Button>
                    <Button
                        type="primary"
                        onClick={handleStripeCheckout}
                        loading={loadingCheckOut}
                        icon={!loading && <ArrowRightOutlined />}
                    >Process to Stripe

                    </Button>
                </Space>

            </Modal>
        </div>
    )
}



const handleGetCourse = async (courseId: string, setState: any) => {
    // console.log('courseId', courseId)
    const response = await API.Course.Course.GET_PUBLIC_DETAIL(courseId)
    // console.log('response', response)
    if (response.status) {
        const state = setState([
            {
                id: response.data.id,
                title: response.data.course_name,
                price: response.data.price,
                image: response.data.image
            }
        ])
        return state
    }
    return null
}


const handleStripeCheckoutSession = async (data: any) => {
    const stripePromise = loadStripe(data.STRIPE_PUBLIC_KEY)
    const stripe = await stripePromise;

    if (stripe) {
        await stripe.redirectToCheckout({
            sessionId: data.id,
        });

    }
}


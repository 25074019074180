import React from 'react'
import { Quiz } from 'types';

export default function QuizView(props: {
    data: Quiz
}) {
    const { data } = props;

    return (

        <div className="quiz-view">
            {
                data &&
                <div className="content-body">
                    <div className="title quiz-title">{data.title}</div>
                    <p className="description mb-4">{data.description}</p>
                    {data.image &&
                        <img src={data.image} alt="img" className='quiz-image' />
                    }
                </div>
            }
        </div>
    )
}


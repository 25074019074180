import { Term } from 'App';
import { Button, Popconfirm, message } from 'antd'
import { API } from 'api';
import { Field } from 'components/ui/Field';
import React, { useEffect, useState } from 'react'
import { Classroom } from 'types';

export default function ClassroomPreferences(props: {
    classroomId: string
}) {
    const { classroomId } = props;
    const [classroom, setClassroom] = useState<Classroom | null>(null)
    const [loading, setLoading] = useState(false)

    const handleUpdate = ({ name, value }: any) => {
        setClassroom((prev: Classroom) => ({
            ...prev, [name]: value
        }));
    };

    const savePreferences = async () => {
        setLoading(true)
        const response = await API.Classroom.Classroom.UPDATE(classroom, classroom.id)

        console.log(response)
        if (response.status) {
            message.success("Save Successfully!")
        }
        setLoading(false)
    }


    const deleteClassroom = async () => {
        setLoading(true)
        const response = await API.Classroom.Classroom.DELETE(classroom.id)

        console.log(response)

        setLoading(false)
        if (response.status) {
            window.location.href = "/profile?tab=authored-subjects"
        }
    }


    const handleGetClassroom = async () => {
        if (!classroomId) return
        const response = await API.Classroom.Classroom.GET(classroomId)
        console.log(response)
        if (response.status) {
            setClassroom(response.data)
        }
    }

    useEffect(() => {
        handleGetClassroom()
    }, [classroomId]);

    return (
        <div className="classroom-preferences">
            <div className="title is-5">Preferences</div>
            {classroom &&
                <div className="form">
                    <Field
                        object={classroom}
                        handleUpdate={handleUpdate}
                        name="classname"
                        label="Title"
                        type="char"
                        tip="Give your classroom an awesome title."
                    />

                    <Field
                        object={classroom}
                        handleUpdate={handleUpdate}
                        name="description"
                        label="Description"
                        type="textEditor"
                        tip="Tell more about your classroom."
                    />

                    <Field
                        object={classroom}
                        handleUpdate={handleUpdate}
                        name="image"
                        label="Image"
                        type="imageUpload"
                        tip="Upload a cover image for your classroom."

                    />

                    <Field
                        object={classroom}
                        handleUpdate={handleUpdate}
                        name="level"
                        label="Level"
                        type="select"
                        options={[
                            { value: "Beginner", label: "Beginner" },
                            { value: "Intermediate", label: "Intermediate" },
                            { value: "Advance", label: "Advance" }
                        ]}
                        tip="Select your classroom level."
                    />

                    <Field
                        object={classroom}
                        handleUpdate={handleUpdate}
                        name="price"
                        label="Price"
                        type="number"
                        tip="Set classroom price (all price in USD)"
                    />

                    <Field
                        object={classroom}
                        handleUpdate={handleUpdate}
                        name="status"
                        label="Status"
                        type="radio"
                        options={[
                            { value: "Draft", label: "Draft" },
                            { value: "Publish", label: "Publish" },
                            { value: "Private", label: "Private" }
                        ]}
                        tip={getStatusTip(classroom.status)}
                    />

                    <div className="button-group flex-group mt-5">
                        <Button onClick={savePreferences} type='primary'>Save</Button>
                        <Popconfirm
                            placement="topRight"
                            title={"Delete " + Term.classroom}
                            description={`Are you sure to delete ${classroom.classname} ${Term.classroom} ?`}
                            onConfirm={deleteClassroom}
                            okText="Yes"
                            cancelText="No"
                            okButtonProps={{ loading: loading }}
                        >
                            <Button className='button is-danger'>Delete</Button>
                        </Popconfirm>
                    </div>
                </div>
            }
        </div>
    )
}


const getStatusTip = (status: string) => {
    switch (status) {
        case "Publish":
            return `Everyone see the classroom, it also allow for search. 
                Please make sure the classroom is ready before published!`;
        case "Private":
            return "Only author and invited students can see the classroom.";
        default:
            return "Only author can see the classroom.";
    }
}
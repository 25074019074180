import React from 'react'
import "./course-enroll.css"
import Footer from 'components/footer/Footer'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'

const image = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/404.gif"
export default function EnrollFailed(props: {
    message: string
}) {

    const { message } = props
    const navigate = useNavigate()

    return (
        <>
            <div className='enroll-failed'>
                <img src={image} alt="" />
                <p>{message}</p>
                <Button type='primary' className='mt-6' onClick={() => navigate("/")}>Back to Home</Button>
            </div>
            <Footer />
        </>
    )
}

// @ts-nocheck
import React, { useEffect, useState } from 'react'
import CourseCard from '../course/CourseCard'


import "./home.css"
import { GetHomeData } from 'api'
import { Course } from 'types'
import { useUserContext } from 'UserContext'
import { PAGE } from 'consts'
import Footer from 'components/footer/Footer'

export default function Home() {

    const [courses, setCourses] = useState<Course[]>([])
    const { setPage } = useUserContext()

    useEffect(() => {
        setPage(PAGE.HOME)
    }, [setPage]);

    const handleGetHomeData = async () => {
        try {
            const response = await GetHomeData()
            console.log('response', response)
            if (response.results) {
                setCourses(response.results)
            }

        } catch (error) {
            throw new Error(error as unknown as string)
        }

    }
    useEffect(() => {
        handleGetHomeData()
    }, [])


    return (
        <div className='home'>
            <div className="container p-5 course-list-container">
                <h1 className='title is-4'>Course</h1>
                <div className="course-list">
                    {courses ? courses.map(item => <CourseCard course={item} key={item.id} />) : ""}
                </div>
            </div>
            <Footer />
        </div>

    )
}


import React, { useEffect, useState } from 'react'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Space, Table, Tag, message } from 'antd';
import { Term } from 'App';
import { getRandomInt } from 'functions';
import Program from './classroom-program/Program';
import { TERM } from 'consts';
import { API } from 'api';
import { Classroom, Program as ProgramType } from 'types';

export default function ClassroomPrograms(props: {
    classroom: Classroom
}) {
    const { classroom } = props;

    const [displayForm, setDisplayForm] = useState<boolean>(false)
    const [programs, setPrograms] = useState<ProgramType[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [display, setDisplay] = useState<string | null>(null)
    const [program, setProgram] = useState<ProgramType | null>(null)

    const deleteProgram = async (programId: string) => {
        setLoading(true)
        const response = await API.Classroom.Program.DELETE(programId)
        console.log(response)
        setLoading(false)
        if (response.status) {
            setPrograms((prev: any) => prev.filter((program: ProgramType) => {
                return program.id !== programId
            }));
            message.success(Term.program + " has deleted!")
        }
    }

    const handleUpdatePrograms = (newPrograms: ProgramType[]) => {
        setPrograms(newPrograms)
    }

    useEffect(() => {
        setDisplay("table")
    }, [])

    const handleGetAllPrograms = async () => {
        const response = await API.Classroom.Program.GET_ALL(classroom.id)
        if (response.status) {
            setPrograms(response.data)
        }
    }

    useEffect(() => {
        handleGetAllPrograms()
    }, []);


    return (
        <div className="classroom-modules classroom-programs">
            {displayForm &&
                <FormProgram
                    classroom={classroom}
                    program={program}
                    close={() => {
                        setDisplayForm(false)
                        setProgram(null)
                    }}
                    reload={() => {
                        handleGetAllPrograms()
                    }}
                />
            }

            {display === "table" &&
                <ProgramTable
                    programs={programs}
                    deleteProgram={deleteProgram}
                    handleUpdatePrograms={handleUpdatePrograms}
                    setDisplayForm={setDisplayForm}
                    setDisplay={setDisplay}
                    loading={loading}
                    setProgram={setProgram}
                />
            }

            {
                display === "program" &&
                <Program
                    programId={program.id}
                    setDisplay={setDisplay}
                    key="program" />
            }

        </div>
    )
}




const FormProgram = (props: {
    classroom: Classroom,
    program: ProgramType | null,
    close: () => void,
    reload: () => void,
}) => {

    const initial = {
        title: "New " + TERM.PROGRAM,
        description: ""
    }
    const { program: propsProgram, close, reload, classroom } = props;

    const [program, setProgram] = useState(propsProgram ? propsProgram : initial)

    const handleUpdate = ({ name, value }: any) => {
        setProgram((prev: any) => ({
            ...prev, [name]: value
        }));
    }

    const saveProgram = async () => {
        const response = program.id ? await API.Classroom.Program.UPDATE(program, program.id) : await API.Classroom.Program.CREATE(program, classroom.id)

        console.log(response)
        if (response.status) {
            reload()
            close()
        }
    }

    return (
        <div className="form-modal add-program">
            <div className="modal-box">
                <div className="modal-header flex-group px-5 py-3" >
                    <div className="title m-0 is-5">{Term.program}</div>
                    <div className="delete" onClick={close}></div>
                </div>

                <div className="modal-body p-5">
                    <div className="fields">
                        <div className="field">
                            <div className="control">
                                <div className="label">{Term.program} Name</div>
                                <Input value={program.title}
                                    name='title'
                                    onChange={e => handleUpdate({ name: e.target.name, value: e.target.value })} />
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <div className="label">Description</div>
                                <Input.TextArea
                                    name='description'
                                    value={program.description}
                                    onChange={e => handleUpdate({ name: e.target.name, value: e.target.value })} />
                            </div>
                        </div>
                    </div>
                    <div className="button-group mt-5">
                        <Button className='mr-3' onClick={close}>Cancel</Button>
                        <Button type='primary' onClick={saveProgram}>Save</Button>

                    </div>
                </div>
            </div>
            <div className="overlay"></div>
        </div>
    )
}


const ProgramTable = (props: {
    programs: ProgramType[],
    deleteProgram: (programId: string) => void,
    handleUpdatePrograms: (newPrograms: ProgramType[]) => void,
    setDisplayForm: (value: boolean) => void,
    setDisplay: (value: string | null) => void,
    loading: boolean,
    setProgram: (program: ProgramType | null) => void,
}) => {
    const {
        programs,
        deleteProgram,
        setDisplayForm,
        loading,
        setProgram,
        setDisplay
    } = props;

    const handleRowClick = (record: ProgramType) => {
        console.log('record', record)
    }

    const handleEdit = (record: ProgramType) => {
        setDisplay("program")
        setProgram(record)
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, record: ProgramType) => {
                const imageUrl = record.avatar;
                console.log(name, record)

                return (
                    <Space onClick={() => handleEdit(record)} style={{ cursor: "pointer" }}>
                        <img src={imageUrl} className={'member-avatar'} alt="course"
                            style={{ width: 40, height: 40, marginRight: 10 }} />
                        <div>
                            <p>{name}</p>
                            <div className="tip">{record.description}</div>
                        </div>
                    </Space>


                );
            },
            sorter: (a: ProgramType, b: ProgramType) => a.name.localeCompare(b.name),
            className: "has-avatar"

        },
        {
            title: "Courses",
            dataIndex: 'courses',
            key: 'courses',
            sorter: (a: ProgramType, b: ProgramType) => a.courses - b.courses,
        },
        {
            title: 'Assignees',
            dataIndex: 'assignees',
            key: 'assignees',
            sorter: (a: ProgramType, b: ProgramType) => a.assignees - b.assignees,

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a: ProgramType, b: ProgramType) => a.status.localeCompare(b.status),
            render: (status: string) => (
                <Tag className={'ant-tag ' + status}>
                    {status}
                </Tag>
            )

        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            className: "action",
            width: "110px",
            render: (record: ProgramType) => (
                <>
                    <Button icon={<EditOutlined />} className='mr-1'
                        onClick={() => handleEdit(record)}></Button>
                    <Popconfirm
                        placement="bottomRight"
                        title={"Delete " + Term.program}
                        description={`Are you sure to delete this ${Term.program}?`}
                        onConfirm={() => deleteProgram(record.id)}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ loading: loading }}
                    >
                        <Button icon={<DeleteOutlined />}></Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    const DataTable = () => {
        const newData = programs.map((program: any) => ({
            ...program,
            name: program.program_name,
            courses: program.courses.length,
            assignees: program.assignees.length,
            avatar: `https://source.unsplash.com/random/sig=${getRandomInt(1, 100)}?abstract`,

        }));

        console.log("new data : ", newData);
        return (
            <Table key={"classroom-programs-table"}
                className={"is-table"}
                columns={columns}
                dataSource={newData}
                loading={loading}
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })} />

        );
    };


    return (

        <div className="p-5">
            <div className="header flex-group">
                <div className="title is-5 mb-3">{TERM.PROGRAM}s</div>
                <Button type='primary' onClick={() => setDisplayForm(true)}>+ Add</Button>
            </div>
            <DataTable key="classroom-programs-table-data" />
        </div>

    )
}
import React from 'react'

import "./auth.css"
import { Link } from 'react-router-dom';
import { IMAGE } from 'consts';

export default function RegistrationSuccess() {

    return (
        <div className="registration-success">
            <div className="group">
                <Link to={"/"}>
                    <img src={IMAGE.LOGO} alt="" className="logo" />
                </Link>
                <img src="https://curiobites-media.s3.ap-southeast-1.amazonaws.com/media/registration_success.gif" alt="" />

                <div className="title">Thanks for joining!</div>
                <p className='mb-3'>Please check your email for activation</p>
            </div>
        </div>


    )
}



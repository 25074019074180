import { HOST } from "consts"
import { sendPublicRequest, sendRequest } from "./Main"

export const GetHomeData = async () => {
    const endpoint = HOST + "/api/GetHomeData/"
    const method = "GET"
    return await sendPublicRequest(endpoint, method)
}

export const DeleteUpload = async (app: string, model: string, objectId: string, field: string) => {
    const method = "DELETE"
    const endpoint = HOST + `/api/Upload/?app=${app}&model=${model}&pk=${objectId}&field=${field}`
    return await sendRequest(endpoint, method)
}


// ---------------------------- TAGS ----------------------------

export namespace TagAPI {
    export const GET_ALL = async () => {
        const endpoint = HOST + "/api/Tag/"
        const method = "GET"
        return await sendRequest(endpoint, method)
    }

    export const GET = async (id: string) => {
        const endpoint = HOST + `/api/Tag/?pk=${id}`
        const method = "GET"
        return await sendRequest(endpoint, method)
    }

    export const CREATE = async (name: string) => {
        const endpoint = HOST + "/api/Tag/"
        const method = "POST"
        const body = JSON.stringify({ name: name })
        return await sendRequest(endpoint, method, body)
    }

    export const DELETE = async (id: string) => {
        const endpoint = HOST + `/api/Tag/?pk=${id}`
        const method = "DELETE"
        return await sendRequest(endpoint, method)
    }

    export const UPDATE = async (id: string, data: any) => {
        const endpoint = HOST + `/api/Tag/?pk=${id}`
        const method = "PUT"
        const body = JSON.stringify(data)
        return await sendRequest(endpoint, method, body)
    }

}

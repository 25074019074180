import { Button, Input, Rate } from 'antd';
import React, { useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import { API } from 'api';

export default function EndCourseView(props: {
    data: any
    mode: string
}) {
    const { mode } = props;

    return (
        <div className="end-course">
            <div className="content-body">
                {mode === "preview" ? <EndCoursePreview /> : <EndCourseEnroll data={props} mode={mode} />}
            </div>
        </div>
    )
}


const EndCoursePreview = () => {

    const [params] = useSearchParams()
    const courseId = params.get("c")
    const editUrl = "/course/edit/?c=" + courseId

    return (
        <>
            <h2 className="title is-5">End of preview</h2>
            <Link to={editUrl}>
                <Button type='primary'>Back to edit course</Button>
            </Link>
        </>
    )
}

const initialReview = {
    rate: 0,
    comment: ""
}

const EndCourseEnroll = (props: {
    data: any
    mode: string

}) => {
    const { data, mode } = props;
    const navigate = useNavigate()
    const [review, setReview] = useState(initialReview)
    const customIcons = {
        1: <FrownOutlined />,
        2: <FrownOutlined />,
        3: <MehOutlined />,
        4: <SmileOutlined />,
        5: <SmileOutlined />,
    };

    // console.log(props)

    const updateReview = ({ name, value }: any) => {
        console.log(name, value);
        setReview(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // const reviewLater = () => {
    //     window.location.href = "/profile"
    // }

    // const saveReview = async () => {
    //     courseComplete()
    // }

    const courseComplete = async (withReview = 0) => {
        if (withReview === 0) {
            return navigate("/profile?tab=personal-courses")
        }

        const updatedData = { ...data, review: review, session: data.session };
        const response = await API.Course.Complete.USER_COURSE_COMPLETE(updatedData, mode, withReview)
        console.log(response);
        if (response.status) {
            if (mode === "public") {
                return navigate("/")
            }
            return navigate("/profile?tab=personal-courses")
        }
        return null
    }

    return (
        <div className="endcourse">
            <p className="title is-2 mb-2">Please leave a review</p>
            <p>Your feedback is critical to our desire to make Curiobites better!</p>
            <Rate
                value={review.rate}
                onChange={e => updateReview({ name: "rate", value: e })}
                character={({ index }: { index?: number }) => (customIcons as any)[index! + 1]}
                style={{ scale: "2", margin: "20px auto 30px" }}
            />
            <Input.TextArea value={review.comment} onChange={e => updateReview({ name: "comment", value: e.target.value })} />

            <div className="button-group mt-4">
                <Button onClick={() => courseComplete(0)} className='mr-3'>I'll review later</Button>
                <Button onClick={() => courseComplete(1)} type='primary'>Save Review</Button>

            </div>
        </div>
    )
}
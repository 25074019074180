import React, { useEffect, useState } from "react"
import { CoursePreferencesFieldProps } from "./CoursePreferences";
import { AppIntegration, AppRole, Course, IntegrationAppType } from "types";
import { GetIntegration } from "api";
import { Checkbox, Input, Select } from "antd";

export const IntegrationField = (props: CoursePreferencesFieldProps) => {
    const { course, handleInputChange } = props;
    const [apps, setApps] = useState<IntegrationAppType[]>([])

    const handleGetApps = async () => {
        const response = await GetIntegration()
        console.log(response)
        if (response.status) {
            setApps(response.data)
        }
    }

    const handleAppChange = (value: any) => {
        console.log('e', value)
        handleInputChange({ name: "integration_applications", value: value })
    }

    useEffect(() => {
        handleGetApps()

    }, []);



    const metaOPT = apps.find((app: IntegrationAppType) => app.name === "MetaOPT");

    // Periksa apakah ada ID MetaOPT dalam course.integration_applications
    // const isMetaOPTIntegrated = course.integration_applications.includes(metaOPT?.id || "");

    // disable karena akan menggunakan metode sebaliknya. course ID akan di input dari model
    const isMetaOPTIntegrated = false;

    return (
        <div className="field">
            <div className="control">
                <div className="label">Integration Access</div>
                <Checkbox
                    checked={course.integration_access}
                    onChange={e => handleInputChange({ name: "integration_access", value: e.target.checked })}>
                    Enable Integration access
                </Checkbox>
                <div className="tip">Share this course to integration application.</div>
            </div>

            {course.integration_access &&
                <div className="integration-app">
                    <div className="control my-5">
                        <div className="label">Application integration</div>
                        <Select
                            mode="multiple"
                            value={course?.integration_applications}
                            onChange={handleAppChange}
                            style={{ width: '100%' }}
                        >
                            {apps.map((item: AppIntegration) => (
                                <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                            ))}
                        </Select>

                        {isMetaOPTIntegrated && metaOPT &&
                            < MetaOPTSettings
                                course={course}
                                handleInputChange={handleInputChange}
                                app={metaOPT}
                            />
                        }

                    </div>

                    <div className="control">
                        <Checkbox
                            checked={course.allow_partner_brand}
                            onChange={e => handleInputChange({ name: "allow_partner_brand", value: e.target.checked })}>
                            Allow to use partner brand.
                        </Checkbox>
                        <div className="tip">Allow partner to use their brand instead this course brand on partner website.</div>
                    </div>


                </div>
            }

        </div>
    )

}


const MetaOPTSettings = (props: {
    course: Course,
    handleInputChange: (args: any) => void,
    app: IntegrationAppType

}) => {
    const { course, handleInputChange, app } = props;
    const [accessibleRoles, setAccessibleRoles] = useState<string[]>([]);

    useEffect(() => {
        setAccessibleRoles(course.accessible_roles ? course.accessible_roles : [])
    }, []);

    const handleInputModelChange = (event: any) => {
        const { name, value } = event.target;

        // Split the input string into an array
        const valueArray = value.split(',').map((item: any) => item.trim());

        // Update the formData with the new array
        handleInputChange({ name: name, value: valueArray });

    }

    const handleRoleRestriction = (check: boolean, role: string) => {
        setAccessibleRoles((prev: any) => {
            if (check) {
                // If the checkbox is checked, add the role to selectedRoles
                const updatedRoles = [...prev, role];
                // Call a function with the updated roles
                handleInputChange({ name: "accessible_roles", value: updatedRoles });
                return updatedRoles;
            } else {
                // If the checkbox is unchecked, remove the role from selectedRoles
                const updatedRoles = prev.filter((id: string) => id !== role);
                // Call a function with the updated roles
                handleInputChange({ name: "accessible_roles", value: updatedRoles });
                return updatedRoles;
            }
        });
    }

    return (
        <div>
            <div className="role-restriction mt-5">
                <div className="label">Role Access Restriction</div>
                {app.roles.map((item: AppRole) => (
                    <Checkbox
                        key={item.id}
                        checked={accessibleRoles.includes(item.name)}
                        onChange={(e) => handleRoleRestriction(e.target.checked, item.name)}
                    >
                        {item.name}
                    </Checkbox>
                ))}
                <div className="tip">
                    Display course for selection role.
                </div>
            </div>

            <div className="model-id mt-5">
                <div className="label">Model ID (optional)</div>
                <Input
                    value={course.metaOPT_model_id?.join(', ')}
                    name='metaOPT_model_id'
                    onChange={handleInputModelChange}
                />
                <div className="tip">
                    Enter Model ID's if this course related to a model separated by comma.
                </div>
            </div>
        </div>
    )
}
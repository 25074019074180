import React, { useEffect, useState } from 'react'
import { Button, Empty, Popconfirm } from 'antd';
import { Term } from 'App';
import { createRoot } from 'react-dom/client';
import EditTaskQuestion from 'components/coursebuilder/contents/EditTaskQuestion';
import QuestionListItem from 'components/coursebuilder/contents/QuestionListItem';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { getDragContentStyle, getKey, getRandomColor, reorderData } from 'functions';
import { Classroom, Question, Quiz } from 'types';
import { API } from 'api';
import { Field } from 'components/ui/Field';
import { FORMAT } from 'consts';


export default function TaskQuiz(props: {
    classroom: Classroom,
    taskQuizId?: string,
    handleDisplay: (display: string) => void,
    getTasks: () => void

}) {
    const { classroom, taskQuizId, handleDisplay, getTasks } = props;

    console.log(classroom, taskQuizId)

    const [taskQuiz, setTaskQuiz] = useState<Quiz>(null)
    const [loading, setLoading] = useState(false)
    const [questionList, setQuestionList] = useState([])

    const handleBack = () => {
        getTasks()
        handleDisplay("table")
    }

    const getTaskQuiz = async () => {
        if (!taskQuizId) return
        setLoading(true)
        const response = await API.Classroom.TaskQuiz.GET(taskQuizId)

        console.log(response)
        if (response.status) {
            setTaskQuiz(response.data)
            setQuestionList(response.data.questions)
        }
        setLoading(false)
    }

    const createTaskQuiz = async () => {
        const response = await API.Classroom.TaskQuiz.CREATE(classroom.id)

        console.log(response)
        if (response.status) {
            setTaskQuiz(response.data)
        }

    }

    const handleDeleteTaskQuiz = async () => {
        if (!taskQuiz) return
        const response = await API.Classroom.TaskQuiz.DELETE(taskQuiz.id)
        if (response.status) {
            getTasks()
            handleDisplay('table')
        }
    }

    const addQuestion = () => {
        const modal = document.getElementById('app-modal');
        if (!modal) return
        const root = createRoot(modal);
        root.render(
            <EditTaskQuestion
                root={root}
                task={taskQuiz}
                getTask={getTaskQuiz}
            />)
    }


    useEffect(() => {
        if (taskQuizId) {
            getTaskQuiz()
        } else {
            createTaskQuiz()
        }
    }, [taskQuizId])

    const handleUpdate = ({ name, value }: any) => {
        setTaskQuiz((prev: any) => ({
            ...prev, [name]: value
        }));
    };

    const editQuestion = async (questionId: string) => {
        const modal = document.getElementById('app-modal');
        if (!modal) return
        const root = createRoot(modal);
        root.render(
            <EditTaskQuestion
                key={'question-task' + taskQuiz.id}
                root={root} task={taskQuiz}
                getTask={getTaskQuiz}
                questionId={questionId}
            />
        )
    }

    const deleteQuestion = async (taskQuestionId: string) => {
        const response = await API.Classroom.TaskQuestion.DELETE(taskQuestionId)
        console.log('response', response)

        if (response.status) {
            setQuestionList(prevList => {
                return prevList.filter((question: Question) => question.id !== taskQuestionId);
            });
        }
    }


    const draggingColor = getRandomColor()
    const questionListItems = questionList && questionList.length ? questionList.map((item: any, index: number) => (
        <Draggable draggableId={"drag-quiz-question-" + index} index={index} key={item.id}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getDragContentStyle(
                        draggingColor,
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}
                    className='question-list-item'>
                    <QuestionListItem
                        key={item.id}
                        question={item}
                        deleteQuestion={deleteQuestion}
                        editQuestion={editQuestion} />
                </div>
            )}
        </Draggable>
    )) :

        <Empty description="You don't have any question yet." />


    const handleDragEnd = (result: any) => {
        if (!result.destination) return; // if dropped outside the list
        const itemsCopy = Array.from(questionList);
        const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
        itemsCopy.splice(result.destination.index, 0, reorderedItem);
        const cek = reorderData(itemsCopy, "question", taskQuiz.id)
        console.log(cek);
        setQuestionList(itemsCopy);
    };

    console.log(questionList)

    return (
        <div className="task-quiz">
            {taskQuiz &&
                <>
                    <div className="header px-3">
                        <div className="flex-group w-100">
                            <div className="flex-group">
                                <div className="back mr-2" onClick={handleBack}>
                                    <i className="fa-solid fa-arrow-left"></i>
                                </div>
                                <div className="title is-6">
                                    {taskQuiz && taskQuiz.title}
                                </div>
                            </div>
                            <Popconfirm
                                placement="bottomRight"
                                title={"Delete " + Term.task}
                                description={`Are you sure to delete ${taskQuiz && taskQuiz.title} ${Term.task} ?`}
                                onConfirm={handleDeleteTaskQuiz}
                                okText="Yes"
                                cancelText="No"
                                okButtonProps={{ loading: loading }}
                            >

                                <Button className='is-danger'>Delete</Button>

                            </Popconfirm>
                        </div>
                    </div>

                    <div className="body">
                        <div className="question-box">
                            <div className="flex-group mb-4">
                                <p className="title is-5 m-0">Questions</p>
                                <Button type='primary' onClick={addQuestion}>+ Add Question</Button>
                            </div>

                            <div className="question-panel">
                                <DragDropContext onDragEnd={handleDragEnd} key={getKey()}>
                                    <Droppable
                                        droppableId={"questions-" + taskQuiz.id}
                                        key={taskQuiz.id} >
                                        {(provided) => (
                                            <div className="question-list" ref={provided.innerRef}>
                                                {questionListItems}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>

                        </div>
                        <div className="setting-panel p-4">
                            <p className='mb-4'>
                                <strong >Setting</strong>
                            </p>

                            <Field
                                name='title'
                                object={taskQuiz}
                                handleUpdate={handleUpdate}
                                type="char"
                                label='Title'
                            />

                            <Field
                                name='description'
                                object={taskQuiz}
                                handleUpdate={handleUpdate}
                                type="textarea"
                                label='Description'
                            />

                            <Field
                                name='"due_type'
                                object={taskQuiz}
                                handleUpdate={handleUpdate}
                                type="radio"
                                options={[
                                    { value: "Day", label: "Day" },
                                    { value: "Date", label: "Date" }
                                ]}
                                label='Due Type'
                            />


                            {taskQuiz.due_type === "Day" ?
                                <Field
                                    name='due_days'
                                    object={taskQuiz}
                                    handleUpdate={handleUpdate}
                                    type="number"
                                    label='Due Days'
                                />

                                :

                                <Field
                                    name='due_date'
                                    object={taskQuiz}
                                    handleUpdate={handleUpdate}
                                    type="date"
                                    label='Due Date'
                                    dateFormat={FORMAT.DATE}
                                />

                            }
                        </div>
                    </div>
                </>

            }
        </div>
    )
}



import Footer from 'components/footer/Footer'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import "./payment.css"
import { Spin } from 'antd'
import { API } from 'api'


export function SuccessPayment() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [loading, setLoading] = useState(true)
    const [isPaymentComplete, setIsPaymentComplete] = useState(false)
    const [timeLeft, setTimeLeft] = useState(6);
    const { paymentToken } = useParams()
    const type = searchParams.get("type")
    const pk = searchParams.get("pk")
    const next = type === "Course" ? `/course/enroll/?c=${pk}` : "/"

    const handleCheckPaymentComplete = async () => {
        if (!paymentToken) return
        setLoading(true)
        const response = await API.Payment.PaymentSuccessConfirmation(paymentToken)
        console.log('response', response)
        if (response.status) {
            setIsPaymentComplete(response.data.is_payment_complete)
        }
        setLoading(false)
    }

    useEffect(() => {
        // Jika timeLeft sudah 0, navigasikan user
        if (!isPaymentComplete) return

        if (timeLeft === 0) {
            navigate(next || "/");
            return;
        }

        // Kurangi timeLeft setiap detik
        const timer = setTimeout(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // Cleanup function
        return () => clearTimeout(timer);
    }, [timeLeft, navigate, isPaymentComplete]);

    useEffect(() => {
        handleCheckPaymentComplete()
    }, [paymentToken])

    return (
        <>
            <div className="success-payment">
                <Spin spinning={loading} size="large" />
                {!isPaymentComplete &&
                    <div className="container">
                        <div className='mb-5'>
                            <svg
                                className="checkmark"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                        </div>
                        <h6>Thank you for purchase our {type}</h6>
                        <p>You will redirect in {timeLeft}...</p>
                    </div>
                }
            </div>
            <Footer />
        </>
    )
}


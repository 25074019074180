import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import Content from './Content';
import EditModule from './contents/EditModule';
import EditLesson from './contents/EditLesson';
import EditQuiz from './contents/EditQuiz';
import EditTask from './contents/EditTask';
import { Module } from 'types';
import { AddModuleContent } from 'api';
import { COURSE } from 'consts';
import { getDragContentStyle, getRandomColor, reorderData } from 'functions';
import { Empty } from 'antd';

export default function ModuleCard(props: {
   provided: any,
   module: Module,
   setContentDisplay: React.Dispatch<React.SetStateAction<JSX.Element>>,
   reload: () => void,
   handleImport: (moduleId: string) => void
}) {

   const { provided, module, setContentDisplay, reload, handleImport } = props;
   const [moduleContents, setModuleContents] = useState([]);

   useEffect(() => {
      setModuleContents(module.contents)
   }, [module])

   const handleDragEnd = (result: any) => {
      if (!result.destination) return; // if dropped outside the list
      const itemsCopy = Array.from(moduleContents);
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
      itemsCopy.splice(result.destination.index, 0, reorderedItem);
      reorderData(itemsCopy, "module_contents", module.id);
      setModuleContents(itemsCopy);
   };

   const editModule = async () => {
      console.log(module);
      setContentDisplay(<EditModule key={"edit" + module.id} moduleId={module.id} {...props} />)
   };

   const addContent = async (contentType: COURSE.CONTENT_TYPE) => {
      const { status, data } = await AddModuleContent({ moduleId: module.id }, contentType, module.id)

      console.log(data);
      if (status) {
         reload()
         if (contentType === COURSE.CONTENT_TYPE.LESSON) {
            setContentDisplay(
               <EditLesson
                  module={module}
                  lesson={data}
                  setContentDisplay={setContentDisplay}
                  reload={reload}
               />
            )
         } else if (contentType === COURSE.CONTENT_TYPE.QUIZ) {
            setContentDisplay(
               <EditQuiz
                  module={module}
                  quizId={data.id}
                  reload={reload}
               />
            )
         } else if (contentType === COURSE.CONTENT_TYPE.TASK) {
            setContentDisplay(
               <EditTask
                  module={module}
                  taskId={data.id}
                  reload={reload}
               />
            )
         }
      }
   }

   let draggingColor = getRandomColor()
   const moduleContentsList = moduleContents ? moduleContents.map((item: any, index: number) => (
      <Draggable draggableId={"drag-" + item.id} index={index} key={item.id}>
         {(provided, snapshot) => (
            <div ref={provided.innerRef}
               {...provided.draggableProps}
               {...provided.dragHandleProps}
               style={getDragContentStyle(
                  draggingColor,
                  snapshot.isDragging,
                  provided.draggableProps.style
               )}
               className={"content content-item m-0"} >
               <Content
                  module={module}
                  content={item}
                  key={item.id}
                  setContentDisplay={setContentDisplay}
                  reload={reload} />
            </div>
         )}
      </Draggable>
   )) : null;




   return (
      <>
         <div className="header" {...provided.dragHandleProps}>
            {module.title}
            <div className="option" onClick={editModule}>
               <i className="fa-solid fa-pen"></i>
            </div>
         </div>
         <div className="body">
            <DragDropContext onDragEnd={handleDragEnd}>
               <Droppable droppableId={module.id} key={"dropable-" + module.id}>
                  {(provided) => (
                     <div ref={provided.innerRef} >
                        {moduleContentsList}
                        {provided.placeholder}
                     </div>
                  )}
               </Droppable>
            </DragDropContext>
            {moduleContentsList?.length === 0 &&
               <Empty description="No content" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
         </div>
         <div className="footer">
            <div className="button is-outlined" onClick={() => addContent(COURSE.CONTENT_TYPE.LESSON)}>+ Add Lesson</div>
            <div className="button is-outlined" onClick={() => addContent(COURSE.CONTENT_TYPE.QUIZ)}>+ Add Quiz</div>
            <div className="button is-outlined" onClick={() => addContent(COURSE.CONTENT_TYPE.TASK)}>+ Add Task</div>
            <div className="button is-outlined import" onClick={() => handleImport(module.id)}><i className="las la-file-import"></i> Import</div>
         </div>
      </>

   )

}


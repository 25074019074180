import { COURSE, HOST } from "consts";
import { sendCustomHeadersRequest, sendPublicRequest, sendRequest } from "./Main";
import { getToken } from "functions"
import cookie from "react-cookies"

// COURSE ENROLL / PREVIEW API's
// get all the course contents 

export namespace CourseAPI {

    export namespace Course {

        export const GET_PUBLIC_DETAIL = async (courseId: string) => {
            const endpoint = HOST + "/api/Course/Public/Detail/?pk=" + courseId
            const method = "GET"
            const token = getToken()
            let headers: any = {}

            if (token) {
                headers = {
                    ...headers,
                    "Authorization": "Bearer " + token
                }
            }
            return await sendCustomHeadersRequest(endpoint, method, headers)
        }

        export const CREATE = async (data: any) => {
            const endpoint = HOST + "/api/Course/"
            const method = "POST"

            return await sendRequest(endpoint, method, data)
        }


        export const GET_DETAIL = async (courseId: string) => {
            const endpoint = HOST + "/api/Course/GetDetailInfo/?pk=" + courseId
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

        export const GET_COURSE_ASSIGNED = async (assignmentId: string) => {
            const endpoint = HOST + "/api/Course/CourseAssigned/?pk=" + assignmentId
            const method = "GET"
            return await sendRequest(endpoint, method)
        }



    }

    export namespace Enroll {
        export const GET_CONTENT = async (data: any, mode?: COURSE.ENROLL_MODE) => {
            const endpoint = `${HOST}/api/Course/r/${mode}/?mode=${mode}`
            const method = "POST"

            // console.log('sini', mode)
            let headers: any = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }

            if (mode === COURSE.ENROLL_MODE.PUBLIC) {
                headers = {
                    'Content-Type': 'application/json',
                }
            }

            // console.log('headers', headers)

            return await sendCustomHeadersRequest(endpoint, method, headers, data)
        }

        export const ENROLL = async (mode: string, courseId?: string, url_hash?: string, public_user?: string) => {
            const endpoint = `${HOST}/api/Course/r/${mode}/?pk=${courseId}&url_hash=${url_hash}&public_user=${public_user}`
            const method = "GET";
            let headers: any = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }

            if (mode === COURSE.ENROLL_MODE.PUBLIC) {
                headers = {
                    'Content-Type': 'application/json',
                }
            }

            return await sendCustomHeadersRequest(endpoint, method, headers, null)
        }
    }

    //---------------------------------- Course Task --------------------------------------

    export namespace Task {
        export const GET = async (taskId: string) => {
            const endpoint = HOST + "/api/Course/Task/?pk=" + taskId
            const method = "GET"
            const { data } = await sendRequest(endpoint, method)
            return data
        }

        export const UPDATE = async (data: any, taskId: string) => {
            const endpoint = HOST + "/api/Course/Task/?pk=" + taskId
            const method = "PUT"
            const body = JSON.stringify(data)
            return await sendRequest(endpoint, method, body)
        }
    }


    //*
    //* Course and Classroom Question API's
    //*
    export namespace Question {
        export const GET = async (param: {
            context: "Course" | "Classroom", type: "Task" | "Quiz", questionId: string
        }) => {
            const { context, type, questionId } = param
            const endpoint = HOST + `/api/${context}/${type}/Question/?pk=` + questionId
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

        export const DELETE = async (param: {
            context: "Course" | "Classroom", type: "Task" | "Quiz", questionId: string
        }) => {
            const { context, type, questionId } = param
            const endpoint = HOST + `/api/${context}/${type}/Question/?pk=` + questionId
            const method = "DELETE"
            return await sendRequest(endpoint, method)
        }

        export const UPDATE = async (param: {
            context: "Course" | "Classroom", type: "Task" | "Quiz", questionId: string, data: any
        }) => {
            const { context, type, questionId, data } = param
            const endpoint = HOST + `/api/${context}/${type}/Question/?pk=` + questionId
            const method = "PUT"
            const body = JSON.stringify(data)
            return await sendRequest(endpoint, method, body)
        }

        export const CREATE = async (param: {
            context: "Course" | "Classroom", type: "Task" | "Quiz", pk: string, data: any
        }) => {
            const { context, type, pk, data } = param
            const endpoint = HOST + `/api/${context}/${type}/Question/?pk=` + pk // quizId or taskId
            const method = "POST"
            const body = JSON.stringify(data)
            return await sendRequest(endpoint, method, body)
        }
    }


    export namespace UserAnswer {
        export const SAVE = async (data: any, mode?: string) => {
            const endpoint = HOST + "/api/Course/Question/Answer/"
            const method = "POST"
            let headers: any = {
                'Authorization': `Bearer ${getToken()}`
            }



            if (mode === COURSE.ENROLL_MODE.PUBLIC) {
                headers = {}
            }


            console.log('mode', mode)
            const body = data

            return await sendCustomHeadersRequest(endpoint, method, headers, body)
        }

    }


    export namespace Invitation {

        export const INVITE_BY_EMAIL = async (data: any) => {
            const endpoint = HOST + "/api/Course/Invitation/"
            const method = "POST"

            return await sendRequest(endpoint, method, data)
        }

        export const RESEND = async (invitationId: string) => {
            const endpoint = HOST + "/api/Course/Invitation/?invitationId=" + invitationId
            const method = "PUT"
            return await sendRequest(endpoint, method)
        }

        export const DELETE = async (invitationId: string) => {
            const endpoint = HOST + "/api/Course/Invitation/?invitationId=" + invitationId
            const method = "DELETE"
            return await sendRequest(endpoint, method)
        }

        export const ACCEPT = async (hash: string) => {
            const endpoint = HOST + "/api/Course/Invitation/?hash=" + hash
            const method = "GET"
            let headers: any = {
                'Content-Type': 'application/json',
            }
            const token = getToken()
            if (token) {
                headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }

            return await sendCustomHeadersRequest(endpoint, method, headers)
        }

        export const GET_ALL_INVITATION = async (courseId: string) => {
            const endpoint = HOST + "/api/Course/Invitation/?courseId=" + courseId
            const method = "GET"
            return await sendRequest(endpoint, method)
        }
    }


    export namespace Assignment {
        export const DELETE = async (courseId: string, assignmentId: string) => {
            const endpoint = `${HOST}/api/Course/Assignment/?pk=${courseId}&assignmentId=${assignmentId}`
            const method = "DELETE"
            return await sendRequest(endpoint, method)
        }


        export const GET = async (assignmentId: string) => {
            const endpoint = HOST + "/api/Course/Assignment/?assignmentId=" + assignmentId
            const method = "GET"
            return await sendRequest(endpoint, method)
        }

    }

    // ------------------------------------------- COURSE REVIEW ----------------------------------------------------

    export namespace Review {
        export const GET = async (courseAssignedId?: string, reviewerId?: string, mode: null | string = null) => {

            const endpoint = HOST + `/api/Course/${mode === "Public" ? mode + "/" : ""}Review/?courseAssignedId=${courseAssignedId}&reviewerId=${reviewerId}`
            const method = "GET"
            if (mode === "Public") {
                return await sendPublicRequest(endpoint, method)
            }
            return await sendRequest(endpoint, method)
        }

        // save review question by question
        export const SAVE = async (data: any, mode?: string) => {
            console.log('mode', mode)
            const endpoint = HOST + `/api/Course/${mode === "Public" ? mode + "/" : ""}Review/`
            const method = "PUT"
            const body = JSON.stringify(data)
            if (mode === "Public") {
                return await sendPublicRequest(endpoint, method, body)
            }
            return await sendRequest(endpoint, method, body)
        }

        export const GET_REVIEWER = async (reviewerId: string) => {
            const endpoint = HOST + `/api/Reviewer/?reviewerId=${reviewerId}`;
            const method = "GET";
            return await sendPublicRequest(endpoint, method);
        }
    }

    export namespace Complete {
        export const USER_COURSE_COMPLETE = async (data: any, mode?: string, withReview: any = 0) => {
            const publicUser = cookie.load("public_user") as string
            const endpoint = `${HOST}/api/Course/Complete/?review=${withReview}&mode=${mode}&public_user=${publicUser}`
            const method = "POST"

            const body = JSON.stringify(data)
            let headers: any = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }

            if (mode === COURSE.ENROLL_MODE.PUBLIC) {
                headers = {
                    'Content-Type': 'application/json',
                }
            }

            return await sendCustomHeadersRequest(endpoint, method, headers, body)
        }
    }

}

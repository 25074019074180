import { Collapse, Drawer, Spin, Tag } from 'antd';
import { API } from 'api';
import React, { useEffect, useState } from 'react'

export default function PersonalCourseResultModal(props: {
    open: boolean;
    courseAssignedId: string;
    close: () => void;
}) {

    const { courseAssignedId, open, close } = props

    const [courseAssigned, setCourseAssigned] = useState<any>(null)
    const [loading, setLoading] = useState(true)

    const handleGetResult = async () => {
        setLoading(true)
        const response = await API.Course.Assignment.GET(courseAssignedId)
        console.log(response)
        if (response.status) {
            setCourseAssigned(response.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        handleGetResult()
    }, [open, courseAssignedId]);

    const { Panel } = Collapse
    return (
        <Drawer title={courseAssigned?.course_name} placement="right" width={600} onClose={close} open={open}>

            {loading && <Spin />}

            {!loading &&
                <Collapse defaultActiveKey={[1, 2]}  >

                    {courseAssigned && courseAssigned.quiz_results.length > 0 &&
                        <Panel key={1} header={"Quiz Results"}>
                            {courseAssigned.quiz_results.map((item: any, index: number) => (
                                <div className="quiz-result-item" key={"result-item" + index}>
                                    <p> {item.result && <Tag className={item.result}>{item.result}</Tag>}
                                        {item.quiz}
                                    </p>
                                    <p className='score' >Score : {item.score.toFixed(1)}
                                    </p>
                                </div>
                            ))}

                        </Panel>
                    }

                    {courseAssigned && courseAssigned.task_results.length > 0 &&
                        <Panel key={2} header={"Task Results"}>
                            {courseAssigned.task_results.map((item: any, index: number) => (
                                <div className="quiz-result-item" key={"result-item" + index}
                                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                >
                                    <div className="reviewer">
                                        <p><strong>{item.task}</strong></p>
                                        <p><span style={{ color: "var(--text-secondary)" }} >Reveiwer : </span>
                                            {item.reviewer_name.trim() ? item.reviewer_name : item.reviewer_email}
                                        </p>
                                    </div>

                                    <p className='score' >
                                        {!item.has_review ?
                                            <span style={{
                                                fontSize: "0.85rem",
                                                opacity: 0.8,
                                            }}> (No reviews yet)</span> :
                                            <span>
                                                Score : {item.total_score.toFixed(1)}
                                            </span>
                                        }
                                    </p>
                                </div>
                            ))}

                        </Panel>
                    }
                </Collapse>
            }
        </Drawer>
    )
}
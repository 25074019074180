import { HOST } from 'consts'
import { sendRequest } from './Main'

export namespace ProfileAPI {
    export const GetCourseDetailInfo = async (courseId: string) => {
        const endpoint = HOST + "/api/Course/GetDetailInfo/?pk=" + courseId
        const method = "GET"
        return await sendRequest(endpoint, method)
    }
    export const GetAuthoredCourses = async (params = "") => {
        const endpoint = HOST + "/api/Course/AuthoredCourses/?" + params
        const method = "GET"
        return await sendRequest(endpoint, method)
    }

    export const GetPersonalCourses = async (params = "") => {
        const endpoint = HOST + "/api/Course/PersonalCourses/" + params
        const method = "GET"
        return await sendRequest(endpoint, method)
    }

    export const GetClassroomDetailInfo = async (classroomId: string) => {
        const endpoint = HOST + "/api/Classroom/?pk=" + classroomId
        const method = "GET"
        return await sendRequest(endpoint, method)
    }

    export const GetClassroomPublicInfo = async (pk: string) => {
        const endpoint = HOST + "/api/Classroom/GetPublicInfo/?pk=" + pk
        const method = "GET"
        return await sendRequest(endpoint, method)
    }



}
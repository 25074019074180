import { ConfigProvider } from 'antd';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import App from './App';
import { ChatProvider } from 'curiobites-chat';


// const Mode = "development"
const Mode = process.env.NODE_ENV

if (Mode === "production") {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

// console.log(Host)
const container = document.getElementById('app')
const root = container && createRoot(container)

root && root.render(
  // <React.StrictMode>
  <RecoilRoot>
    <Suspense fallback={<div></div>}>
      <ChatProvider>
        <ConfigProvider theme={{ token: { colorPrimary: '#00a397' } }}>
          <App />
        </ConfigProvider>
      </ChatProvider>
    </Suspense>
  </RecoilRoot>
);


import { Button, Space, Table, Tag } from 'antd'
import { IMAGE } from 'consts';
import React, { useEffect, useRef, useState } from 'react'
import { Course } from 'types'
import { ExportOutlined } from '@ant-design/icons'
import PersonalCourseResultModal from './PersonalCourseResultModal';
import CourseDetailModal from 'components/course/course-detail-modal/CourseDetailModal';

export const minTableHeightRequired = 650

export default function PersonalCoursesTable(props: {
    courses: Course[],
    loading: boolean,
}) {

    const tableRef = useRef(null)
    const { courses, loading } = props
    const [courseId, setCourseId] = useState<string | undefined>(undefined)
    const [courseAssignedId, setCourseAssignedId] = useState<string | undefined>(undefined)
    const [tableHeight, setTableHeight] = useState<number>(300)

    useEffect(() => {
        const maxTableHeight = window.innerHeight - 230
        setTableHeight(maxTableHeight)
    }, [])

    const courseDetails = (record: Course) => {
        setCourseId(record.course_id)

    }

    const handleDisplayResult = (record: Course) => {
        setCourseAssignedId(record.id)

    }


    const handleRowClick = async (record: Course) => {
        console.log(record)
    }

    // scroll vertikal

    const columns = [
        {
            title: 'Title',
            dataIndex: 'course_name',
            key: 'title',

            render: (text: string, record: Course) => {
                let imageUrl = record.image;
                if (!imageUrl) {
                    imageUrl = IMAGE.DEFAULT
                }

                return (
                    <Space>
                        <img src={imageUrl} className={'course-image'} alt="course" style={{ width: 40, height: 40, borderRadius: 6, objectFit: "cover" }} />
                        <span onClick={() => courseDetails(record)} style={{ cursor: "pointer" }}>{text}</span>
                    </Space>
                );
            },
            sorter: (a: Course, b: Course) => a.course_name.localeCompare(b.course_name),
            className: "has-avatar"

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (status: string) => (
                <Tag className={'ant-tag ' + status}>
                    {status}
                </Tag>
            ),
            sorter: (a: Course, b: Course) => a.status.localeCompare(b.status),
        },
        {
            title: 'Results',
            dataIndex: 'results',
            key: 'results',
            width: 150,
            sorter: (a: Course, b: Course) => a.results - b.results,
            // sortDirections: ['ascend', 'descend'],
            render: (results: number, record: Course) => {
                return (
                    <p className='is-link' onClick={() => handleDisplayResult(record)} >
                        {results + " Result"}
                        <ExportOutlined className='ml-2' />
                    </p>
                )
            }

        },
        {
            title: '',
            dataIndex: '',
            key: 'action',
            width: 90,
            render: (record: Course) => (
                <Button onClick={() => courseDetails(record)}>View</Button>
            )
        },

    ];



    return (
        <>
            {courseAssignedId &&
                <PersonalCourseResultModal
                    open={courseAssignedId !== undefined}
                    courseAssignedId={courseAssignedId}
                    close={() => {
                        setCourseAssignedId(undefined)
                    }}
                />
            }

            {courseId &&
                <CourseDetailModal
                    courseId={courseId}
                    close={() => {
                        setCourseId(undefined)
                    }}
                    open={courseId !== undefined}
                />
            }

            <Table
                ref={tableRef}
                key={"personal-course-table"}
                loading={loading}
                className={"is-table"}
                columns={columns}
                dataSource={courses}
                pagination={{ pageSize: 10 }}
                scroll={{ y: tableHeight }}
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
                rowKey={"id"}
            />
        </>
    )
}

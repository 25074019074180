import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./error.css"
import { IMAGE } from 'consts'

export default function PageNotFound() {
    const navigate = useNavigate()
    return (
        <div className="not-found">
            <h1>404</h1>
            <img src={IMAGE.ERROR_404} alt="not-found" />
            <h2>Page Not Found</h2>
            <div className="message-block">
                The link you clicked may be broken or the page may have been removed.
            </div>
            <Button type='primary'
                onClick={() => navigate("/")}
                className='mt-5'>Back to Home</Button>
        </div>
    )
}

import { Button } from 'antd';
import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
interface BeforeInstallPromptEvent extends Event {
    readonly platforms: Array<string>;
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;
    prompt(): Promise<void>;
}

export interface WindowEventMap {
    'beforeinstallprompt': BeforeInstallPromptEvent;
}

export default function InstallPrompt() {

    const [installPrompt, setInstallPrompt] = useState<BeforeInstallPromptEvent | null>(null);
    const [open, setOpen] = useState<boolean>(true);
    useEffect(() => {
        if (!window || !isMobile) return;
        const listener: EventListener = (e) => {
            e.preventDefault();
            setInstallPrompt(e as BeforeInstallPromptEvent);
        };
        window.addEventListener('beforeinstallprompt', listener);
        return () => window.removeEventListener('beforeinstallprompt', listener);

    }, [window, isMobile])

    const handleClick = async () => {
        if (!installPrompt) return;
        setOpen(false)
        await installPrompt.prompt();
        const choiceResult = await installPrompt.userChoice;
        if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
        } else {
            console.log('User dismissed the A2HS prompt');
        }
        setInstallPrompt(null);
    }

    console.log('installPrompt', installPrompt)
    return (
        <>
            {installPrompt && open && isMobile &&
                <motion.div className='install-prompt'
                    initial={{ y: -100, display: "none" }}
                    animate={{ y: 0, display: "flex" }}
                    transition={{ duration: .3, delay: 3 }}
                >
                    <Button
                        onClick={() => setOpen(false)}
                        ghost
                    >Close</Button>
                    <Button
                        onClick={handleClick}
                        className='install-button'
                        ghost
                    >Install Curiobites App</Button>
                </motion.div>
            }
        </>
    )
}

import { Button } from 'antd'
import Footer from 'components/footer/Footer'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./payment.css"

const image = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/CancelPayment.gif"

export function CancelPayment() {
    const navigate = useNavigate()
    return (
        <>
            <div className="cancel-payment">
                <div className="container">
                    <img src={image} alt="" />
                    <p>Your payment has been canceled.</p>
                    <Button type='primary' onClick={() => navigate("/")}>Back to Home</Button>
                </div>
            </div>
            <Footer />
        </>
    )
}


import { starSVG } from 'functions'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import StarRatings from 'react-star-ratings'
import { Course } from "types"
import { AuthorSection } from './AuthorSection'
import { Button, Skeleton, Space } from 'antd'
import { CURRENCY } from 'consts'
import { useUserContext } from 'UserContext'
import { getCourseAction } from './CourseDetailModal'

export const RightPanel = (props: {
    course: Course,
    img: string,
    startCourse: () => void,
    handlePurchase: () => void,
    loading: boolean
}) => {

    const { course, img, startCourse, loading, handlePurchase } = props
    const { user } = useUserContext()


    const navigate = useNavigate()


    // console.log('purchaseCourseId', purchaseCourseId)
    return (
        <div className="right-side">


            {course ?
                <div className="sticky-holder">
                    <div className="box-summary">
                        <img src={course.image ? course.image : img} alt="" />
                        <div className="summary-info">
                            {course.price ?
                                <div className="price mb-3">$ {course.price} {CURRENCY}
                                    {course.is_assigned &&
                                        <span
                                            style={{ fontWeight: 400 }}
                                        > (Purchased)</span>

                                    }
                                </div> :
                                <div className="price mb-3">Free</div>
                            }

                            {user ?
                                <Button
                                    loading={loading}
                                    onClick={course.is_assigned ? startCourse : handlePurchase}
                                    type="primary"
                                    className='mb-3 w-100'
                                >
                                    {getCourseAction(course)}
                                </Button> :
                                <Button
                                    type="primary"
                                    className='mb-3 w-100'
                                    onClick={() => navigate("/login?next=/course/enroll/?c=" + course.id)}
                                >
                                    Start Course
                                </Button>
                            }

                            <div className="extra mb-3">
                                <div className="level m-0">
                                    <p>{course.level}</p>
                                    <p className="tip">Level</p>
                                </div>
                                <div className="student">
                                    <p>{course.students ? course.students : "-"}</p>
                                    <p className="tip">Students</p>
                                </div>

                                <div className="rate">
                                    {course.review ?
                                        <StarRatings
                                            rating={parseFloat(course.review)}
                                            starDimension="16px"
                                            starSpacing="0"
                                            svgIconPath={starSVG}
                                            starRatedColor="#ffbb30"
                                            svgIconViewBox="0 0 24 24"
                                        /> : "No review"}
                                    <p className="tip">Rate</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <AuthorSection author={course.author} />

                </div>
                :
                <SkeletonRightPanel />
            }
        </div>
    )
}

const SkeletonRightPanel = () => {
    return (
        <div className="sticky-holder">
            <div className="box-summary">
                <Skeleton.Image active style={{ width: "308px", height: "180px" }} />
                <div className="summary-info">
                    <Skeleton.Button active style={{ height: "35px", marginBottom: "1rem" }} block />
                    <Skeleton.Input active style={{ width: "284px", height: "20px" }} />
                </div>
            </div>
            <div className="author-box"
                style={{ display: "flex", alignItems: "center", padding: "1rem 0" }}
            >
                <Space>
                    <Skeleton.Avatar active size={35} style={{ width: "35px" }} />
                    <Skeleton.Input active style={{ width: "150px", height: "20px" }} />
                </Space>
            </div>
        </div>

    )
}



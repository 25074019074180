
export const HOST = process.env.REACT_APP_HOST
export const WS_HOST = process.env.REACT_APP_WS_HOST

export const AWS_STORAGE_BUCKET_NAME = process.env.REACT_APP_AWS_STORAGE_BUCKET_NAME
export const AWS_REGION = process.env.REACT_APP_AWS_REGION
export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID
export const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY

export const LOGO = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/curiobites-logo.png"
export const FORMAT = {
    DATE: "DD/MM/YYYY",
    DATE_TIME: "DD/MM/YYYY HH:mm",
}

export const CURRENCY = "USD"


export namespace KEY {
    export const TOKEN_ACCESS = process.env.REACT_APP_ACCESS_TOKEN_KEY || ""
    export const TOKEN_REFRESH = process.env.REACT_APP_REFRESH_TOKEN_KEY || ""
    export const ROLE = process.env.REACT_APP_ROLE_KEY || ""
}

export namespace PATH {
    export const HOME = "/"
    export const LOGIN = "/login"
    export const REGISTER = "/register"
    export const PROFILE = "/profile"
    export const FORGOT_PASSWORD = "/forgot-password"
    export const RESET_PASSWORD = "/reset-password"
    export const COURSE = "/course"
    export const REGISTRATION_SUCCESS = "/registration-success"
}

export enum PAGE {
    HOME = "Home",
    LOGIN = "Login",
    REGISTER = "Register",
    PROFILE = "Profile",
    FORGOT_PASSWORD = "Forgot Password",
    RESET_PASSWORD = "Reset Password",
    COURSE = "Course",
    COURSE_ENROLL = "Course Enroll",
    COURSE_BUILDER = "Course Builder",
    REGISTRATION_SUCCESS = "Registration Success",
    PUBLIC_REVIEW = "Public Review",
}

export const pageWithNavbar = [PAGE.HOME, PAGE.COURSE, PAGE.PROFILE]

export namespace MOOD {
    export const _1 = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/mood/1.svg"
    export const _2 = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/mood/2.svg"
    export const _3 = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/mood/3.svg"
    export const _4 = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/mood/4.svg"
    export const _5 = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/mood/5.svg"
}

export enum CHAT_IMAGE {
    DOCS = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/chat/docs.png",
    PDF = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/chat/pdf.png",
    EXCEL = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/chat/excel.png"
}

export namespace IMAGE {
    export const LOGO = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/curiobites-logo.png"
    export const CURIOBITES_ICON = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/curiobites-icon.svg"
    export const DEFAULT = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/default_image.png"
    export const BECOME_AUTHOR = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/become_author.png"
    export const LOGIN = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/img-login.jpg"
    export const ERROR_404 = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/404.gif"
    export const ERROR_500 = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/500.gif"
    export const TEACHER = "https://curiobites-media.s3.ap-southeast-1.amazonaws.com/public/teacher-bro.svg"
    export const CHAT = CHAT_IMAGE
}


export enum TERM {
    MODULE = "Curio",
    COURSE = "Course",
    CLASSROOM = "Classroom",
    LESSON = "Lesson",
    QUIZ = "Quiz",
    TASK = "Task",
    QUESTION = "Question",
    ANSWER = "Answer",
    COMMENT = "Comment",
    REVIEW = "Review",
    REVIEWER = "Reviewer",
    AUTHOR = "Author",
    PROGRAM = "Program",
    MEMBER = "Member",
}


import { ClassroomAPI } from "./ClassroomAPI"
import { AuthAPI } from "./AuthAPI"
import { NotificationApi } from "./NotificationAPI"
import { ProfileAPI } from "./ProfileAPI"
import { CourseAPI } from "./CourseAPI"
import { TagAPI } from "./CommonAPI"
import { PaymentAPI } from "./PaymentAPI"

export * from "./Main"
export * from "./CourseAPI"
export * from "./AuthAPI"
export * from "./ChatAPI"
export * from "./UserSettingAPI"
export * from "./CourseBuilderAPI"
export * from "./CommonAPI"
export * from "./ProfileAPI"



export namespace API {
    export const Classroom = ClassroomAPI
    export const Auth = AuthAPI
    export const Notification = NotificationApi
    export const Profile = ProfileAPI
    export const Course = CourseAPI
    export const Tag = TagAPI
    export const Payment = PaymentAPI
}
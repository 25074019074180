
import React from 'react'
import AttachmentQuestionView from './questionView/AttachmentQuestionView'
import DateQuestionView from './questionView/DateQuestionView'
import MoodQuestionView from './questionView/MoodQuestionView'
import OptionQuestionView from './questionView/OptionQuestionView'
import TextQuestionView from './questionView/TextQuestionView'
import YesNoQuestionView from './questionView/YesNoQuestionView'
import { Question } from 'types'
import { COURSE } from 'consts'

export interface QuestionViewProps {
    data: Question
    mode: COURSE.ENROLL_MODE
    session: any
    setAllowNext: (arg: boolean) => void
}


export default function QuestionView(props: QuestionViewProps) {
    const { data } = props

    console.log(data)
    console.log("response type --->  ", data.response_type)

    return (

        <div className="question-view">
            {data.response_type === COURSE.QUESTION.RESPONSE_TYPE.OPTION &&
                <OptionQuestionView type={data.obj_type}  {...props} />
            }

            {(data.response_type === COURSE.QUESTION.RESPONSE_TYPE.YESNO ||
                data.response_type === COURSE.QUESTION.RESPONSE_TYPE.YES_NO) &&
                <YesNoQuestionView type={data.obj_type}  {...props} />
            }

            {data.response_type === COURSE.QUESTION.RESPONSE_TYPE.MOOD &&
                <MoodQuestionView type={data.obj_type}  {...props} />
            }

            {data.response_type === COURSE.QUESTION.RESPONSE_TYPE.ATTACHMENT &&
                <AttachmentQuestionView type={data.obj_type}  {...props} />
            }

            {data.response_type === COURSE.QUESTION.RESPONSE_TYPE.DATE &&
                <DateQuestionView type={data.obj_type}  {...props} />
            }

            {data.response_type === COURSE.QUESTION.RESPONSE_TYPE.TEXT &&
                <TextQuestionView type={data.obj_type}  {...props} />
            }

        </div>
    )
}


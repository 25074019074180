import React, { useState } from 'react'
import { Button, Image, Popconfirm, Space, Table, } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Member, Program } from 'types';
import { TERM } from 'consts';

export default function ProgramMembers(props: {
    program: Program
}) {
    const { program } = props;

    const assignees = program.assignees

    const [loading, setLoading] = useState(false)
    const [displayForm, setDisplayForm] = useState(false)

    const deleteAssignee = async (assignee: Member) => {
        setLoading(true)
        console.log('member', assignee)
        setLoading(false)
    }

    const handleEdit = (assignee: Member) => {
        console.log('record', assignee)
    }
    console.log('displayForm', displayForm)
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, record: Member) => {
                const imageUrl = record.image;
                console.log(name, record)

                return (
                    <Space onClick={() => handleEdit(record)} style={{ cursor: "pointer" }}>
                        <Image src={imageUrl} />
                        <div>
                            <p>{name}</p>
                            <div className="tip">{record.description}</div>
                        </div>
                    </Space>


                );
            },
            sorter: (a: Member, b: Member) => a.name.localeCompare(b.name),
            className: "has-avatar"

        },
        {
            title: "Days",
            dataIndex: 'days',
            key: 'days',
            sorter: (a: Member, b: Member) => a.days - b.days,
        },

        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            className: "action",
            width: "110px",
            render: (record: Member) => (
                <>
                    <Button icon={<EditOutlined />} className='mr-1'
                        onClick={() => handleEdit(record)}></Button>
                    <Popconfirm
                        placement="bottomRight"
                        title={"Delete " + TERM.MEMBER}
                        description={`Are you sure to delete this ${TERM.MEMBER}?`}
                        onConfirm={() => deleteAssignee(record.id)}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ loading: loading }}
                    >
                        <Button icon={<DeleteOutlined />}></Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    const handleRowClick = (assignee: Member) => {
        console.log('member', assignee)
    }

    return (
        <div className="program-members p-5">

            <div className="header flex-group no-border mb-3">
                <div className="title is-5 mb-3">Members</div>
                <Button type='primary' onClick={() => setDisplayForm(true)}>+ Add</Button>
            </div>

            <Table key={"classroom-program-courses-table"}
                className={"is-table"}
                columns={columns}
                dataSource={assignees}
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })} />

        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { InputNumber, Input } from 'antd';
import { getMood } from 'functions';
import { Review } from 'types';


export default function ReviewScore(props: {
    review: Review,
    handleReviewChange: (data: any) => void,
    mode?: string,
    review_type?: string
}) {
    const {
        review: propReview,
        handleReviewChange,
        review_type
    } = props;

    // console.log('rev', propReview)

    const [review, setReview] = useState(propReview)
    const [initialLoad, setInitialLoad] = useState(true)

    const handleUpdate = ({ name, value }: any) => {
        setReview((prev: any) => ({
            ...prev, [name]: value
        }))
    }

    const handleScoreTemplateClick = (value: any) => {
        handleUpdate({ name: "score", value: value })
    }


    useEffect(() => {
        if (review && !initialLoad) {
            handleReviewChange(review)
        }

        if (initialLoad) {
            setInitialLoad(false)
        }
    }, [review]);

    // console.log('review', review)

    const moodScores = [0, 25, 50, 75, 100]

    return (
        <div className="review-score review-mood mb-3">
            {review &&
                <>
                    {review_type === "Score" &&
                        <div className="with-score">
                            <div className="label">Score :</div>
                            <div className="score-box">
                                <InputNumber
                                    value={review.score}
                                    min={0}
                                    max={100}
                                    onChange={e => handleUpdate({ name: "score", value: e })}
                                />
                                <div className="score-template ml-2" >
                                    <span onClick={() => handleScoreTemplateClick(0)}>0</span>
                                    <span onClick={() => handleScoreTemplateClick(20)}>20</span>
                                    <span onClick={() => handleScoreTemplateClick(40)}>40</span>
                                    <span onClick={() => handleScoreTemplateClick(60)}>60</span>
                                    <span onClick={() => handleScoreTemplateClick(80)}>80</span>
                                    <span onClick={() => handleScoreTemplateClick(100)}>100</span>
                                </div>
                            </div>
                        </div>
                    }

                    {review_type === "Mood" &&
                        <div className="with-mood">
                            <div className="label">Mood :</div>
                            <div className="score-box">
                                <div className="score-template" >
                                    {moodScores.map((item, index) => (
                                        <div data-value={item}
                                            key={"mood-" + index}
                                            className={review.score === item ? 'mood-score active' : 'mood-score'}
                                            onClick={() => handleScoreTemplateClick(item)}>
                                            <img src={getMood(index + 1) || ""} alt="" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }


                    <Input.TextArea
                        value={review.comment}
                        placeholder='Comment'
                        className='mt-3'
                        onChange={e => handleUpdate({ name: "comment", value: e.target.value })}
                    />
                </>
            }

        </div>
    )

}
